import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
//import { apiHostURL } from "../graphql/baseApi";

const clientId = process.env.REACT_APP_AUTH0_CLIENTID || "";
const baseDomain = process.env.REACT_APP_AUTH0_URL_BASE || "";
const audience =
  process.env.REACT_APP_AUTH0_AUDIENCE || "http://localhost:4000";

function SecurityGuardrail({ children }: { children: React.ReactNode }) {
  console.log(`auth0 login nto ${clientId} ${baseDomain} }`);
  return (
    <Auth0Provider
      domain={baseDomain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        scope:
          "openid profile email read:current_user update:current_user_metadata",
      }}
    >
      {children}
    </Auth0Provider>
  );
}

export default SecurityGuardrail;
