import { useDispatch } from "react-redux";
import { useWebSocketForUpdates } from "../hooks/useWebSocket";
import { useServiceBag } from "../services/ServiceBag";

function NotificationListener() {
  const dispatch = useDispatch();
  const { apiService } = useServiceBag();
  useWebSocketForUpdates(dispatch, apiService);

  return <></>;
}

export default NotificationListener;
