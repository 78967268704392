import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { UniversalOrgId, WebUserRoles } from "shared-utils";
import SehatSpinner from "../components/SehatSpinner";
import useCurrentRole from "../hooks/useCurrentRole";
import { type HealthOrg } from "../graphql/generated";
import useCurrentHealthOrg from "../hooks/useCurrentHealthOrg";
import { RootState } from "../state/reducers";

function createLandingPageMap(
  healthOrg?: HealthOrg
): Record<WebUserRoles, string> {
  return {
    su: "/admin/create-user",
    greet: "/greeter-dashboard",
    phys: healthOrg?.demo ? "/physician-dashboard" : "/visit-in-progress",
    medast: "/medassist-dashboard",
    user: "/greeter-dashboard",
    trn: "/greeter-dashboard",
    smgr: "/greeter-dashboard",
    cladmin: "/create-user",
  };
}
function AppIndexRoute() {
  const navigate = useNavigate();
  const healthOrg = useCurrentHealthOrg();
  const role = useCurrentRole();
  const user = useSelector((state: RootState) => state.currentUser.currentUser);

  const healthOrgReady = healthOrg !== null || user?.orgId === UniversalOrgId;

  useEffect(() => {
    if (role && healthOrgReady) {
      navigate(createLandingPageMap(healthOrg)[role]);
    }
  }, [role, navigate, healthOrg, healthOrgReady]);
  return <SehatSpinner />;
}

export default AppIndexRoute;
