import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { removeNils } from "shared-utils";
import { AppDispatch } from "../../state/store";
import { setAuthToken } from "../../state/authSlice";
import { useLazyUserForSessionQuery } from "../../graphql/generated";
import { setAvailableClinics } from "../../state/currentClinicSlice";
import SehatSpinner from "../../components/SehatSpinner";
import {
  setCurrentHealthOrg,
  setCurrentUser,
} from "../../state/currentUserSlice";

// TODO: I don't think we need target at all.
export default function LoginRoute() {
  return <LoginView target="/select-clinic" />;
}

interface LoginViewProps {
  target?: string;
}

export function LoginView({ target }: LoginViewProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [userForSession] = useLazyUserForSessionQuery();

  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      void loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // after authentication, get an OAuth token and use it to confirm the user exists, and
  // get a list of clinics.
  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "openid profile email read:current_user",
          },
        });
        console.log("got the token.");
        dispatch(setAuthToken(accessToken));

        const userData = await userForSession();
        console.log(`LOGGED IN user data: ${JSON.stringify(user)}`);
        dispatch(
          setAvailableClinics(
            removeNils(userData?.data?.userForSession?.clinics)
          )
        );
        console.log(
          `health org is ${JSON.stringify(
            userData?.data?.userForSession?.healthOrg
          )}`
        );
        dispatch(
          setCurrentHealthOrg(
            userData?.data?.userForSession?.healthOrg || undefined
          )
        );
        dispatch(setCurrentUser(userData?.data?.userForSession || undefined));
      } catch (e) {
        console.log(`token failed ${JSON.stringify(e)}`);
      }
    };
    console.log(`IN hook is authenticated? ${isAuthenticated ? "Y" : "N"}`);
    if (isAuthenticated) {
      void getUserToken();
    }
  }, [
    isAuthenticated,
    dispatch,
    getAccessTokenSilently,
    user,
    userForSession,
    navigate,
    target,
  ]);

  return <SehatSpinner />;
}
