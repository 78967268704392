type FetchFunc = typeof fetch;
export default function createFileService(fetchReq: FetchFunc) {
  const convertBase64ToBlob = async (base64: string) => {
    const res = await fetchReq(base64);
    return await res.blob();
  };

  const uploadBlobToS3 = async (s3Url: string, data: Blob) => {
    await fetchReq(s3Url, { method: "PUT", body: data });
  };
  const uploadBase64ToS3 = async (s3Url: string, base64: string) => {
    await uploadBlobToS3(s3Url, await convertBase64ToBlob(base64));
  };

  const downloadFromUrl = (url: string, filename: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  };
  const downloadFromS3 = downloadFromUrl;

  const downloadData = (data: Blob, filename: string) => {
    downloadFromUrl(window.URL.createObjectURL(data), filename);
  };

  return {
    // we can expose these if we need to.
    //convertBase64ToBlob,
    uploadBlobToS3,
    uploadBase64ToS3,
    downloadFromS3,
    downloadData,
    fetch: fetchReq,
  };
}

const fetchReq: FetchFunc = fetch.bind(window);
export const fileService = createFileService(fetchReq);
export type FileService = ReturnType<typeof createFileService>;
