import * as R from "ramda";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { SxProps, Theme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import { selectHandler } from "../utils/eventUtils";

interface SelectFromMenuProps<T = string> {
  value: T;
  setValue: (v: T) => void;
  label: string;
  id?: string;
  sx?: SxProps<Theme>;
  options?: T[];
  children?: React.ReactNode;
  convert?: (v: string) => T;
  format?: (v: T) => string;
  required?: boolean;
}
function SelectFromMenu<T extends string = string>({
  value,
  setValue,
  label,
  id,
  sx,
  options,
  children,
  required = false,
  convert = (v) => v as unknown as T,
  format = (v) => v as unknown as string,
}: SelectFromMenuProps<T>) {
  const onChange = R.compose(setValue, convert, selectHandler);
  const sxSetting = sx || { width: 300, marginTop: "1em" };
  const labelId = `${id || label}-select-label`;

  const renderValue = (value: T) => (
    <Typography textAlign="left">{format(value)}</Typography>
  );

  return (
    <FormControl sx={sxSetting}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        required={required}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              //style={getStyles(name, personName, theme)}
            >
              {format(option)}
            </MenuItem>
          ))}
        {options || children}
      </Select>
    </FormControl>
  );
}

export default SelectFromMenu;
