import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import MainAppRoute from "../routes/MainAppRoute";
import InternalAppRoute from "../routes/InternalAppRoute";
import DataRoute from "../routes/DataRoute";
import ErrorPage from "../routes/ErrorPage";
import CreateAdminUserRoute from "../routes/createAdminUser/CreateAdminUserRoute";
import CreateUserRoute from "../routes/createUser/CreateUserRoute";
import PatientInfo from "../components/PatientInfo";
import LoginRoute from "../routes/login/LoginRoute";
import RecordingRoute from "../routes/record/RecordingRoute";
import RecordingsRoute from "../routes/record/RecordingsRoute";
import SiteNotesRoute from "../routes/siteNotes/SiteNotesRoute";
import VitalsRoute from "../routes/vitals/VitalsRoute";
import CreatePatientRoute from "../routes/createPatient/CreatePatientRoute";
import VisitDetailsRoute from "../routes/visitDetails/VisitDetailsRoute";
import SelectPatientRoute from "../routes/selectPatient/SelectPatientRoute";
//import VisitInProgressRoute from "../routes/visitInProgress/VisitInProgressRoute";
import SimpleVisitInProgressRoute from "../routes/visitInProgress/SimpleVisitInProgress";
import GreeterDashboardRoute from "../routes/greeterDashboard/GreeterDashboardRoute";
import MedAssistDashboardRoute from "../routes/medAssistDashboard/MedAssistDashboardRoute";
import PhysicianDashboardRoute from "../routes/physicianDashboard/PhysicianDashboardRoute";
import PlanRoute from "../routes/planRoute/PlanRoute";
import AppIndexRoute from "../routes/AppIndexRoute";
import VisitInProgressRoute from "../routes/visitInProgress/VisitInProgressRoute";
import SelectClinicRoute from "../routes/SelectClinic/SelectClinicRoute";
import ReviewVisitsRoute from "../routes/reviewVisits/ReviewVisitsRoute";
import { SecuredClinicRoute, SecuredRoute } from "./SecuredRoute";

const SecuredMainAppRoute = () => {
  return (
    <SecuredClinicRoute>
      <MainAppRoute />
    </SecuredClinicRoute>
  );
};

const SecuredInternalAppRoute = () => {
  return (
    <SecuredRoute>
      <InternalAppRoute />
    </SecuredRoute>
  );
};

// requires login but adds no UI elements at all.
const LoginRequiredRoute = () => {
  return (
    <SecuredRoute>
      <Outlet />
    </SecuredRoute>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<LoginRoute />} />

      <Route
        path="/"
        element={<LoginRequiredRoute />}
        errorElement={<ErrorPage />}
      >
        <Route index={true} element={<AppIndexRoute />} />
        <Route path="/select-clinic" element={<SelectClinicRoute />} />
      </Route>

      <Route
        path="/"
        element={<SecuredMainAppRoute />}
        errorElement={<ErrorPage />}
      >
        <Route path="/greeter-dashboard" element={<GreeterDashboardRoute />} />
        <Route
          path="/physician-dashboard"
          element={<PhysicianDashboardRoute />}
        />
        <Route
          path="/medassist-dashboard"
          element={<MedAssistDashboardRoute />}
        />

        <Route path="/create-patient" element={<CreatePatientRoute />} />
        <Route path="/plan" element={<PlanRoute />} />
        <Route path="/visit-details" element={<VisitDetailsRoute />} />
        <Route path="/select-patient" element={<SelectPatientRoute />} />
        
        <Route
          path="/demo-visit-in-progress"
          element={<VisitInProgressRoute />}
        />
        
         <Route
          path="/visit-in-progress"
          element={<SimpleVisitInProgressRoute />}
        />

        <Route path="/create-user" element={<CreateUserRoute />} />
        <Route path="vitals" element={<VitalsRoute />} />
      </Route>
      <Route
        path="/admin"
        element={<SecuredInternalAppRoute />}
        errorElement={<ErrorPage />}
      >
        <Route path="create-user" element={<CreateAdminUserRoute />} />
        <Route path="data" element={<DataRoute />} />
        <Route path="patient" element={<PatientInfo />} />
        {/* verb: re-CORD, not the noun REC-ord */}
        <Route path="record" element={<SimpleVisitInProgressRoute />} />
        <Route path="recording/:recordingId" element={<RecordingRoute />} />
        <Route path="recordings" element={<RecordingsRoute />} />
        <Route path="site-notes" element={<SiteNotesRoute />} />
        <Route path="review-visits" element={<ReviewVisitsRoute />} />
        <Route index element={<h1>index</h1>} />
      </Route>
    </>
  )
);

export default router;