import * as R from "ramda";
import { useDispatch } from "react-redux";
import { RecordingStatuses } from "shared-utils";
import api from "../../graphql/enhancedApi";
import {
  type RecordingKeys,
  useDeleteRecordingMutation as useDeleteRecordingMutationInternal,
  useUpdateRecordingStatusMutation as useUpdateRecordingStatusMutationInternal,
} from "../../graphql/generated";
import { AppDispatch } from "../../state/store";
export { useUpdateRecordingNotesMutation } from "../../graphql/generated";

const updateCachedQueryData = api.util.updateQueryData;

const isSameRecording = R.curry((a: RecordingKeys, b: RecordingKeys) => {
  return a.user === b.user && a.recordingId === b.recordingId;
});

const isSameRecordingAs = (r: RecordingKeys) =>
  isSameRecording(r) as (r: RecordingKeys) => boolean;

const withoutRecording = (
  recording: RecordingKeys,
  recordings: RecordingKeys[]
) => {
  return R.reject(isSameRecordingAs(recording), recordings);
};

// we can modify mutation hooks as needed here; if we don't need to,
// just re-export

function performDelete<Return>(
  deleteFromServer: (keys: { recordingKeys: RecordingKeys }) => Promise<Return>,
  dispatch: AppDispatch
) {
  return async (keys: RecordingKeys) => {
    const result = await deleteFromServer({ recordingKeys: keys });
    // we could just refetch, but it's so easy just to remove the deleted
    // item from the cache this way.
    const updater = updateCachedQueryData(
      "recordingsByCriteria",
      { criteria: { since: "2021-10-01" } },
      (state) => {
        state.recordings = withoutRecording(
          keys,
          state.recordings as RecordingKeys[]
        );
      }
    );
    dispatch(updater);
    return result;
  };
}

export function useDeleteRecordingMutation() {
  const dispatch = useDispatch<AppDispatch>();
  const [deleteRecordingFromServer, deleteRecordingResult] =
    useDeleteRecordingMutationInternal();

  const deleteRecording = performDelete(deleteRecordingFromServer, dispatch);
  // getting a tuple to work here is too complicated
  return { deleteRecording, deleteRecordingResult };
}

type UseUpdateRecordingStatusMutationInternalParam = Parameters<
  typeof useUpdateRecordingStatusMutationInternal
>[0];

interface UpdateRecordingStatusUpdateParams {
  status?: RecordingStatuses;
  assignedTo?: string;
}

interface UpdateRecordingStatusParams {
  keys: RecordingKeys;
  update: UpdateRecordingStatusUpdateParams;
}

export function useUpdateRecordingStatusMutation(
  param: UseUpdateRecordingStatusMutationInternalParam = {}
) {
  const [updateRecordingStatusUntyped, updateRecordingStatusResult] =
    useUpdateRecordingStatusMutationInternal(param);

  const updateRecordingStatus = (args: UpdateRecordingStatusParams) => {
    return updateRecordingStatusUntyped(args);
  };

  return { updateRecordingStatus, updateRecordingStatusResult };
}
