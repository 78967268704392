import { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LocaleContext } from "shared-ui";

interface Visit {
  date: string;
  practitioner: string;
  location: string;
  bloodPressure: number;
  glucose: number;
}

const visits: Visit[] = [
  {
    date: "2021-01-01",
    practitioner: "Dr. Javed",
    location: "Jinnah Park",
    bloodPressure: 110,
    glucose: 80,
  },
  {
    date: "2022-05-13",
    practitioner: "Dr. Khan",
    location: "Mozina",
    bloodPressure: 90,
    glucose: 90,
  },
  {
    date: "2022-09-04",
    practitioner: "Dr. Nasram",
    location: "Ramgarh",
    bloodPressure: 135,
    glucose: 140,
  },
];

const DataGraph = () => {
  const intl = useIntl();
  const bloodData = visits.map((visit) => visit.bloodPressure);
  const glucoseData = visits.map((visit) => visit.glucose);
  const { localeDirection } = useContext(LocaleContext);

  const options = {
    title: {
      text: "My chart",
    },
    series: [
      {
        name: intl.formatMessage({ id: "graph.bloodPressure" }),
        data: bloodData,
      },
      {
        name: intl.formatMessage({ id: "graph.glucose" }),
        data: glucoseData,
      },
    ],
    legend: {
      rtl: localeDirection === "rtl",
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const DataRoute = () => {
  return (
    <Box>
      <Typography variant="h1">Data</Typography>
      <DataGraph />
    </Box>
  );
};
export default DataRoute;
