import { useContext } from "react";
import { CssBaseline, SimplePaletteColorOptions } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocaleContext } from "shared-ui";
import LightTheme from "./LightTheme";
import DarkTheme from "./DarkTheme";

function SehatThemeProvider({ children }: { children: React.ReactNode }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // const theme = prefersDarkMode ? DarkTheme : LightTheme;
  const theme = LightTheme;
  const { localeDirection } = useContext(LocaleContext);

  // ltr or rtl
  theme.direction = localeDirection;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

declare module "@mui/material/styles" {
  interface Palette {
    primaryContainer: SimplePaletteColorOptions;
    secondaryContainer: SimplePaletteColorOptions;
    inverseText: SimplePaletteColorOptions;
    primaryFaded: SimplePaletteColorOptions;
    primaryVeryFaded: SimplePaletteColorOptions;
    neutralBackground: SimplePaletteColorOptions;
    hoverBackground: SimplePaletteColorOptions;
    themeText: SimplePaletteColorOptions;
    infoBox: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    primaryContainer: SimplePaletteColorOptions;
    secondaryContainer: SimplePaletteColorOptions;
    inverseText: SimplePaletteColorOptions;
    primaryFaded: SimplePaletteColorOptions;
    primaryVeryFaded: SimplePaletteColorOptions;
    neutralBackground: SimplePaletteColorOptions;
    hoverBackground: SimplePaletteColorOptions;
    themeText: SimplePaletteColorOptions;
    infoBox: SimplePaletteColorOptions;
  }
}

export default SehatThemeProvider;
