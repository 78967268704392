import { createContext, useContext } from "react";
import { type ApiService } from "../graphql/apiService";
import { apiService } from "../graphql/apiService";
import { type EnvService } from "./envService";
import { type FileService } from "./fileService";
import { envService } from "./envService";
import { fileService } from "./fileService";

interface ServiceBag {
  envService: EnvService;
  apiService: ApiService;
  fileService: FileService;
}

const serviceBag = { apiService, envService, fileService };

const ServiceBagContext = createContext(serviceBag);

function ServiceBagProvider({ children }: { children: React.ReactNode }) {
  return (
    <ServiceBagContext.Provider value={serviceBag}>
      {children}
    </ServiceBagContext.Provider>
  );
}

function useServiceBag(): ServiceBag {
  return useContext(ServiceBagContext);
}

export {
  type ServiceBag,
  ServiceBagContext,
  ServiceBagProvider,
  useServiceBag,
};
export default serviceBag;
