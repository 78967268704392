import { useCallback, useMemo, useRef, useState } from "react";
import { useServiceBag } from "../services/ServiceBag";
import { type DataCallback, type FailureCallback } from "../services/recorder";

function useRecorder(onDataReceived: DataCallback, onFailure: FailureCallback) {
  const callbackRef = useRef<DataCallback>(() => {
    console.log("not handling audio data.");
    return Promise.resolve();
  });
  const { envService } = useServiceBag();
  const [recordingInProgress, setRecordingInProgress] = useState(false);

  const onAudioData = useCallback((data: string) => {
    return callbackRef.current(data);
  }, []);
  const recorder = useMemo(
    () => envService.recorder(setRecordingInProgress, onAudioData, onFailure),
    [envService, onFailure, onAudioData]
  );

  callbackRef.current = onDataReceived;
  return { recorder, recordingInProgress };
}

export default useRecorder;
