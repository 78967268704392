const typography = {
  h1: {
    // used for the company name header.
    fontSize: "1.2rem",
    fontWeight: 800,
    fontFamily: "sans-serif",
  },
  h2: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  h3: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  h4: {
    fontSize: "1.0rem",
    fontWeight: "bold",
  },
  datum: {
    fontSize: "1.5rem",
    fontWeight: "medium",
  },
  navHeader: {
    fontSize: "1.6rem",
    fontWeight: "bold",
  },
  navItems1: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  navItems2: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  navItems3: {
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    datum: React.CSSProperties;
    navHeader: React.CSSProperties;
    navItems1: React.CSSProperties;
    navItems2: React.CSSProperties;
    navItems3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    datum?: React.CSSProperties;
    navHedaer?: React.CSSProperties;
    navItems1?: React.CSSProperties;
    navItems2?: React.CSSProperties;
    navItems3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    datum: true;
    navHeader: true;
    navItems1: true;
    navItems2: true;
    navItems3: true;
  }
}
export default typography;
