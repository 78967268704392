import * as R from "ramda";
import { useIntl } from "react-intl";
import { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { WebUserRoles, isValidEmail } from "shared-utils";
import { textChangeHandler } from "../utils/eventUtils";
import { useCreateWebUserMutation } from "../graphql/generated";
import { announceOperationResult } from "../utils/snackUtils";
import { WebUserCreation } from "../gql/graphql";
import SelectFromMenu from "./SelectFromMenu";
import AmchurCard from "./AmchurCard";
import { OkButton } from "./standardButtons";
import { SmallSehatSpinner } from "./SehatSpinner";

type ExtraFieldFunction = (
  role: WebUserRoles,
  setValid: (v: boolean) => void
) => React.ReactNode;

type UpdateUserFieldsFunction = (user: WebUserCreation) => WebUserCreation;

interface CreateUserFormProps {
  roleOptions: WebUserRoles[];
  roleDefault: WebUserRoles;
  orgId?: string;
  extraField?: ExtraFieldFunction;
  updateUserFields?: UpdateUserFieldsFunction;
}

type CreateUserFunction = (
  givenName: string,
  familyName: string,
  email: string,
  identifier: string,
  role: WebUserRoles
) => Promise<boolean>;

function CreateUserForm({
  orgId,
  updateUserFields = R.identity,
  ...args
}: CreateUserFormProps) {
  const [createWebUserMutation, { isLoading }] = useCreateWebUserMutation();
  const intl = useIntl();

  const createUser: CreateUserFunction = (
    givenName: string,
    familyName: string,
    email: string,
    externalId: string,
    role: WebUserRoles
  ) => {
    async function createWebUserAsync() {
      // update user fields lets a parent component modify or add fields.
      const webUser = updateUserFields({
        givenName,
        familyName,
        email,
        externalId,
        role,
        orgId,
      });
      const result = await createWebUserMutation({
        webUser,
      });
      return announceOperationResult(result, intl);
    }
    return createWebUserAsync();
  };

  return <CreateUserFormUi {...args} onSubmit={createUser} busy={isLoading} />;
}

interface CreateUserFormUiProps {
  roleOptions: WebUserRoles[];
  roleDefault: WebUserRoles;
  onSubmit: CreateUserFunction;
  busy: boolean;
  extraField?: ExtraFieldFunction;
}

function CreateUserFormUi({
  roleOptions,
  roleDefault,
  onSubmit,
  busy,
  extraField,
}: CreateUserFormUiProps) {
  const intl = useIntl();
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [role, setRole] = useState<WebUserRoles>(roleDefault);
  // used to coordinate with the 'extra' field
  const [valid, setValid] = useState(extraField == null);

  const onOKPressed = useCallback(() => {
    onSubmit(givenName, familyName, email, identifier, role)
      .then((success) => {
        if (success) {
          setGivenName("");
          setFamilyName("");
          setEmail("");
          setIdentifier("");
          setRole(roleDefault);
        }
      })
      .catch(() => {});
  }, [email, familyName, givenName, identifier, onSubmit, role, roleDefault]);

  const readyToSubmit = identifier.length > 0 && isValidEmail(email) && valid;

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const width = isSmallScreen ? "90%" : "60%";

  return (
    <Stack spacing="10px" width={width} mt="2em" mx="2em" justifyContent="left">
      <AmchurCard
        role="primary"
        titleAlign="left"
        title={intl.formatMessage({ id: "createUser.primaryInfo" })}
      >
        <Stack spacing="2em" width="92%" mx="1em" my="1em">
          <TextField
            label={intl.formatMessage({ id: "createUser.email" })}
            value={email}
            onChange={textChangeHandler(setEmail)}
            required={true}
          />
          <TextField
            label={intl.formatMessage({ id: "createUser.identifier" })}
            value={identifier}
            onChange={textChangeHandler(setIdentifier)}
            required={true}
          />
          <TextField
            label={intl.formatMessage({ id: "createUser.givenName" })}
            value={givenName}
            onChange={textChangeHandler(setGivenName)}
          />
          <TextField
            label={intl.formatMessage({ id: "createUser.familyName" })}
            value={familyName}
            onChange={textChangeHandler(setFamilyName)}
          />
          <SelectFromMenu
            options={roleOptions}
            value={role}
            setValue={setRole}
            label={intl.formatMessage({ id: "createUser.role" })}
            format={(x) => intl.formatMessage({ id: `role.${x as string}` })}
            sx={{ width: "100%" }}
            required={true}
          />
          {extraField && extraField(role, setValid)}
        </Stack>
      </AmchurCard>
      <Stack
        spacing="10px"
        justifyContent="flex-start"
        display="flex"
        flexDirection="row"
      >
        <OkButton onClick={onOKPressed} disabled={!readyToSubmit} />
        {busy && <SmallSehatSpinner />}
      </Stack>
    </Stack>
  );
}

export default CreateUserForm;
