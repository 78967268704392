import { VitalsInput } from "../../gql/graphql";
import { VisitInput } from "../../gql/graphql";

// to check wether the passed section has it's fields filled completely | some | none
const sectFieldsStatus = (
  name: string,
  sect: VitalsInput | VisitInput
): string => {
  const objValues = Object.values(sect);
  let count = 0;
  if (name === "vitals") {
    objValues.forEach((val) => {
      if (!Array.isArray(val) && val !== 0) {
        count += 1;
      }
    });
  } else {
    objValues.forEach((val: string) => {
      if (val !== "") {
        count += 1;
      }
    });
  }
  // in case of vitals we need to reduce len by 2 to exclude painPoints array
  const objLength = name === "vitals" ? objValues.length - 2 : objValues.length;
  const fieldsStatus =
    count === objLength ? "all" : count > 0 ? "some" : "none";

  return fieldsStatus;
};

export const fieldsStatus = (
  vitalsInfo: VitalsInput,
  visitInfo: VisitInput
) => {
  let painPointsStatus = false;
  if (vitalsInfo.frontPainPoints?.length || vitalsInfo.backPainPoints?.length) {
    painPointsStatus = true;
  }
  const vitalsStatus = sectFieldsStatus("vitals", vitalsInfo);
  const visitStatus = sectFieldsStatus("visit", visitInfo);
  return { painPointsStatus, vitalsStatus, visitStatus };
};
