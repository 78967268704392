import { type ServiceBag } from "../services/ServiceBag";

export async function createNewSiteNoteFromFile(
  file: File,
  username: string,
  serviceBag: ServiceBag
) {
  const filename = file.name;
  return createNewSiteNote(file, filename, username, serviceBag);
}

export async function createNewSiteNote(
  data: Blob,
  filename: string,
  username: string,
  serviceBag: ServiceBag
) {
  const { apiService, envService, fileService }: ServiceBag = serviceBag;
  const noteId = envService.randomUUID();
  const currentTime = envService.currentTime();

  const s3Url = await apiService.createSiteNote({
    siteNote: {
      user: username,
      createdAt: currentTime.toJSDate(),
      noteId,
      filename,
    },
  });
  if (s3Url != null) {
    await fileService.uploadBlobToS3(s3Url, data);
    return true;
  } else {
    return false;
  }
}
