import * as R from "ramda";
import { useEffect, useState } from "react";
import { removeNils } from "shared-utils";
import {
  type Visit,
  type VisitCriteria,
  type Patient,
  useLazyPatientsByIdQuery,
  useVisitsQuery,
  PatientId,
} from "../graphql/generated";
import useMemoizedRemoveNils from "./useMemoizedRemoveNils";

type PatientList = Partial<Patient>[];

const visitToPaitentId = R.pick(["patientId", "patientIdType"]) as (
  v: Partial<Visit>
) => PatientId;
const extractPatientIdsFromVisits = R.compose(
  R.uniq,
  R.map(visitToPaitentId),
  removeNils<Partial<Visit>>
);

export interface VisitAndPatient {
  visit: Partial<Visit>;
  patient: Partial<Patient>;
}

function matchPatientToVisit(
  patients: Partial<Patient>[],
  visits: Partial<Visit>[]
): VisitAndPatient[] {
  // this isn't super efficient (m*n) but the lists will be small.
  // we can revisit if we need to.
  const pairs = visits.map((visit) => {
    const patient = patients.find(
      (p) =>
        visit.patientId === p.patientId &&
        visit.patientIdType === p.patientIdType
    );

    return patient == null ? null : { visit, patient };
  });
  return removeNils(pairs);
}

function usePatientsForVisitsQueries(criteria: VisitCriteria) {
  const [patientsAndVisits, setPatientsAndVisits] = useState<VisitAndPatient[]>(
    []
  );
  const { data: visitData, isLoading: visitsLoading } = useVisitsQuery({
    criteria: criteria,
  });
  const [trigger, patientData] = useLazyPatientsByIdQuery();

  const visits = useMemoizedRemoveNils(visitData?.visits);
  const patients: PatientList = useMemoizedRemoveNils(
    patientData.data?.patientsById
  );

  // whenever the list of visits changes, update the list of patients.
  useEffect(() => {
    if (visits) {
      const ids = extractPatientIdsFromVisits(visits);
      trigger({ ids })
        .then(() => {})
        .catch(() => {});
    }
  }, [trigger, visits]);

  useEffect(() => {
    setPatientsAndVisits(matchPatientToVisit(patients || [], visits || []));
  }, [patients, visits]);

  const isLoading = visitsLoading || patientData.isLoading;

  return { patientsAndVisits, patients, visits, isLoading };
}

export default usePatientsForVisitsQueries;
