import { useSelector } from "react-redux";
import { RootState } from "../state/reducers";

function useCurrentClinicId() {
  // routes that call this should be protected by SecuredClinicRoute, so clinic should
  // never be null. The || "" just simplifies the typing.
  return useSelector(
    (state: RootState) => state.currentClinic.clinic?.clinicId || ""
  );
}

export default useCurrentClinicId;
