import React from "react";
import { useIntl } from "react-intl";
import { FormControl, Stack, TextField } from "@mui/material";
import { textChangeHandler } from "../../utils/eventUtils";

interface DetailsFormViewProps {
  primaryComplaint: string;
  setPrimaryComplaint: (value: string) => void;
  noteToPhysician: string;
  setNoteToPhysician: (value: string) => void;
  reasonForComing: string;
  setReasonForComing: (value: string) => void;
}

function VisitDetailsFormView({
  primaryComplaint,
  setPrimaryComplaint,
  noteToPhysician,
  setNoteToPhysician,
  reasonForComing,
  setReasonForComing,
}: DetailsFormViewProps) {
  const intl = useIntl();
  return (
    <Stack>
      <FormControl sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        <DetailsFormFiled
          value={primaryComplaint}
          setValue={setPrimaryComplaint}
          label={intl.formatMessage({ id: "visitDetails.primaryComplaint" })}
          testId="primaryComplaint"
        />
        <DetailsFormFiled
          value={noteToPhysician}
          setValue={setNoteToPhysician}
          label={intl.formatMessage({ id: "visitDetails.noteToPhysician" })}
          testId="noteToPhys"
        />
        <DetailsFormFiled
          value={reasonForComing}
          setValue={setReasonForComing}
          label={intl.formatMessage({ id: "visitDetails.reasonForComing" })}
          testId="reasForComing"
        />
      </FormControl>
    </Stack>
  );
}
interface DetailsFormFiledProps {
  testId: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
}

function DetailsFormFiled({
  label,
  value,
  setValue,
  testId,
}: DetailsFormFiledProps) {
  return (
    <TextField
      size="small"
      value={value}
      onChange={textChangeHandler(setValue)}
      label={label}
      inputProps={{ "data-testid": testId }}
      multiline
    />
  );
}

export default VisitDetailsFormView;
