import React, { useEffect, useState } from "react";
import { useIntl, type IntlShape } from "react-intl";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, TextField,Select, MenuItem} from "@mui/material";
import { InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SvgIconComponent } from "@mui/icons-material";
import { textChangeHandler } from "../utils/eventUtils";
import { type BinSet} from "../hooks/useAudioProcessing";
import { AssessmentInput } from "../gql/graphql";
import BinInfoCard from "./BinCard";
import MedicationIcon from '@mui/icons-material/Medication';
import HistoryIcon from '@mui/icons-material/History';
import SickIcon from '@mui/icons-material/Sick';
import MasksIcon from '@mui/icons-material/Masks';
import AllergiesIcon from '../assets/AllergiesIcon.svg';
import PastMedicalHxIcon from '../assets/PastMedicalHxIcon.svg';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { binTitleMap, getEmptyBins, getFieldOptions, getDummyData } from "../utils/BinUtils";
import { SocialHxBinInActive, SocialHxBinActive, MedsBinInActive, MedsBinActive, MedsTradeEditableLayout, AllergiesBinInActive, AllergiesBinActive, PastMedicalHxBinInActive, PastMedicalHxBinActive, FamilyHxBinInActive, FamilyHxBinActive } from "./BinLayouts";
import { useDispatch, useSelector } from "react-redux";
import { update } from "ramda";
import { useBenchmarkSavingMutation } from "../graphql/generated";
import { BenchmarkInput } from "../gql/graphql";
import { RootState } from "../state/reducers";
import useCurrentClinicId from "../hooks/useCurrentClinic";
import useActiveVisit from "../hooks/useActiveVisit";



const ItemTypes = {
  BIN: 'bin',
};

function binTitle(intl: IntlShape, bin: string) {
  // ideally all names should go through i18n, but for now just the ones where
  // we want to rename the field coming from the model.
  if (["drugs", "a_p", "socialHx", "meds", "allergies", "pastMedicalHx", "familyHx"].includes(bin)) {
    return intl.formatMessage({ id: `bin.title.${bin}` });
  } else {
    return bin;
  }
}

//  data is coming (from the ML model) in form of an object with keys containing bin's name and value correponding to an array of objects, where each object in array has key and value of type string, in case a bin has no corresponding data it's value will be an empty array
export interface BinCardStackProps {
  align?: "left" | "right";
  binSet: BinSet;
  revisedBinSet?: BinSet;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady?: boolean;
  isEditable?: boolean;
  isComparable?: boolean;
  Indexes?: number[];
  moveBinAcross?: (bin: number, fromLeft: boolean) => void;
  visitToken?: string;
}


function BinCardStack({
  align,
  binSet,
  revisedBinSet,
  updateAssessment,
  assessmentReady = true,
  isEditable = false,
  isComparable = false,
  Indexes = [1,2,3,4,5,6,7,8,9,10,11],
  moveBinAcross,
  visitToken = ""
}: BinCardStackProps) {
  const [bins, setBins] = useState<any[]>([]);
  const revisedBins = revisedBinSet === undefined ? binSet : revisedBinSet;

  //this is for splitting the bins based on indexes.
    useEffect(() => {
    // console.log("useeffect should be triggered on indexes change")
      setBins(Indexes.map((index) => binTitleMap[index]));
  }, [align, binSet, revisedBinSet, Indexes]);

  // ORIGINAL CODE (no drag and drop):
  
  return (
    <>
      {bins.map((bin) => (
        <React.Fragment key={bin}>
          <BinCard
            bin={binSet[bin as keyof BinSet] || "[]"}
            revisedBin={revisedBins[bin as keyof BinSet] || "[]"}
            title={bin}
            updateAssessment={updateAssessment}
            assessmentReady={assessmentReady}
            isEditable={isEditable}
            isComparable={isComparable}
            visitToken={visitToken}
          />
        </React.Fragment>
      ))}
    </>
  );

  //THIS CODE IS FOR RE-ENABLING DRAG AND DROP

  // console.log(`binSet (at the start of bincardstack) is: ${JSON.stringify(binSet)}`);
  // const moveBin = (dragIndex: number, hoverIndex: number) => {
  //   const draggedBin = bins[dragIndex];
  //   const newBins = [...bins];
  //   newBins.splice(dragIndex, 1);
  //   newBins.splice(hoverIndex, 0, draggedBin);
  //   setBins(newBins);
  // };
  // return (
  //   <>
  //     {bins.map((bin, index) => (
  //       <DraggableBinCard
  //         key={bin}
  //         index={index}
  //         bin={bin}
  //         binSet={binSet}
  //         revisedBinSet={revisedBins}
  //         moveBin={moveBin}
  //         updateAssessment={updateAssessment}
  //         assessmentReady={assessmentReady}
  //         isEditable={isEditable}
  //         isComparable={isComparable}
  //         moveBinAcross={moveBinAcross}
  //         fromLeft={align === "left"}
  //       />
  //     ))}
  //   </>
  // );
}

interface DraggableBinCardProps {
  bin: string;
  index: number;
  binSet: BinSet;
  revisedBinSet: BinSet;
  moveBin: (dragIndex: number, hoverIndex: number) => void;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady?: boolean;
  isEditable?: boolean;
  isComparable?: boolean;
  moveBinAcross?: (bin: number, fromLeft: boolean) => void;
  fromLeft?: boolean;
}

interface DragItem {
  index: number;
  type: string;
  fromLeft: boolean;
  bin: string;
}

const DraggableBinCard = ({
  bin,
  index,
  binSet,
  revisedBinSet,
  moveBin,
  updateAssessment,
  assessmentReady = true,
  isEditable = true,
  isComparable = true,
  moveBinAcross,
  fromLeft = false,
}: DraggableBinCardProps) => {
  const ref = React.useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.BIN,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = (ref.current as HTMLElement)?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : 0;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveBin(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item: DragItem, monitor: DropTargetMonitor) => {
      // console.log("monitor.didDrop()", monitor.didDrop());
      if (moveBinAcross !== undefined &&  !monitor.didDrop()) {
        if (item.fromLeft !== fromLeft) {
          const binIndex = Number(Object.entries(binTitleMap).find(([key, value]) => value === item.bin)?.[0] ?? -1);
          moveBinAcross(binIndex, fromLeft);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.BIN,
    item: { type: ItemTypes.BIN, index, fromLeft, bin },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} data-handler-id={handlerId}>
      <BinCard
        bin={binSet[bin as keyof BinSet] || "[]"}
        revisedBin={revisedBinSet[bin as keyof BinSet] || "[]"}
        title={bin}
        updateAssessment={updateAssessment}
        assessmentReady={assessmentReady}
        isEditable={isEditable}
        isComparable={isComparable}
        visitToken="dummy"
      />
    </div>
  );
};

interface BinCardProps {
  bin: string;
  revisedBin: string;
  title: string;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady: boolean;
  isEditable: boolean;
  isComparable: boolean;
  visitToken: string;
}

const getIconForTitle = (title: string) => {
  switch (title) {
    case 'socialHx':
      return <HistoryIcon style={{ marginRight: 8, marginBottom: 0, fontSize: 32 }} />;
    // case 'meds':
    //   return <MedicationIcon style={{ marginRight: 8, marginBottom: 0, fontSize: 32  }} />;
    // case 'hopi':
    //   return <SickIcon style={{ marginRight: 8, marginBottom: 0, fontSize: 32  }} />;
    // case 'allergies':
    //   return <img src={AllergiesIcon} style={{ marginRight: 8, marginBottom: 0, width: 32, height: 32 }} />;
    // case 'pastMedicalHx':
    //   return <img src={PastMedicalHxIcon} style={{ marginRight: 8, marginBottom: 0, width: 32, height: 32 }} />;
    case 'familyHx':
      return <HistoryIcon style={{ marginRight: 8, marginBottom: 0, fontSize: 32 }} />;
    default:
      return null;
  }
};
function BinCard({
  bin,
  revisedBin,
  title,
  updateAssessment = () => {},
  assessmentReady,
  isEditable,
  isComparable,
  visitToken,
}: BinCardProps) {
  const intl = useIntl();
  const [inEditMode, setEditMode] = useState(false);
  const [inCompMode, setCompMode] = useState(false);
  const [isActive, setActive] = useState(true);

  // console.log(`Bin (before json parsing in bin card) is: ${JSON.stringify(bin)} for title: ${title}`); 

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [binState, setBinState] = useState<any>([]);
  useEffect(() => {
    // bin contain original bin data while revisedBin contains modified one
    const binData = JSON.parse(revisedBin);
    // console.log(`Bin (after json parsing in bin card) is: ${JSON.stringify(binData)} for title: ${title}`);
    // console.log(`binData is: ${JSON.stringify(binData)} for bin: ${title}`);
    // console.log("[IN BIN CARD STACK], revised bin useeffect called, revised bin is: ", revisedBin, "binData is: ", binData, "title is: ", title);
    setBinState(binData);
  }, [revisedBin, title]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateBin = (currState: { [key: string]: any }[]) => { //i think this function is for manually updating the bin
    const updatedBin = { [title]: JSON.stringify(currState) };
    console.log("updatedBin", updatedBin);
    updateAssessment(updatedBin);
  };


  const handleEditField = ( // this function is for editing the field manually
    index: number,
    updatedObj: { [key: string]: string }
  ) => {
    // console.log("[TESTING EDIT FIELD] about to update Editted Field");
    const newState = [...binState];
    newState[index] = updatedObj;
    console.log("new updated field", newState);
    updateBin(newState);
    setBinState(newState);
  };


  const handleDeleteField = (index: number, key: string) => { //for mnaually deleting the bin field //TODO: integrate delete when model is fixed.
    console.log(
      "handle delete",
      index,
      key,
      "binState",
      [...binState],
      "old way",
      binState
    );
    let newState = [...binState];
    delete newState[index][key];
    newState = newState.filter((obj) => JSON.stringify(obj) !== "{}"); // removing any empty object
    console.log("newState", newState);
    updateBin(newState);
    setBinState(newState);
  };

  const handleNewField = (key: string, value: string) => { //adding new field separately
    const newState = [...binState, { [key]: value }];
    updateBin(newState);
    setBinState(newState);
  };




  const icon = getIconForTitle(title); 
  return (
    <BinInfoCard
      title={
        icon ? (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
            <Box>{icon}</Box>
            <Box>{binTitle(intl, title)}</Box>
            </Stack>
          </>
        ) : (
          binTitle(intl, title)
        )
      } //The commented lines are for testing for card stack in dev mode only
      role="secondary"
      inTextAlign="left"
      allowEdit={isEditable && isActive}
      // allowEdit={isEditable}
      inEditMode={inEditMode}
      onEditStateChanged={setEditMode}
      allowComparison={isComparable && isActive}
      // allowComparison={isComparable}
      inCompMode={inCompMode}
      onCompModeChanged={setCompMode}
      isActive={isActive}
      // isActive={true}
      titleColor="white"
      titleVarient="h3"
    >
      {/* Remove the compMode for more clarify */}
      {/* {inCompMode ? (
        <BinInfoView
          key={"original"}
          title = {title}
          //check if title is not meds, then json  parse it, else pas it as it is
          // bin={title !== "meds" ? JSON.parse(bin) : bin}
          bin={JSON.parse(bin)}
          inEditMode={false}
          inCompMode={inCompMode}
          onEdit={handleEditField}
          onDelete={handleDeleteField}
          onAdd={handleNewField}
          assessmentReady={assessmentReady}
          setActive={setActive}
          setEditMode={setEditMode}
        />
      ) : ( */}
        <BinInfoView
          key={"revised"}
          title={title}
          bin={binState}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          onEdit={handleEditField}
          onDelete={handleDeleteField}
          onAdd={handleNewField}
          assessmentReady={assessmentReady}
          setActive={setActive}
          setEditMode={setEditMode}
          updateBin={updateBin}
          setBinState={setBinState}
          visitToken={visitToken}
        />
      {/* )} */}
    </BinInfoCard>
  );
}

interface BinInfoViewProps {
  bin: (Record<string, string> | string)[];
  title: string
  inEditMode: boolean;
  inCompMode: boolean;
  onEdit: (index: number, obj: { [key: string]: string }) => void;
  onDelete: (index: number, key: string) => void;
  onAdd: (key: string, value: string) => void;
  assessmentReady: boolean;
  setActive: (b: boolean) => void;
  setEditMode: (b: boolean) => void;
  updateBin: (currState: { [key: string]: any }[]) => void;
  setBinState: (newState: { [key: string]: any }[]) => void;
  visitToken: string;
}

function BinInfoView({
  bin,
  title,
  inEditMode,
  inCompMode,
  onEdit,
  onDelete,
  onAdd,
  assessmentReady,
  setActive,
  setEditMode,
  updateBin,
  setBinState,
  visitToken
}: BinInfoViewProps) {
  const intl = useIntl();
  // console.log(`[JUST UNCOMMENTED] Bin (in infoview) is: ${JSON.stringify(bin)} for title: ${title}`); //TEST THIS FOR INDEXES/EDITS
  // if (title === "meds") {
  //   bin = `[${bin}]`;
  // }
  if (bin.length > 0) {
    // console.log(`Entered bin.length > 0 for title: ${title}`);
    // ISSUE OF DUPLICATE ENTRIES RESOLVED HERE, THIS IS HOW THEY WERE MAKING DIFFERENT STACKS OF THE SAME INFORMATION.
    // if (title === "meds" || title === "allergies" || title === "familyHx") { 
    if (title === "familyHx") {
      return (
        <BinItemView
          title={title}
          bin={bin}
          index={-1}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          updateField={onEdit}
          deleteField={onDelete}
          setActive={setActive}
          setEditMode={setEditMode}
          updateBin={updateBin}
          setBinState={setBinState}
          visitToken={visitToken}
        />
      );

      } else {
        return (
          <Stack gap={2}>
            {bin.map((item, index) => (
              <BinItemView
                title={title}
                bin={item}
                key={index}
                index={index}
                inEditMode={inEditMode}
                inCompMode={inCompMode}
                updateField={onEdit}
                deleteField={onDelete}
                setActive={setActive}
                setEditMode={setEditMode}
                updateBin={updateBin}
                setBinState={setBinState}
                visitToken={visitToken}
              />
            ))}
            {/* {inEditMode && <AddBinItem addField={onAdd} />} */}
          </Stack>
      );
    }


  } else {
    // for bin with no data from ML model
    return (
      <>
        <BinItemView
          bin={
            assessmentReady
              ? intl.formatMessage({ id: "bin.notMentioned" })
              : ""
          }
          title={title}
          index={-1}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          updateField={onEdit}
          deleteField={onDelete}
          setActive={setActive}
          setEditMode={setEditMode}
          updateBin={updateBin}
          setBinState={setBinState}
          visitToken={visitToken}
        />
        {/* {inEditMode && <AddBinItem addField={onAdd} />} */}
      </>
    );
  }
}

interface BinItemViewProps {
  title: string
  bin: Record<string, string> | string | any;
  index: number;
  inEditMode: boolean;
  inCompMode: boolean;
  updateField: (index: number, obj: { [key: string]: string }) => void;
  deleteField: (index: number, key: string) => void;
  setActive: (b: boolean) => void;
  setEditMode: (b: boolean) => void;
  updateBin: (currState: { [key: string]: any }[]) => void;
  setBinState: (newState: { [key: string]: any }[]) => void;
  visitToken: string;
}
function BinItemView({
  title,
  bin,
  index,
  inEditMode,
  inCompMode,
  updateField,
  deleteField,
  setActive,
  setEditMode,
  updateBin,
  setBinState,
  visitToken,
}: BinItemViewProps) {
  const [itemState, setItemState] = useState<{ [key: string]: string } | any>({}); //we are somehow retrieiving the states and setting them here. find out more on how we are retrieiving the states
  // If testing locally with dummy values, use this:
  // const [currState, setCurrState] = useState<{ [key: string]: string } | any>(getDummyData(title));
  // else, use this for deployment/official testing
  const [currState, setCurrState] = useState<{ [key: string]: string } | any>({});
  const patient = useSelector(
    (state: RootState) => state.currentPatient.patient
  );
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );
  const clinicId = useCurrentClinicId();
  const { visit, visitId, needToLoadVisit } = useActiveVisit(clinicId);
   
  const [createBenchmarkMutation] = useBenchmarkSavingMutation();
  
  useEffect(() => {
    // ignoring any string data
    // console.log(`[BinItemView ${title}] type of bin is: ${typeof bin} and bin is: ${bin}`); //should be getting 1 item of medicine
    if (typeof bin === "object" && bin !== null) {
      // console.log(" inside binitemview, bin is: ", JSON.stringify(bin));
      setItemState(bin);
      setCurrState(bin); //addding our own edits variable
      setActive(true);
      if (title === "socialHx") { //this is for setting the socialHx and meds bin to active mode
        setEditMode(true);
      }
    }
    if (title === "meds" || title === "allergies" || title === "familyHx") { //setting them active anyways and will work on dummy setup tonight
      setActive(true);
    }
  }, [bin]);

  useEffect(() => {
    if (
      !inEditMode &&
      Object.keys(itemState).length !== 0 &&
      JSON.stringify(itemState) !== JSON.stringify(bin)
    ) {
      console.log("itemState", itemState);
      console.log("index", index); //investigate here
      updateField(index, itemState); //this is where it is updating
    }
  }, [inEditMode, bin, index, itemState, updateField]);

  useEffect(() => {
    console.log("curr state triggered, new is: ", JSON.stringify(currState));
  }, [currState]);

  const onStateChange = (key: string, value: string, updateState: any = null) => { //CHECKPOINT basically this is the function for item state being 
    // passed ahead to the bins, modify this. Same function is being invoked for adding new things and updating current, so write logic accordingly
    // console.log(`On State Change called with key ${key} and val ${value}`);
    // console.log(`itemState currently is: ${JSON.stringify(itemState)}`);  
    let patientID: string = patient?.patientId || "";
    const currentDate = new Date().toISOString().split('T')[0]; // Gets the date in "YYYY-MM-DD" format
    // console.log("[TESTING PRIMARY KEY] patientId is: ",patient?.patientId,  "visitToken is: ", visitToken, "currentPhysician is: ", currentPhysician, "currentDate is", currentDate);
    console.log("[TESTING PK] visitId is: ", visitId)
    const Alternate = visitToken.concat(currentPhysician, currentDate);

    const orgKey = key;  

    if (key.includes("#")) {
      let keySplit = key.split("#");
      let title = keySplit[0];
      // console.log("title", title);
      key = keySplit[1];
      // console.log("key", key);
    }

    if (title === "meds") {
      // console.log("entered meds");
      // bin is an array of objects, so we need to update the object which has Trade_Name as key, replace it with the value string, as a parsed json object
      const newState = [...currState];
      const index = newState.findIndex((obj) => obj.Trade_Name === key);
      console.log("[In onstate change function] currState: ", JSON.stringify(currState));  
      if (index !== -1) { //if exists
        newState[index] = JSON.parse(value);
        // console.log("newState", newState);
        setCurrState(newState);
        updateState(newState);
        const benchmarkInput: BenchmarkInput = {
          benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
          socialHx: "", 
          familyHx: "",
          meds: JSON.stringify({ newState }),
          habits: "",
          allergies: "",      
        };
        // Handle the promise with .then and .catch
        createBenchmarkMutation({ benchmark: benchmarkInput })
        .then(response => {
          console.log("[MEDS] Mutation successful:", response);
        })
        .catch(error => {
          console.error("[MEDS] Mutation failed:", error);
        });
      } else { //if new
        const newerState = [...currState, JSON.parse(value)];
        // console.log("newerState", newerState);
        setCurrState(newerState);
        updateState(newerState);
        const benchmarkInput: BenchmarkInput = {
          benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
          socialHx: "", 
          familyHx: "",
          meds: JSON.stringify({ newerState }),
          habits: "",
          allergies: "",      
        };
        // Handle the promise with .then and .catch
        createBenchmarkMutation({ benchmark: benchmarkInput })
        .then(response => {
          console.log("[MEDS] Mutation successful:", response);
        })
        .catch(error => {
          console.error("[MEDS] Mutation failed:", error);
        });
      } 

    } else if (title === "familyHx") {
      let keySplit = orgKey.split("#");
      let scenario = keySplit[1];
      console.log("[TESTING CONDITION EDIT], in familyHX, keysplit is: ", keySplit);

        if (scenario === "Condition") {
          console.log("Family hx Condition Edit triggered");
          let relation = keySplit[2];
          let orgCondition = keySplit[3];
          const newState = [...currState];
          const index = newState.findIndex((obj) => obj.Relation === relation); 
          if (index !== -1) { 
              const newValue = JSON.parse(value);
              newState[index]["Medical_condition"] = newState[index]["Medical_condition"].map((condition: any) => {
                if (condition["Name"] === orgCondition) {
                  return newValue;
                }
                return condition;
              });
            console.log("[FAMILY HX] Condition Edit state is: ", JSON.stringify(newState));
            setCurrState(newState);
            updateState(newState);
            const benchmarkInput: BenchmarkInput = {
              benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
              socialHx: "", 
              familyHx: JSON.stringify({ newState }),
              meds: "",
              habits: "",
              allergies: "",      
            };
            createBenchmarkMutation({ benchmark: benchmarkInput })
            .then(response => {
              console.log("Mutation successful:", response);
            })
            .catch(error => {
              console.error("Mutation failed:", error);
            });

          } 
        } else if (scenario === "AddCondition")  {
          console.log("Family Hx Add Condition triggered");
          let relation = keySplit[2];
          const newState = [...currState];
          const index = newState.findIndex((obj) => obj.Relation === relation); 
          console.log("newState is: ", JSON.stringify(newState), "index is: ", index);
          if (index !== -1) { 
            const newValue = JSON.parse(value);
            newState[index]["Medical_condition"].push(newValue); 
            console.log("[FAMILY HX] Condition Add state is: ", JSON.stringify(newState));
            setCurrState(newState);
            updateState(newState);
            const benchmarkInput: BenchmarkInput = {
              benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
              socialHx: "", 
              familyHx: JSON.stringify({ newState }),
              meds: "",
              habits: "",
              allergies: "",      
            };
            createBenchmarkMutation({ benchmark: benchmarkInput })
            .then(response => {
              console.log("Mutation successful:", response);
            })
            .catch(error => {
              console.error("Mutation failed:", error);
            });
        } 
      } else if (scenario === "Relation") {
        console.log("Family Hx Relation Edit triggered");
        let relation = keySplit[2];
        const newState = [...currState];
        const index = newState.findIndex((obj) => obj.Relation === relation); 
        if (index !== -1) { 
          const newValue = JSON.parse(value);
          newState[index] = newValue;

          let PriorityOrder = getFieldOptions("familyHx", "Relation");
          let PriorityOrderAdopted = getFieldOptions("familyHx", "Relation_adopted");
          newState.sort((a, b) => {
            const aPriority = PriorityOrderAdopted.indexOf(a["Relation"]) !== -1 ? PriorityOrderAdopted.indexOf(a["Relation"]) : PriorityOrder.indexOf(a["Relation"]);
            const bPriority = PriorityOrderAdopted.indexOf(b["Relation"])  !== -1 ? PriorityOrderAdopted.indexOf(b["Relation"]) : PriorityOrder.indexOf(b["Relation"]);
            return (aPriority === -1 ? Infinity : aPriority) - (bPriority === -1 ? Infinity : bPriority);
          });

          console.log("[FAMILY HX] Condition Add state is: ", JSON.stringify(newState));
            setCurrState(newState);
            updateState(newState);
            const benchmarkInput: BenchmarkInput = {
              benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
              socialHx: "", 
              familyHx: JSON.stringify({ newState }),
              meds: "",
              habits: "",
              allergies: "",      
            };
            createBenchmarkMutation({ benchmark: benchmarkInput })
            .then(response => {
              console.log("Mutation successful:", response);
            })
            .catch(error => {
              console.error("Mutation failed:", error);
            });
        }
      } else if (scenario === "DelCondition") {
        console.log("Family Hx Del Condition triggered");
        let relation = keySplit[2];
        const newState = [...currState];
        const index = newState.findIndex((obj) => obj.Relation === relation); 
        if (index !== -1) { 
          const newValue = JSON.parse(value);
          newState[index]["Medical_condition"] = newValue;
          console.log("[FAMILY HX] Condition Del state is: ", JSON.stringify(newState));
            setCurrState(newState);
            updateState(newState);
            const benchmarkInput: BenchmarkInput = {
              benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
              socialHx: "", 
              familyHx: JSON.stringify({ newState }),
              meds: "",
              habits: "",
              allergies: "",      
            };
            createBenchmarkMutation({ benchmark: benchmarkInput })
            .then(response => {
              console.log("Mutation successful:", response);
            })
            .catch(error => {
              console.error("Mutation failed:", error);
            });
        }
      } else if (scenario === "DelRelation") {
        console.log("Family Hx Del Relation triggered");
        let relation = keySplit[2];
        const newState = [...currState];
        const index = newState.findIndex((obj) => obj.Relation === relation); 
        if (index !== -1) { 
          newState.splice(index, 1);
          console.log("[FAMILY HX] Relation Del state is: ", JSON.stringify(newState));
            setCurrState(newState);
            updateState(newState);
            const benchmarkInput: BenchmarkInput = {
              benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
              socialHx: "", 
              familyHx: JSON.stringify({ newState }),
              meds: "",
              habits: "",
              allergies: "",      
            };
            createBenchmarkMutation({ benchmark: benchmarkInput })
            .then(response => {
              console.log("Mutation successful:", response);
            })
            .catch(error => {
              console.error("Mutation failed:", error);
            });
        }
      } else if (scenario === "AddRelation") {
        console.log("Family Hx Add Relation triggered");
        const newState = [...currState, JSON.parse(value)];
        console.log("[FAMILY HX] Relation Add state is: ", JSON.stringify(newState));
        let PriorityOrder = getFieldOptions("familyHx", "Relation");
          let PriorityOrderAdopted = getFieldOptions("familyHx", "Relation_adopted");
          newState.sort((a, b) => {
            const aPriority = PriorityOrderAdopted.indexOf(a["Relation"]) !== -1 ? PriorityOrderAdopted.indexOf(a["Relation"]) : PriorityOrder.indexOf(a["Relation"]);
            const bPriority = PriorityOrderAdopted.indexOf(b["Relation"])  !== -1 ? PriorityOrderAdopted.indexOf(b["Relation"]) : PriorityOrder.indexOf(b["Relation"]);
            return (aPriority === -1 ? Infinity : aPriority) - (bPriority === -1 ? Infinity : bPriority);
          });
        setCurrState(newState);
        updateState(newState);
        const benchmarkInput: BenchmarkInput = {
          benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
          socialHx: "", 
          familyHx: JSON.stringify({ newState }),
          meds: "",
          habits: "",
          allergies: "",      
        };
        createBenchmarkMutation({ benchmark: benchmarkInput })
        .then(response => {
          console.log("Mutation successful:", response);
        })
        .catch(error => {
          console.error("Mutation failed:", error);
        });
      } else if (scenario === "UnknownHistory") {
        console.log("Family Hx Unknown/ Not significant Relation triggered");
        const newState = [JSON.parse(value)];
        console.log("[FAMILY HX] Relation Unknown/not significant state is: ", JSON.stringify(newState));
        setCurrState(newState);
        updateState(newState);
        const benchmarkInput: BenchmarkInput = {
          benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
          socialHx: "", 
          familyHx: JSON.stringify({ newState }),
          meds: "",
          habits: "",
          allergies: "",      
        };
        createBenchmarkMutation({ benchmark: benchmarkInput })
        .then(response => {
          console.log("Mutation successful:", response);
        })
        .catch(error => {
          console.error("Mutation failed:", error);
        });
      } else if (scenario === "RevertStatus") {
        console.log("Family Hx Revert status triggered");
        const newState = [JSON.parse(value)];
        console.log("[FAMILY HX] Relation revert status state is: ", JSON.stringify(newState));
        setCurrState(newState);
        updateState(newState);
        const benchmarkInput: BenchmarkInput = {
          benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
          socialHx: "", 
          familyHx: JSON.stringify({ newState }),
          meds: "",
          habits: "",
          allergies: "",      
        };
        createBenchmarkMutation({ benchmark: benchmarkInput })
        .then(response => {
          console.log("Mutation successful:", response);
        })
        .catch(error => {
          console.error("Mutation failed:", error);
        });
      }
          
    }else { //this is triggered for social History bin
      const benchmarkInput: BenchmarkInput = {
        benchmarkId: "DoctorEdits-Benchmark-".concat(visitId ?? Alternate),
        socialHx: JSON.stringify({ ...itemState, [key]: value }), 
        familyHx: "",
        meds: "",
        habits: "",
        allergies: "",      
      };
      // Handle the promise with .then and .catch
      createBenchmarkMutation({ benchmark: benchmarkInput })
      .then(response => {
        console.log("Mutation successful:", response);
      })
      .catch(error => {
        console.error("Mutation failed:", error);
      });

      console.log("[ONSTATE TRIGGERED FOR SOCIAL HX] value change being callled");
      const newState = { ...itemState, [key]: value };
      console.log("newState", newState);
      setItemState(newState);
  }
  };

  const DeleteItemFromBin = (key: string, reason: string, updateState: any = null) => {
    
    const keySplit = key.split("#");
    const title = keySplit[0];
    console.log("title", title);
    key = keySplit[1];
    console.log("key", key);

    if (title === "allergies") {
      const newState = [...itemState];
      const index = newState.findIndex((obj) => obj.Allergen === key);
      console.log("index", index);  
      if (index !== -1) {
        //now remove the obj at this index
        newState.splice(index, 1);
        console.log("newState", newState);
        setItemState(newState);
        updateBin(newState);
        setBinState(newState);
      } 
    }
  }

  // console.log(`At the end of the BinItemView function, itemstate is: ${JSON.stringify(itemState)} and title is: ${title}, bin is ${bin} and inEditMode is: ${inEditMode}`);

  // if (inCompMode) {
  //   return <NonEditableBinItemView bin={bin} title={title} onStateChange={onStateChange}  DeleteItemFromBin={DeleteItemFromBin}/>;
  // }
  if (inEditMode) {
    return (
      <EditbaleBinItemView
        title={title}
        itemState={itemState}
        index={index}
        updateState={onStateChange} // update state and onstatechange are the same functions :O
        deleteField={deleteField}
      />
    );
  } else {
    return <NonEditableBinItemView bin={bin} title={title} onStateChange={onStateChange} DeleteItemFromBin={DeleteItemFromBin}/>;
  }
}

function NonEditableBinItemView({ //this is the function that i need to change to change the layout of the individual items.
  bin,
  title,
  onStateChange,
  DeleteItemFromBin,
}: {
  bin: Record<string, string> | string | any;
  title: string;
  onStateChange: (key: string, value: string) => void;
  DeleteItemFromBin: (key: string, reason: string, updateState: any) => void;
}) {
  const intl = useIntl();


  if (typeof bin === "string") {
    // console.log(`Title is: ${title} and bin is: ${bin}`);
    //for demonstrating inactive socialHx bin
    const InactiveState = getEmptyBins(title);
    if (title === "socialHx") {
      // FOR INACTIVE STATE:
      return <SocialHxBinInActive Bin={InactiveState} />;

      // FOR ACTIVE STATE
      // const DummySocialHx = getDummyData("socialHxModel");
      // return  <SocialHxBinActive Bin={DummySocialHx} title={title} updateState={onStateChange} />
  
    //  } else if (title === "meds") {
      // FOR INACTIVE STATE
      // return <MedsBinInActive Bin={InactiveState} />;

      // FOR ACTIVE STATE
      // const DummyMeds = getDummyData(title);
      // return <MedsBinActive Bin={DummyMeds} onStateChange={onStateChange} />

    } else if (title === "familyHx") {
      // FOR INACTIVE STATE
      return <FamilyHxBinInActive Bin={InactiveState} />;

      // FOR ACTIVE STATE
      // const DummyMeds: any = getDummyData(title);
      // return <FamilyHxBinActive Bin={DummyMeds} onStateChange={onStateChange} />
      
    } //else if (title=== "allergies") {
    //   // FOR INACTIVE STATE
    //   // return <AllergiesBinInActive Bin={InactiveState} />

    //   // FOR ACTIVE STATE
    //   const DummyAllergies = getDummyData(title);
    //   return < AllergiesBinActive Bin={DummyAllergies} onStateChange={onStateChange}  DeleteItemFromBin={DeleteItemFromBin}/>

    // } else if (title === "pastMedicalHx") {
    //    // Inactive state:
    //   // return <PastMedicalHxBinInActive Bin={InactiveState} />;
    //   // Active state:
    //   const DummyPastMedicalHx = getDummyData(title);
    //   return <PastMedicalHxBinActive Bin={DummyPastMedicalHx} onStateChange={onStateChange} DeleteItemFromBin={DeleteItemFromBin} />;
    // }

    // console.log("Bin is: ", bin );

    return (
      <Stack>
        {Object.keys(bin).map((key) => (
          <Box key={key} mb={2}>
            <Typography variant="h6" component="span" sx={{ color: 'gray' }}>
              {key.replace(/_/g, ' ')}:
            </Typography> 
          </Box>
        ))}
      </Stack>
    );
  }

  // for demonstrating active socialHx bin
  const ActiveSHX = getDummyData(title);
  // console.log(`Active Title is: ${title} and bin is: ${bin}`);
  // else if (title === "allergies") {
  //   console.log(`Allergies bin received:  ${JSON.stringify(bin)}`)
  //   // const DummyAllergies = getDummyData(title);
  //     return < AllergiesBinActive Bin={bin} onStateChange={onStateChange} DeleteItemFromBin={DeleteItemFromBin} />
  // } else if (title === "pastMedicalHx") {
  //   const DummyPastMedicalHx = getDummyData(title);
  //   return <PastMedicalHxBinActive Bin={DummyPastMedicalHx} onStateChange={onStateChange} DeleteItemFromBin={DeleteItemFromBin} />;
  // } else 

  // console.log("bin is: ", bin);
  if (title === "socialHx") {
    return  <SocialHxBinActive Bin={bin} title={title} updateState={onStateChange} />
  // } else if (title === "meds") {
    // return <MedsBinActive Bin={bin} onStateChange={onStateChange} />
  } else if (title === "familyHx") {
    return <FamilyHxBinActive Bin={bin} onStateChange={onStateChange} />
  }
  return (
    //this is where to return active layouts
    <Stack>
    {Object.keys(bin).map((key) => (
      <Box key={key} mb={0}>
        <Typography variant="h6" component="span" sx={{ color: 'black' }}>
          {key}:
        </Typography>
        <br/>
        <Typography
          component="span"
          sx={{ color: bin[key] ? 'gray' : 'gray', ml: 1 }}
        >
          {bin[key] || "ND"} 
        </Typography>
      </Box>
    ))}
  </Stack>
  );
}

interface EditbaleBinItemViewProps {
  title: string
  index: number;
  itemState: { [key: string]: string };
  updateState: (key: string, value: string) => void;
  deleteField: (index: number, key: string) => void;
}
function EditbaleBinItemView({ //this is the function we want to change to turn the textfield into select items field.
  title,
  index,
  itemState,
  updateState,
  deleteField,
}: EditbaleBinItemViewProps) {

//for demonstrating active editable socialHx bin
// console.log(`Item state is: ${itemState} for bin title: ${title}`);
if (title === "socialHx") {
  // console.log("itemSHXstate", itemState);
  return (
      <SocialHxBinActive Bin={itemState} title={title} updateState={updateState} />
  )
} //else if (title === "meds") {
  // console.log("[IN MEDS EDITABLE] bin is:", itemState);
  // return (
  //   <MedsBinActive Bin={itemState} />
  // )
//}
  return (
    <Stack>
      {Object.keys(itemState).map((key) => (
        <TextField
          size="small"
          variant="filled"
          key={key}
          label={key}
          value={itemState[key]}
          onChange={(e) => updateState(key, e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    console.log("itemState", itemState);
                    deleteField(index, key);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      ))}
    </Stack>
  );
}

function AddBinItem({
  addField,
}: {
  addField: (key: string, value: string) => void;
}) {
  const [formToggle, setFormToggle] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const intl = useIntl();

  const toggleForm = () => {
    setFormToggle(!formToggle);
  };
  const handleNewField = () => {
    if (title && description) {
      addField(title, description);
      setTitle("");
      setDescription("");
      toggleForm();
    }
  };
  const readyToSubmit = title.length > 0 && description.length > 0;
  return (
    <Stack gap={2}>
      <BinButton
        Icon={AddIcon}
        text={intl.formatMessage({ id: "bin.addField" })}
        width={1 / 2}
        onClick={toggleForm}
      />
      {formToggle && (
        <Stack gap={2}>
          <TextField
            required={true}
            size="small"
            label="title"
            value={title}
            onChange={textChangeHandler(setTitle)}
          ></TextField>
          <TextField
            required={true}
            size="small"
            label="description"
            value={description}
            onChange={textChangeHandler(setDescription)}
          ></TextField>
          <BinButton
            disabled={!readyToSubmit}
            text={intl.formatMessage({ id: "common.ok" })}
            isPrimary={true}
            width={1 / 5}
            onClick={handleNewField}
          />
        </Stack>
      )}
    </Stack>
  );
}

interface BinButtonProps {
  text: string;
  Icon?: SvgIconComponent;
  width: number;
  isPrimary?: boolean;
  disabled?: boolean;
  onClick: () => void;
}
function BinButton({
  text,
  Icon,
  width,
  disabled = false,
  isPrimary = false,
  onClick,
}: BinButtonProps) {
  return (
    <Button
      variant="contained"
      color={isPrimary ? "primary" : "secondary"}
      sx={{ width: width, margin: "auto" }}
      endIcon={Icon && <Icon />}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
export default BinCardStack;