import { uploadPatientPhoto } from "../domain/patientDomain";
import { type Patient } from "../graphql/generated";
import { createApiThunk } from "./amchurMiddleware";

export interface UploadPatientPhotoPayload {
  imageBase64: string;
  patient: Patient;
}

export const uploadPatientPhotoAction = createApiThunk(
  "patient/uploadPhoto",
  async (photoUploadInfo: UploadPatientPhotoPayload, serviceBag) => {
    return uploadPatientPhoto(
      photoUploadInfo.patient,
      photoUploadInfo.imageBase64,
      serviceBag.apiService,
      serviceBag.fileService
    );
  }
);
