import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { getSiteNoteDownloadUrlMiddleware } from "../../middleware/siteNoteMiddleware";
import { AppDispatch } from "../../state/store";
import { useServiceBag } from "../../services/ServiceBag";
import { SiteNoteModel } from "../../models/modelTypes";
import { useSiteNotesQuery } from "../../hooks/apiHooks/queryHooks";
import OneSiteNoteView from "./OneSiteNoteView";

function SiteNotesList() {
  const dispatch = useDispatch<AppDispatch>();
  const { fileService } = useServiceBag();

  const { data, isFetching } = useSiteNotesQuery({
    criteria: { since: "2010-01-01" },
  });

  const siteNotes = data;

  const onClickSiteNote = (id: string) => {
    void dispatch(getSiteNoteDownloadUrlMiddleware(id)).then((result) => {
      if (getSiteNoteDownloadUrlMiddleware.fulfilled.match(result)) {
        console.log("url", result.payload);
        if (result.payload && result.payload) {
          // this disable shouldn't be necessary, but eslint is complaining
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
          fileService.downloadFromS3(result.payload[0], `sitenote-${id}.txt`);
        }
      }
    });
  };

  if (isFetching) {
    return <Typography>loading...</Typography>;
  }

  return <SiteNotesListUi siteNotes={siteNotes} onClick={onClickSiteNote} />;
}

interface SiteNotesListUiProps {
  siteNotes: SiteNoteModel[];
  onClick: (id: string) => void;
}

function SiteNotesListUi({ siteNotes, onClick }: SiteNotesListUiProps) {
  return (
    <Table>
      <TableBody>
        {siteNotes.map((siteNote) => (
          <OneSiteNoteView
            siteNote={siteNote}
            onClick={onClick}
            // onChangeSelectState={onCheckboxClick(recording.recordingId || "")}
            // isSelected={isSelected(recording.recordingId || "")}
            // isAdmin={isAdmin}
            key={siteNote.noteId || ""}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default SiteNotesList;
