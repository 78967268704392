import { useState } from "react";
import * as R from "ramda";
import { DateTime } from "luxon";
import { nullaryBool } from "shared-utils";
import useDefinePasswordState from "../../hooks/useDefinePasswordState";
import CreatePatientUiView from "./CreatePatientUiView";
import CreatePatientSuccessDialog from "./CreatePatientSuccessDialog";

type PatientCreationCallback = (
  patientId: string,
  password: string,
  birthday: Date | null,
  firstName: string,
  lastName: string,
  allergies: string[]
) => Promise<void>;

interface CreatePatientStateViewProps {
  createPatient: PatientCreationCallback;
  onCancel: () => void;
  initialId: string;
}

function CreatePatientStateView({
  createPatient,
  onCancel,
  initialId,
}: CreatePatientStateViewProps) {
  const {
    acceptablePassword: password,
    definePasswordState,
    clearPassword,
  } = useDefinePasswordState();

  const [patientId, setPatientId] = useState(initialId);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState<DateTime | null>(null);
  const [allergies, setAllergies] = useState<string[]>([]);
  const [showingDialog, setShowingDialog] = useState(false);
  const [showDialog, hideDialog] = nullaryBool(setShowingDialog);

  const updateAllergies = R.compose(
    setAllergies,
    R.sort((a, b) => a.localeCompare(b))
  );

  const clearAll = () => {
    setPatientId("");
    setFirstName("");
    setBirthday(null);
    setLastName("");
    setAllergies([]);
    clearPassword();
  };

  const onSave = () => {
    // console.log(birthday);
    const bithdayDate = birthday && birthday.toJSDate();
    createPatient(
      patientId,
      password,
      bithdayDate,
      firstName,
      lastName,
      allergies
    )
      .then(() => {
        showDialog();
        clearAll();
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <>
      <CreatePatientUiView
        patientId={patientId}
        setPatientId={setPatientId}
        birthday={birthday}
        setBirthday={setBirthday}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        allergies={allergies}
        setAllergies={updateAllergies}
        password={password}
        definePasswordState={definePasswordState}
        onSave={onSave}
        onCancel={onCancel}
      />
      <CreatePatientSuccessDialog open={showingDialog} onClose={hideDialog} />
    </>
  );
}

export default CreatePatientStateView;
