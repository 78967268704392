import React from "react";
// this provides localization for the date picker via luxon.
import { LocalizationProvider as DatePickerLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import { LocalizationProvider, getDirection } from "shared-ui";
import * as messages_en from "../lang/en.json";
import * as messages_ur from "../lang/ur.json";

const messagesByLocale = {
  en: messages_en,
  ur: messages_ur,
};

let initialLocale = "en";
if (navigator.language.startsWith("ur")) {
  initialLocale = "ur";
}

document.dir = getDirection(initialLocale);

function LocalizedStringsProvider({ children }: { children: React.ReactNode }) {
  return (
    <LocalizationProvider
      strings={messagesByLocale}
      initialLocale={initialLocale}
    >
      <DatePickerLocalizationProvider dateAdapter={AdapterLuxon}>
        {children}
      </DatePickerLocalizationProvider>
    </LocalizationProvider>
  );
}

export default LocalizedStringsProvider;
