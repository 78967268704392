import React, { type PropsWithChildren } from "react";
import { useState, useEffect, createContext } from "react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import {
  //useIntl,
  IntlProvider,
  //   FormattedMessage,
  //   FormattedDate,
  //   FormattedTime,
  //   FormattedRelativeTime,
  //   FormattedNumber,
  //   FormattedList,
} from "react-intl";
import { type Direction } from "@mui/material/styles";

export type MessagesJsonType = {
  [id: string]: string | MessagesJsonType;
};

export type MessagesByLocaleType = {
  [id: string]: MessagesJsonType;
};

type LocaleMessagesType = {
  [id: string]: string;
};

export const LocaleContext = createContext({
  locale: "en",
  setLocale: (locale: string) => {},
  localeDirection: "ltr" as Direction,
});

// converts things like {a: { b: { c: "message"} } } to {"a.b.c": "message"}
const flattenMessages = (
  obj: MessagesJsonType,
  prefix = ""
): LocaleMessagesType => {
  const messages = Object.keys(obj).reduce((acc: MessagesJsonType, k) => {
    const pre = prefix.length ? prefix + "." : "";
    // assigning obj[k] helps TS understand the type within the condition,
    // since it doesn't have to worry about k changing.
    const item = obj[k];
    if (typeof item === "object") {
      Object.assign(acc, flattenMessages(item, pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
  return messages as LocaleMessagesType;
};
function selectMessages(
  strings: MessagesByLocaleType,
  locale: string
): LocaleMessagesType {
  console.log(`messages will be in ${locale}}`);
  let unflattenedMessages: MessagesJsonType;
  switch (locale) {
    case "ur":
      unflattenedMessages = strings.ur;
      break;
    // case "en":
    //   return import("./lang/en.json");
    // case "es-MX":
    //   return import("./lang/es-MX.json");
    default:
      unflattenedMessages = strings.en;
  }
  return flattenMessages(unflattenedMessages);
}

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export function getDirection(locale: string): Direction {
  switch (locale) {
    case "ur":
      return "rtl";
    // case "en":
    //   return "ltr";
    // case "es-MX":
    //   return "ltr";
    default:
      return "ltr";
  }
}

interface SehatCacheProviderProps {
  direction: Direction;
}
const SehatCacheProvider = ({
  children,
  direction,
}: PropsWithChildren<SehatCacheProviderProps>) => {
  if (direction === "rtl") {
    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
  } else {
    return <>{children}</>;
  }
};

interface LocalizationProviderProps {
  strings: MessagesByLocaleType;
  initialLocale: string;
}

function LocalizationProvider({
  strings,
  initialLocale,
  children,
}: PropsWithChildren<LocalizationProviderProps>) {
  const [locale, setLocale] = useState(initialLocale);
  const [messages, setMessages] = useState<Record<string, string> | null>(null);
  const localeDirection = getDirection(locale);

  useEffect(
    () => setMessages(selectMessages(strings, locale)),
    [strings, locale]
  );

  return messages ? (
    <LocaleContext.Provider value={{ locale, setLocale, localeDirection }}>
      <SehatCacheProvider direction={localeDirection}>
        <IntlProvider locale={locale} messages={messages}>
          {children}
        </IntlProvider>
      </SehatCacheProvider>
    </LocaleContext.Provider>
  ) : null;
}

export { LocalizationProvider };
