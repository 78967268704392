import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { InputBaseProps } from "@mui/material/InputBase";
import { textareaChangeHandler } from "../../utils/eventUtils";

export interface TranscriptionDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  inputProps?: InputBaseProps["inputProps"];
}

function TranscriptionDialog({
  open,
  onClose,
  title,
  value,
  setValue,
  inputProps,
  placeholder,
}: TranscriptionDialogProps) {
  console.log(`value in dialog is ${value}`);
  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: "90%" }}>
          <TextField
            multiline
            inputProps={inputProps}
            value={value}
            onChange={textareaChangeHandler(setValue)}
            placeholder={placeholder}
            minRows={28}
          />
        </FormControl>
      </DialogContent>
    </Dialog>
  );
}

export default TranscriptionDialog;
