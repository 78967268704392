import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const searchParamKeys = ["start", "patientId"] as const;
export type SearchParamKeys = (typeof searchParamKeys)[number];
export type SearchParams = Partial<Record<SearchParamKeys, string>>;

// this slice is used to preserve the initial rquested state
// when we have to divert through login
export interface InitialRequeststate {
  searchParams: SearchParams;
}

const initialState: InitialRequeststate = {
  searchParams: {},
};

const initialRequestUserSlice = createSlice({
  name: "initialRequest",
  initialState,
  reducers: {
    saveInitialParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    clearInitialRequest: (state) => {
      state.searchParams = {};
    },
  },
});

export const { saveInitialParams, clearInitialRequest } =
  initialRequestUserSlice.actions;
export default initialRequestUserSlice.reducer;
