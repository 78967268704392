import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [] as any[], 
};

const deletedMedicinesSlice = createSlice({
  name: "deletedMedicines",
  initialState,
  reducers: {
    addDeletedMedicine(state, action: PayloadAction<any>) {
      // Add the payload to the `data` array
      state.data.push(action.payload);
      console.log("[IN SLICE] Deleted Medicines: ", state.data); // Log the updated `data` array
    },
  },
});

export default deletedMedicinesSlice.reducer;
export const { addDeletedMedicine } = deletedMedicinesSlice.actions;
