interface Nameable {
  email?: string | null;
  givenName?: string | null;
  familyName?: string | null;
}

export function displayNameFor(user: Nameable) {
  if (user.givenName && user.familyName) {
    // could be locale dependent! but this is fine for now.
    return `${user.givenName} ${user.familyName}`;
  }
  if (user.givenName) {
    return user.givenName;
  }
  if (user.familyName) {
    return user.familyName;
  }
  return user.email || "???";
}
