import { PayloadAction, createSlice } from "@reduxjs/toolkit";
export interface AuthState {
  token?: string;
  loggingOut: boolean;
}

const initialState: AuthState = {
  loggingOut: false,
};

const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
    logoutAction: (state, _action: PayloadAction<void>) => {
      // logout is also processed in currentClinicSlice.
      state.token = undefined;
      state.loggingOut = true;
    },
  },
});

export default authSlice.reducer;
export const { setAuthToken, logoutAction } = authSlice.actions;
