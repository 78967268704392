import { MouseEventHandler, useMemo, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import HumanFrontImage from "../icons/human-front.png";
import HumanBackImage from "../icons/human-back.png";
type Point = readonly [number, number];

function PainPointIcon({ scale }: { scale: number }) {
  const size = scale < 0.4 ? "32px" : "72px";
  return (
    <Typography fontSize={size} fontWeight="bold" color="red">
      *
    </Typography>
  );
}

// these are y, x coordinates
const painLocations = [
  [15, 100],
  [100, 50],
  [100, 150],
  [115, 100],
  [200, 100],
  [280, 15],
  [280, 195],
  [380, 80],
  [380, 130],
  [520, 85],
  [520, 125],
] as const;

interface PainPointProps {
  painLocation: Point;
  shift: number;
  scale: number;
}

function PainPoint({ painLocation, shift, scale }: PainPointProps) {
  const left = `${painLocation[1] + shift}px`;
  const top = `${painLocation[0]}px`;
  return (
    <Box position="absolute" top={top} left={left}>
      {/* {`(${painLocation[0]}, ${painLocation[1]})`} */}
      <PainPointIcon scale={scale} />
    </Box>
  );
}

interface PainPointSetViewProps {
  image: string;
  shiftX: number;
  shiftY: number;
  selectionList: number[];
  scale?: number;
  toggleSelection: (ix: number) => void;
}

function PainPointSetView({
  image,
  shiftX,
  shiftY,
  selectionList,
  toggleSelection,
  scale = 0.67,
}: PainPointSetViewProps) {
  const boxRef = useRef();

  const scaledPoints = useMemo(
    () =>
      painLocations.map(
        (p) => [(p[0] * scale * 722) / 600, (p[1] * scale * 300) / 240] as const
      ),
    [scale]
  );

  const selectedPoints = selectionList.map((ix) => scaledPoints[ix]);

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const boxElem = boxRef.current as unknown as HTMLDivElement;
    const x = e.clientX - boxElem.getBoundingClientRect().left - shiftX;
    const y = e.clientY - boxElem.getBoundingClientRect().top;
    console.log(`clicked at ${y}, ${x} -- ${e.clientX}, ${e.clientY}}`);
    const closest = closestPoint([y, x], scaledPoints);
    console.log(
      `closest point is ${closest} at ${JSON.stringify(scaledPoints[closest])}`
    );
    toggleSelection(closest);
  };

  const width = `${scale * 300}px`;
  const height = `${scale * 722}px`;
  const top = `${shiftY}px`;
  return (
    <Box
      position="relative"
      left="0"
      top={top}
      width={width}
      height={height}
      onClick={onClick}
      ref={boxRef}
      sx={{ cursor: "pointer" }}
    >
      <img src={image} alt="pain points" width={width} height={height} />
      {selectedPoints.map((painLocation, ix) => (
        <PainPoint
          painLocation={painLocation}
          shift={shiftX}
          key={ix}
          scale={scale}
        />
      ))}
    </Box>
  );
}

interface SidePainPointSetViewProps {
  selectionList: number[];
  scale?: number;
  toggleSelection: (ix: number) => void;
}

function FrontPainPointSetView({
  selectionList,
  toggleSelection,
  scale,
}: SidePainPointSetViewProps) {
  return (
    <PainPointSetView
      image={HumanFrontImage}
      shiftX={0}
      shiftY={0}
      scale={scale}
      selectionList={selectionList}
      toggleSelection={toggleSelection}
    />
  );
}
function BackPainPointSetView({
  selectionList,
  toggleSelection,
  scale,
}: SidePainPointSetViewProps) {
  return (
    <PainPointSetView
      image={HumanBackImage}
      shiftX={0}
      shiftY={5}
      scale={scale}
      selectionList={selectionList}
      toggleSelection={toggleSelection}
    />
  );
}

interface PainPointPairViewProps {
  scale?: number;
  frontPainPoints: number[];
  frontToggleSelection: (ix: number) => void;
  backPainPoints: number[];
  backToggleSelection: (ix: number) => void;
}

function PainPointPairView({
  scale = 0.67,
  frontPainPoints,
  frontToggleSelection,
  backPainPoints,
  backToggleSelection,
}: PainPointPairViewProps) {
  return (
    <Stack direction="row" spacing={2} justifyContent="space-around">
      <FrontPainPointSetView
        scale={scale}
        selectionList={frontPainPoints || []}
        toggleSelection={frontToggleSelection}
      />
      <BackPainPointSetView
        scale={scale}
        selectionList={backPainPoints || []}
        toggleSelection={backToggleSelection}
      />
    </Stack>
  );
}

function distance(p1: Point, p2: Point) {
  return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
}
function closestPoint(point: Point, points: readonly Point[]) {
  const distances = points
    .map((p, ix) => [distance(point, p), ix])
    .sort((a, b) => a[0] - b[0]);
  return distances[0][1];
}
export default PainPointPairView;
