import { useEffect, useLayoutEffect, useState } from "react";

// a hook for layout effects that depend on the window height.

type WindowLayoutEffect = (windowHeight: number) => void;
function useWindowLayoutEffect(effect: WindowLayoutEffect) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleWindowSizeChange = () => {
    setWindowHeight(window.innerHeight);
  };

  // listen for window size changes.
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useLayoutEffect(() => {
    effect(windowHeight);
  }, [effect, windowHeight]);
}

export default useWindowLayoutEffect;
