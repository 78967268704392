import JSZip from "jszip";
import { type Recording } from "../graphql/generated";
import { type ServiceBag } from "../services/ServiceBag";
import { type FileService } from "../services/fileService";
import { toFormattedCnic } from "./cnicUtils";

export async function createNewRecording(
  recordingDataURI: string,
  username: string,
  patientId: string,
  patientIdType: string,
  serviceBag: ServiceBag
) {
  const { apiService, envService, fileService }: ServiceBag = serviceBag;
  const recordingId = envService.randomUUID();
  const currentTime = envService.currentTime();

  const s3Url = await apiService.createRecording({
    recording: {
      user: username,
      recordedAt: currentTime.toJSDate(),
      recordingId,
      patientId,
      patientIdType,
    },
  });
  if (s3Url != null) {
    await fileService.uploadBase64ToS3(s3Url, recordingDataURI);
    return true;
  } else {
    return false;
  }
}

export function createRecordingCsv(recordings: Recording[]) {
  let csv = "recordingId,recordedAt,patientCnic,user\n";
  for (const recording of recordings) {
    const recordedAt = (recording?.recordedAt as string) || "";
    const cnic = toFormattedCnic(recording.patientId || "");
    csv += `${recording.recordingId || ""},${recordedAt},${cnic},${
      recording.user || ""
    }\n`;
  }
  return csv;
}

export async function zipDownloadsFrom(
  ids: string[],
  urls: string[],
  fileService: FileService
) {
  const promises = urls.map((url) => fileService.fetch(url));
  const results = await Promise.all(promises);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const blobs = results.map((r) => r.blob());

  const zip = new JSZip();
  blobs.forEach((blob, i) => {
    zip.file(`${ids[i]}.webm`, blob);
  });

  return await zip.generateAsync({ type: "blob" });
}
