import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import ClinicHeader from "../components/ClinicHeader";

const MainAppRoute = () => {
  return (
    <>
      <Stack height="100%">
        <ClinicHeader />
        <Outlet />
      </Stack>
    </>
  );
};

export default MainAppRoute;
