import React from "react";
import * as R from "ramda";
import Tooltip from "@mui/material/Tooltip";

interface AutoTooltipProps {
  message: string | undefined | null;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"; // values copied from Tooltip.d.ts, too bad it's not exposed as a type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
}

function AutoTooltip({
  message,
  placement = "right",
  children,
}: AutoTooltipProps) {
  return (
    <Tooltip
      open={R.isNotNil(message)}
      title={message}
      placement={placement}
      children={children}
      arrow
    />
  );
}

export { AutoTooltip };
