import { useMemo } from "react";
import { removeNils } from "shared-utils";

function useMemoizedRemoveNils<T>(
  array: (T | null | undefined)[] | null | undefined
): T[] {
  return useMemo(() => removeNils(array), [array]);
}

export default useMemoizedRemoveNils;
