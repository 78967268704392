import * as R from "ramda";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import { type Breakpoint } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";

interface AmchurDialogActionsProps {
  onCancelClicked: () => void;
  onOkClicked: () => void;
  showCancel: boolean;
  disabled: boolean;
}

export function AmchurDialogActions({
  onCancelClicked,
  onOkClicked,
  showCancel,
  disabled,
}: AmchurDialogActionsProps) {
  return (
    <DialogActions>
      {/* if there is no ok action, then we only show the ok Button 
      and it closes the dialog. */}
      {showCancel && (
        <IconButton
          size="large"
          color="error"
          onClick={onCancelClicked}
          //disabled={disabled}
        >
          <CancelIcon fontSize="large" />
        </IconButton>
      )}
      <IconButton
        size="large"
        color="success"
        onClick={onOkClicked}
        disabled={disabled}
        data-testid="submit-button"
      >
        <CheckCircleIcon fontSize="large" />
      </IconButton>
    </DialogActions>
  );
}

interface AmchurDialogProps {
  isOpen: boolean;
  onCloseRequested: () => void;
  onOk?: () => Promise<boolean>;
  title: string;
  titleVariant?: Variant;
  titleColor?:
    | "error"
    | "default"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  contentText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactElement<any, any>;
  maxWidth?: false | Breakpoint | undefined;
  fullWidth?: boolean;
  disabled?: boolean;
  // can add more mui Dialog properties here.
}

function AmchurDialog({
  isOpen,
  onCloseRequested,
  onOk,
  title,
  titleColor = "default",
  titleVariant,
  contentText = undefined,
  disabled = false,
  children,
  ...dialogProps
}: AmchurDialogProps) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const onOkClicked = onOk
    ? () => {
        setWorkInProgress(true);
        onOk()
          .then((ok) => {
            setWorkInProgress(false);
            if (ok) {
              onCloseRequested();
            }
          })
          .catch((e) => {
            console.error(e);
            setWorkInProgress(false);
          });
      }
    : onCloseRequested;
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseRequested}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...dialogProps}
    >
      <DialogTitle
        color={titleColor}
        id="alert-dialog-title"
        variant={titleVariant}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {contentText && (
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <AmchurDialogActions
        onCancelClicked={onCloseRequested}
        onOkClicked={onOkClicked}
        showCancel={R.isNotNil(onOk)}
        disabled={workInProgress || disabled}
      />
    </Dialog>
  );
}

export default AmchurDialog;
