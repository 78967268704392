import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import createRecorder from "./recorder";

export default function createEnvService() {
  return {
    // use this rather than calling new Date() directly so that tests can
    // mock a known value.
    currentTime: () => DateTime.now(),

    // to produce date in formatted manner, it is specifaically being
    // used to detect the delay we incur (rarely) in transcription while working with the
    // ML model
    formattedCurrentTime: () =>
      DateTime.now().toLocaleString(DateTime.TIME_WITH_SECONDS),

    randomUUID: () => uuid(),

    recorder: createRecorder,
  };
}
export const envService = createEnvService();
export type EnvService = ReturnType<typeof createEnvService>;
