import * as R from "ramda";
import { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { removeNils } from "shared-utils";
import { type AppDispatch } from "../../state/store";
import { useServiceBag } from "../../services/ServiceBag";
import {
  getRecordingDownloadUrlMiddleware,
  getRecordingDownloadUrlsMiddleware,
} from "../../middleware/recordingMiddleware";
import { type Recording } from "../../graphql/generated";
import { createRecordingCsv } from "../../domain/recordingDomain";
import useCurrentRole from "../../hooks/useCurrentRole";
import useMultiSelect from "../../hooks/useMultiSelect";
import { useRecordingsByCriteriaQuery } from "../../hooks/apiHooks/queryHooks";
import { RecordingModel } from "../../models/modelTypes";
import { RootState } from "../../state/reducers";
import { setRecordingSelectedDate } from "../../state/recordingListSlice";
import OneRecordingView from "./OneRecordingView";

interface DownloadCsvViewProps {
  recordings: Recording[];
}

function DownloadCsvView({ recordings }: DownloadCsvViewProps) {
  const { fileService } = useServiceBag();

  const generateCsv = () => {
    const csv = createRecordingCsv(recordings);
    const blob = new Blob([csv], { type: "text/csv" });
    fileService.downloadData(blob, "recordings.csv");
  };

  return <Button onClick={generateCsv}>Download Metadata</Button>;
}

interface MultiDownloadViewProps {
  recordings: Recording[];
  selectedIds: string[];
}

function MultiDownloadView({
  recordings,
  selectedIds,
}: MultiDownloadViewProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { fileService } = useServiceBag();
  const [working, setWorking] = useState(false);

  const generateZip = () => {
    setWorking(true);
    void dispatch(getRecordingDownloadUrlsMiddleware(selectedIds)).then(
      (result) => {
        if (getRecordingDownloadUrlsMiddleware.fulfilled.match(result)) {
          if (result.payload) {
            fileService.downloadData(result.payload, "recordings.zip");
            setWorking(false);
          }
        }
      }
    );
  };
  return (
    <Button
      disabled={selectedIds.length === 0 || working}
      onClick={generateZip}
    >
      {working ? " Working..." : "Download Recordings"}
    </Button>
  );
}

interface RecordingTableProps {
  recordings: RecordingModel[];
  isAdmin: boolean;
  onClick: (id: string) => void;
  isSelected: (id: string) => boolean;
  onSelectionChanged(id: string, selected: boolean): void;
}

function RecordingTable({
  recordings,
  isAdmin,
  onClick,
  onSelectionChanged,
  isSelected,
}: RecordingTableProps) {
  const onCheckboxClick = R.curry(onSelectionChanged);
  return (
    <Table>
      <TableBody>
        {recordings.map((recording) => (
          <OneRecordingView
            recording={recording}
            onClick={onClick}
            onChangeSelectState={onCheckboxClick(recording.recordingId || "")}
            isSelected={isSelected(recording.recordingId || "")}
            isAdmin={isAdmin}
            key={recording.recordingId || ""}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default function RecordingsRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const { selectionList, changeSelection, isSelected, setSelectionList } =
    useMultiSelect();
  const role = useCurrentRole();
  const isRecordingAdmin = role === "su";

  const { fileService } = useServiceBag();
  // when we change 'since' to be selected by the user, we'll also
  // need to use it in recordMiddleware.ts
  const { data: recordings, isFetching } = useRecordingsByCriteriaQuery({
    criteria: { since: "2021-10-01" },
  });

  const onClickRecording = (id: string) => {
    void dispatch(getRecordingDownloadUrlMiddleware(id)).then((result) => {
      if (getRecordingDownloadUrlMiddleware.fulfilled.match(result)) {
        console.log("url", result.payload);
        if (result.payload) {
          fileService.downloadFromS3(result.payload, `recording-${id}}.webm`);
        }
      }
    });
  };

  const selectedDate = useSelector(
    (state: RootState) => state.recordingList.selectedDate
  );
  const changeSelectedDate = (date: DateTime | null) => {
    dispatch(
      setRecordingSelectedDate({
        date: date || undefined,
      })
    );
  };

  return (
    <RecordingListUiView
      inProgress={isFetching}
      onClick={onClickRecording}
      onSelectionChanged={changeSelection}
      isSelected={isSelected}
      selectionList={selectionList}
      setSelectionList={setSelectionList}
      recordings={recordings}
      isAdmin={isRecordingAdmin}
      selectedDate={selectedDate}
      changeSelectedDate={changeSelectedDate}
    />
  );
}

interface RecordingListUiViewProps {
  inProgress: boolean;
  recordings: RecordingModel[] | null;
  onClick: (id: string) => void;
  selectionList: string[];
  isSelected: (id: string) => boolean;
  onSelectionChanged(id: string, selected: boolean): void;
  setSelectionList: (ids: string[]) => void;
  isAdmin: boolean;
  selectedDate: DateTime | undefined;
  changeSelectedDate: (date: DateTime | null) => void;
}

function RecordingListUiView({
  inProgress,
  recordings,
  onClick,
  isSelected,
  selectionList,
  onSelectionChanged,
  setSelectionList,
  isAdmin,
  selectedDate,
  changeSelectedDate,
}: RecordingListUiViewProps) {
  const displayedRecordings = useMemo(
    () => recordingsToDisplay(recordings, selectedDate),
    [recordings, selectedDate]
  );

  const onSelectNone = useCallback(() => {
    setSelectionList([]);
  }, [setSelectionList]);
  const onSelectAll = useCallback(() => {
    const allIds = removeNils(displayedRecordings.map((r) => r.recordingId));
    setSelectionList(allIds);
  }, [setSelectionList, displayedRecordings]);

  if (inProgress) {
    return <Typography>loading...</Typography>;
  }

  // clear selection when changing date, so we dont end up with
  // some selected items that aren't actually shown in the list.
  const onChangeSelectedDate = (date: DateTime | null) => {
    changeSelectedDate(date);
    onSelectNone();
  };

  return (
    <Stack marginTop="2em">
      <Stack direction="row" mx="auto" alignItems="center">
        <MultiDownloadView
          recordings={displayedRecordings}
          selectedIds={selectionList}
        />
        {isAdmin && (
          <AdminControls
            recordings={displayedRecordings}
            selectedDate={selectedDate}
            changeSelectedDate={onChangeSelectedDate}
          />
        )}
      </Stack>
      <Stack direction="row" mr="auto" ml="1em" alignItems="left">
        <Button onClick={onSelectNone}>Select None</Button>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ marginRight: "0.5em" }}
        />
        <Button onClick={onSelectAll}>Select All</Button>
      </Stack>

      <RecordingTable
        recordings={displayedRecordings}
        isAdmin={isAdmin}
        onClick={onClick}
        isSelected={isSelected}
        onSelectionChanged={onSelectionChanged}
      />
    </Stack>
  );
}

interface AdminControlsProps {
  recordings: Recording[] | null;
  selectedDate: DateTime | undefined;
  changeSelectedDate: (date: DateTime | null) => void;
}

function AdminControls({
  recordings,
  selectedDate,
  changeSelectedDate,
}: AdminControlsProps) {
  const dateValue: DateTime | null = selectedDate || null;

  return (
    <>
      <Divider flexItem orientation="vertical" />
      {recordings && <DownloadCsvView recordings={recordings} />}
      <Divider flexItem orientation="vertical" sx={{ marginRight: "0.5em" }} />
      <DatePicker
        label="Recordings for date"
        value={dateValue}
        onChange={changeSelectedDate}
        views={["month", "day", "year"]}
      />
      <Divider flexItem orientation="vertical" sx={{ marginLeft: "0.5em" }} />
      <Button
        onClick={() => changeSelectedDate(null)}
        disabled={dateValue == null}
      >
        All Dates
      </Button>
    </>
  );
}

function recordingsToDisplay(
  recordings: RecordingModel[] | null,
  date: DateTime | undefined
): RecordingModel[] {
  if (recordings == null) {
    return [];
  }
  if (date == null) {
    return recordings;
  }
  return recordings.filter((recording) =>
    recording.recordedAt.startOf("day").equals(date.startOf("day"))
  );
}
