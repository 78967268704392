import { BaseQueryApi, createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { PrepareHeaders } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { GraphQLClient } from "graphql-request";
import { RootState } from "../state/reducers";

export const apiHostURL = process.env.REACT_APP_GRAPHQL_URL || "";
export const client = new GraphQLClient(apiHostURL, { credentials: "include" });

const prepareHeaders: PrepareHeaders = (
  headers: Headers,
  { getState }: Pick<BaseQueryApi, "getState">
) => {
  const token = (getState as () => RootState)().auth.token as string;
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }
  return Promise.resolve(headers);
};

export const api = createApi({
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment */
  baseQuery: graphqlRequestBaseQuery({ client, prepareHeaders }),
  endpoints: () => ({}),
});

// const baseQuery = fetchBaseQuery({
//   baseUrl: '/',
//   prepareHeaders: (headers, { getState }) => {
//     const token = (getState() as RootState).auth.token

//     // If we have a token set in state, let's assume that we should be passing it.
//     if (token) {
//       headers.set('authorization', `Bearer ${token}`)
//     }

//     return headers
//   },
// })
