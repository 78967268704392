import { createContext } from "react";
import { removeNils } from "shared-utils";
import { graphql } from "../gql/gql";
import { type PatientUploadPhotoQueryVariables } from "../gql/graphql";
import {
  type PatientUploadPhotoQuery,
  type CreateRecordingMutation,
  type MutationCreateRecordingArgs,
  type RecordingQueryVariables,
  type RecordingQuery,
  type RecordingKeys,
  RecordingsByIdQueryVariables,
  CreateSiteNoteMutationVariables,
  SiteNotesByIdQueryVariables,
} from "../graphql/generated";
import { Maybe } from "../graphql/generated";
import { patientUploadPhotoQuery } from "./patientQuery";
import { createRecordingMutation } from "./createRecordingMutation";
import { client } from "./baseApi";
import { recordingDownloadUrlQuery } from "./recordingQuery";
import { deleteRecordingMutation } from "./deleteRecordingMutation";
import api, { type TagType } from "./enhancedApi";

// this is untyped to avoid circular dependencies.
let apiDispatch: (u: unknown) => unknown;
export function setApiDispatch(dispatch: (u: unknown) => unknown) {
  apiDispatch = dispatch;
}

interface DataUnionBranch {
  data: unknown;
}

// This extracts the data if available. ResultT is going to be a union type
// with either data or error, but no way of determining which via TS.
// we should probably have a similar way of extrating the error.
function extractData<ResultT, DataResultT extends DataUnionBranch>(
  result: ResultT | null | undefined
): DataResultT["data"] {
  if (result && typeof result == "object" && result.hasOwnProperty("data")) {
    const dataResult = result as unknown as DataResultT;
    return dataResult.data as DataResultT["data"];
  }

  return null;
}

interface EndpointType {
  initiate: (args: unknown) => unknown;
}

async function dispatchGql<
  Endpoint extends EndpointType,
  Args,
  QResult,
  DataResult
>(
  endpoint: Endpoint,
  args: Args,
  extract: (
    result: Extract<QResult, DataUnionBranch>["data"]
  ) => DataResult | undefined
) {
  const queryResult = (await apiDispatch(endpoint.initiate(args))) as QResult;

  const data = extractData<QResult, Extract<QResult, DataUnionBranch>>(
    queryResult
  );

  // should do error checking here.
  return (data ? extract(data) : null) || null;
}

export type FetchType = typeof fetch;

// NOTE: many of the functions below use client.request to perform the graphql
// request. This was a mistake; the api.endpoints method is correct as it
// goes through some rtk-query cache handling that is very useful.
// The ones that use client.request need to be migrated.
export default function createApiService() {
  const getPatientUploadUrl = async (
    args: PatientUploadPhotoQueryVariables
  ): Promise<string> => {
    const query = graphql(patientUploadPhotoQuery);
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    const result: PatientUploadPhotoQuery = await client.request(query, args);
    if (result?.patient?.photoUploadUrl) {
      return result.patient.photoUploadUrl;
    }
    throw new Error("No photo upload URL");
  };

  const createRecording = async (
    args: MutationCreateRecordingArgs
  ): Promise<Maybe<string>> => {
    const query = graphql(createRecordingMutation);
    const recording: CreateRecordingMutation = await client.request(
      query,
      args,
      {}
    );
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    return recording?.createRecording?.uploadUrl || null;
  };

  const getRecordingDownloadUrl = async (args: RecordingQueryVariables) => {
    const query = graphql(recordingDownloadUrlQuery);
    const result: RecordingQuery = await client.request(query, args, {});
    // I don't understand why I need these but TS complains.
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    return result.recording.downloadUrl;
  };

  type GetRecordingsByIdResult = Awaited<
    ReturnType<ReturnType<typeof api.endpoints.recordingsById.initiate>>
  >;

  const getRecordingDownloadUrls = async (
    args: RecordingsByIdQueryVariables
  ): Promise<Maybe<string[]>> => {
    return await dispatchGql<
      EndpointType, // would be nice to make this more accurate
      RecordingsByIdQueryVariables,
      GetRecordingsByIdResult,
      Maybe<string[]>
    >(api.endpoints.recordingsById as EndpointType, args, (data) =>
      removeNils(data.recordingsById.map((r) => r?.downloadUrl))
    );
  };

  type GetSiteNotesByIdResult = Awaited<
    ReturnType<ReturnType<typeof api.endpoints.siteNotesById.initiate>>
  >;

  const getSiteNoteDownloadUrls = async (
    args: SiteNotesByIdQueryVariables
  ): Promise<Maybe<string[]>> => {
    return await dispatchGql<
      EndpointType, // would be nice to make this more accurate
      SiteNotesByIdQueryVariables,
      GetSiteNotesByIdResult,
      Maybe<string[]>
    >(api.endpoints.siteNotesById as EndpointType, args, (data) =>
      removeNils(data.siteNotesById.map((r) => r?.downloadUrl))
    );
  };

  // const createRecording = async (
  //   args: MutationCreateRecordingArgs
  // ): Promise<Maybe<string>> => {
  //   const query = graphql(createRecordingMutation);
  //   const recording: CreateRecordingMutation = await client.request(
  //     query,
  //     args,
  //     {}
  //   );

  type CreateSiteNoteResultResult = Awaited<
    ReturnType<ReturnType<typeof api.endpoints.createSiteNote.initiate>>
  >;

  const createSiteNote = async (
    args: CreateSiteNoteMutationVariables
  ): Promise<Maybe<string>> => {
    return await dispatchGql<
      EndpointType, // would be nice to make this more accurate
      CreateSiteNoteMutationVariables,
      CreateSiteNoteResultResult,
      Maybe<string>
    >(
      api.endpoints.createSiteNote as EndpointType,
      args,
      (data) => data.createSiteNote?.uploadUrl
    );
  };
  const deleteRecording = async (recordingKeys: RecordingKeys) => {
    const query = graphql(deleteRecordingMutation);
    await client.request(query, { recordingKeys }, {});

    return;
  };

  return {
    getPatientUploadUrl,
    createRecording,
    getRecordingDownloadUrl,
    getRecordingDownloadUrls,
    deleteRecording,
    createSiteNote,
    getSiteNoteDownloadUrls,
    // everything above this point should be replaced by hooks. Do not use as a model
    // for future development.

    // use this to update the cache directly, e.g. when you've sent a mutation
    // but don't want to reload all the data.
    // It's a simple wrapper to allow tests to mock.
    updateCachedQueryData: api.util.updateQueryData,
    // use this to clear a cache, e.g. as a result of a web hook.
    invalidateCachedQueryDataAction: (tags: TagType[]) =>
      api.util.invalidateTags(tags),
  };
}

export type ApiService = ReturnType<typeof createApiService>;
export const apiService = createApiService();
export const ApiServiceContext = createContext(apiService);

export function ApiServiceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ApiServiceContext.Provider value={apiService}>
      {children}
    </ApiServiceContext.Provider>
  );
}
