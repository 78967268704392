import { useState } from "react";
import { Outlet } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { PrimaryAppBar } from "shared-ui";
import MainMenu from "../components/MainMenu";

const InternalAppRoute = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => setShowDrawer((state) => !state);

  return (
    <>
      <PrimaryAppBar toggleMenu={toggleDrawer} />
      <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer}>
        <MainMenu />
      </Drawer>
      <Stack>
        <Box sx={{ mt: 7 }} />
        <Outlet />
      </Stack>
    </>
  );
};

export default InternalAppRoute;
