import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SehatIcon from "../icons/sehat-logo.svg";
import AmchurCard from "./AmchurCard";
import CenteredForm from "./CenteredForm";
import DefaultTypeOverride from "./DefaultTypeOverride";

export interface LoginPageLayoutViewProps {
  title: string;
  children: React.ReactNode;
}
function LoginPageLayoutView({ title, children }: LoginPageLayoutViewProps) {
  const { slots, remainingChildren } = extractSlots(children);
  return (
    <CenteredForm>
      <img src={SehatIcon} alt="company icon" width="240px" />

      {/* for WebUserClinics Route */}
      <Box sx={{ alignItems: "flex-start" }} width="100%">
        {slots.header}
      </Box>

      <AmchurCard title={title} role="primary">
        <Stack alignItems="flex-start" spacing={2} width="100%">
          {slots.primary}
        </Stack>
      </AmchurCard>

      <Box mr="auto" width="100%" alignContent="flex-start" display="flex">
        <DefaultTypeOverride variant="subtitle2">
          {slots.secondary}
        </DefaultTypeOverride>
      </Box>
      <Box height="20px" />
      {slots.actions}
      {remainingChildren}
    </CenteredForm>
  );
}
function Header({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}
function Primary({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}
function Secondary({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}
function Actions({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

LoginPageLayoutView.Header = Header;
LoginPageLayoutView.Primary = Primary;
LoginPageLayoutView.Secondary = Secondary;
LoginPageLayoutView.Actions = Actions;

interface LoginPageLayoutSlots {
  header?: React.ReactNode;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  actions?: React.ReactNode;
}

function extractSlots(children: React.ReactNode) {
  const slots: LoginPageLayoutSlots = {};
  const remainingChildren: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }
    if (child.type === Header) {
      slots.header = child;
    } else if (child.type === Secondary) {
      slots.secondary = child;
    } else if (child.type === Primary) {
      slots.primary = child;
    } else if (child.type === Actions) {
      slots.actions = child;
    } else {
      remainingChildren.push(child);
    }
  });
  return { slots, remainingChildren };
}

export default LoginPageLayoutView;
