import * as R from "ramda";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { WebUserRoles } from "shared-utils";
import { HealthOrg, useAllHealthOrgsQuery } from "../../graphql/generated";
import { selectHandler } from "../../utils/eventUtils";
import CreateUserForm from "../../components/CreateUserForm";
import SehatSpinner from "../../components/SehatSpinner";
import useMemoizedRemoveNils from "../../hooks/useMemoizedRemoveNils";
import { WebUserCreation } from "../../gql/graphql";

const roleNeedsOrg = (role: string) => role !== "trn";
const getOrgId = R.prop("orgId") as (org: HealthOrg) => string;
const makeOrgMap = R.indexBy(getOrgId);

interface SelectHealthOrgPanelProps {
  allOrgs: HealthOrg[];
  role: WebUserRoles;
  setValid: (v: boolean) => void;
  orgId: string;
  setOrgId: (v: string) => void;
}

function SelectHealthOrgPanel({
  allOrgs,
  role,
  setValid,
  orgId,
  setOrgId,
}: SelectHealthOrgPanelProps) {
  const intl = useIntl();

  const orgMap = useMemo(() => makeOrgMap(allOrgs), [allOrgs]);
  console.log(`all Orgs are ${JSON.stringify(allOrgs)}`);

  const label = intl.formatMessage({ id: "createUser.chooseHo" });
  const renderOrg = (orgId: string) => (
    <Typography textAlign="left">{orgMap[orgId].name}</Typography>
  );

  const onChange = R.compose(setOrgId, selectHandler);
  const orgNeeded = roleNeedsOrg(role);

  useEffect(() => {
    setValid(!orgNeeded || orgId !== "");
    if (!orgNeeded) {
      setOrgId("");
    }
  }, [orgNeeded, orgId, setValid, setOrgId]);

  return (
    <FormControl>
      <InputLabel id="horg-label">{label}</InputLabel>
      <Select
        labelId="horg-label"
        label={label}
        value={orgId}
        renderValue={renderOrg}
        onChange={onChange}
        disabled={!orgNeeded}
        required={orgNeeded}
      >
        {allOrgs.map((org) => (
          <MenuItem key={org.orgId || "-"} value={org.orgId || "-"}>
            {renderOrg(org.orgId || "-")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default function CreateAdminUserRoute() {
  const { data, isLoading } = useAllHealthOrgsQuery();
  const [orgId, setOrgId] = useState("");

  const healthOrgs = useMemoizedRemoveNils(data?.allHealthOrgs || []);

  const roleField = useCallback(
    (role: WebUserRoles, setValid: (f: boolean) => void) => (
      <SelectHealthOrgPanel
        allOrgs={healthOrgs}
        role={role}
        setValid={setValid}
        orgId={orgId}
        setOrgId={setOrgId}
      />
    ),
    [healthOrgs, orgId]
  );

  // if the role requires a health org, then add it to the user.
  const addOrgField = useCallback(
    (user: WebUserCreation) =>
      roleNeedsOrg(user.role) ? { ...user, orgId } : user,
    [orgId]
  );

  if (isLoading) {
    return <SehatSpinner />;
  }

  return (
    <CreateUserForm
      roleOptions={["trn", "smgr", "phys"]}
      roleDefault="phys"
      extraField={roleField}
      updateUserFields={addOrgField}
    />
  );
}
