import * as R from "ramda";
import { useState } from "react";
import GridLayout, { WidthProvider } from "react-grid-layout";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useCollapsiblePanels from "../../hooks/useCollapsiblePanels";
import VitalsCard, {
  VitalsCardContents,
  VitalsCardActions,
} from "./VitalsCard";

const WideGridLayout = WidthProvider(GridLayout);

function VitalsRoute() {
  const { layout, setCollapsedState, isCollapsedState } = useCollapsiblePanels([
    { i: "a", x: 0, y: 0, w: 1, h: 3 },
    { i: "b", x: 1, y: 0, w: 3, h: 3 },
    { i: "c", x: 4, y: 0, w: 1, h: 3 },
  ]);

  const setCollapsed = R.curry(setCollapsedState);
  const [editMode, setEditMode] = useState(false);

  return (
    <WideGridLayout
      className="layout"
      layout={layout}
      cols={5}
      rowHeight={85}
      resizeHandles={["nw", "se", "ne", "sw", "s", "e", "n", "w"]}
      isDraggable={editMode}
      isResizable={editMode}
    >
      <VitalsCard
        key="a"
        title="Medication"
        setCollapsed={setCollapsed("a")}
        collapsed={isCollapsedState("a")}
        setEditMode={setEditMode}
        editMode={editMode}
      >
        <VitalsCardContents>
          <Stack alignItems="flex-start">
            <Typography>Aspirin</Typography>
            <Typography>Benadryl</Typography>
            <Typography>Simvastatin</Typography>
            <Typography>Erythropoietin</Typography>
          </Stack>
        </VitalsCardContents>
        <VitalsCardActions>
          <Button>Add Medication</Button>
        </VitalsCardActions>
      </VitalsCard>
      <VitalsCard
        key="b"
        title="Presenting Condition"
        setCollapsed={setCollapsed("b")}
        collapsed={isCollapsedState("b")}
        setEditMode={setEditMode}
        editMode={editMode}
      >
        <VitalsCardContents>
          모든 인류 구성원의 천부의 존엄성과 동등하고 양도할 수 없는 권리를
          인정하는 것이 세계의 자유 , 정의 및 평화의 기초이며 , 인권에 대한
          무시와 경멸이 인류의 양심을 격분시키는 만행을 초래하였으며 , 인간이
          언론과 신앙의 자유, 그리고 공포와 결핍으로부터의 자유를 누릴 수 있는
          세계의 도래가 모든 사람들의 지고한 열망으로서 천명되어 왔으며 ,
        </VitalsCardContents>
      </VitalsCard>
      <VitalsCard
        key="c"
        title="Dog Breeds"
        setCollapsed={setCollapsed("c")}
        collapsed={isCollapsedState("c")}
        setEditMode={setEditMode}
        editMode={editMode}
      >
        <VitalsCardContents>
          <Stack alignItems="flex-start">
            <Typography>Husky</Typography>
            <Typography>Goldren Retriever</Typography>
            <Typography>Goldren Lab</Typography>
            <Typography>Poodle</Typography>
          </Stack>
        </VitalsCardContents>
      </VitalsCard>
    </WideGridLayout>
  );
  return (
    <div>
      <h1>Vitals</h1>
    </div>
  );
}

export default VitalsRoute;
