import { FormattedMessage, useIntl } from "react-intl";
import { DateTime } from "luxon";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MultiSelectFromMenu from "../../components/MultiSelectFromMenu";
import DefinePasswordFieldSet from "../../components/DefinePasswordFieldSet";
import PatientIdField from "../../components/PatientIdField";
import { type DefinePasswordState } from "../../hooks/useDefinePasswordState";
import { textChangeHandler, dateChangeHandler } from "../../utils/eventUtils";
import AmchurCard from "../../components/AmchurCard";

interface CreatePatientUiViewProps {
  patientId: string;
  setPatientId: (patientId: string) => void;
  birthday: DateTime | null;
  setBirthday: (date: DateTime | null) => void;
  firstName: string;
  setFirstName: (firstName: string) => void;
  lastName: string;
  setLastName: (lastName: string) => void;
  allergies: string[];
  setAllergies: (allergies: string[]) => void;
  password: string;
  definePasswordState: DefinePasswordState;
  onSave: () => void;
  onCancel: () => void;
}

function CreatePatientUiView({
  patientId,
  setPatientId,
  birthday,
  setBirthday,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  allergies,
  setAllergies,
  password,
  definePasswordState,
  onSave,
  onCancel,
}: CreatePatientUiViewProps) {
  const intl = useIntl();
  const buttonEnabled = patientId.length > 0 && password && password.length > 0;

  return (
    <Stack
      width="90%"
      mx="2em"
      spacing="15px"
      mt="10px"
      alignItems="flex-start"
    >
      <Typography variant="h2">
        <FormattedMessage id="createPatient.header" />
      </Typography>

      <AmchurCard
        role="primary"
        titleAlign="left"
        title={intl.formatMessage({ id: "createPatient.cnic" })}
      >
        <Stack spacing="10px">
          <Stack direction="row" spacing="15px">
            <PatientIdField
              patientId={patientId}
              setPatientId={setPatientId}
              required={true}
              disabled={false}
            />
          </Stack>
          <Stack direction="row" spacing="15px">
            <DefinePasswordFieldSet
              username=""
              definePasswordState={definePasswordState}
            />
          </Stack>
        </Stack>
      </AmchurCard>
      <Box width="15px" />

      <AmchurCard
        role="primary"
        titleAlign="left"
        title={intl.formatMessage({ id: "createPatient.demographics" })}
      >
        <Stack spacing="10px">
          <Stack direction="row" spacing="15px">
            <TextField
              label={intl.formatMessage({ id: "createPatient.firstName" })}
              value={firstName}
              onChange={textChangeHandler(setFirstName)}
            />
            <TextField
              label={intl.formatMessage({ id: "createPatient.lastName" })}
              value={lastName}
              onChange={textChangeHandler(setLastName)}
            />
          </Stack>
          <Stack direction="row" spacing="15px">
            <DatePicker
              label={intl.formatMessage({ id: "createPatient.birthday" })}
              value={birthday}
              onChange={dateChangeHandler(setBirthday)}
            />
          </Stack>
        </Stack>
      </AmchurCard>
      <AmchurCard
        role="primary"
        titleAlign="left"
        title={intl.formatMessage({ id: "createPatient.medHistory" })}
      >
        <Stack direction="row" spacing="15px">
          <MultiSelectFromMenu
            value={allergies}
            setValue={setAllergies}
            options={[
              "Penicillin",
              "Sulfa",
              "Tetracycline",
              "Codeine",
              "Quinine",
            ]}
            label="allergies"
            id="allerg"
          />
        </Stack>
      </AmchurCard>
      <Box width="100%">
        <Stack mx="auto" width="150px" spacing="10px">
          <Button
            variant="contained"
            disabled={!buttonEnabled}
            data-testid="submit-button"
            onClick={onSave}
          >
            <FormattedMessage id="createPatient.save" />
          </Button>
          <Button
            variant="contained"
            data-testid="cancel-button"
            onClick={onCancel}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}

export default CreatePatientUiView;
