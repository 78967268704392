import { Stack, Typography, IconButton, Menu, MenuItem, Box, Button, Checkbox, ListItemText, TextField } from '@mui/material';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { getFieldOptions, SocialHxActiveProps, SocialHxInActiveProps, MedsInActiveProps, MedsTradeProps, AllergiesInActiveProps } from "../utils/BinUtils";
import { FormControlLabel, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../state/reducers';
import { useSelector } from "react-redux";
import GreenTick from "../assets/GreenTick.svg";
import PositiveTagIcon from "../assets/PositiveTagIcon.svg";
import NegativeTagIcon from "../assets/NegativeTagIcon.svg";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { useGetMedicineDataQuery } from '../graphql/generated'; //makes hook here, import hook
import Tooltip from '@mui/material/Tooltip';
import SmallSehatSpinner from './SehatSpinner';



// ======================================================================================Util functions============================================================================================================================
const ListOfHeadings = ['Marital Status', 'Occupation', 'Education', 'Identity', 'Set Meal Schedule', 'Exercise', 'Routine', 'Sleep Same Time', 'Stress Level', 'Trade Name', 'Medical condition', "Relation"];
const ListOfNotCapitalizations = ['Unit']

function capitalizeFirstWord(str: string): string {
  if (typeof str !== "string") { // If the string is empty, return it as is.
    return str
  } 
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Function to extract number from a string
function extractNumber(str: string): number {
  if (str.toLowerCase() === "every 4 hours") {
    return 6
  } else  if (str.toLowerCase() === "every 6 hours") {
    return 4
  }  else  if (str.toLowerCase() === "every 8 hours") {
    return 3
  }  else  if (str.toLowerCase() === "every 12 hours") {
    return 2
  } else  if (str.toLowerCase() === "once daily") {
    return 1
  }  else  if (str.toLowerCase() === "twice daily") {
    return 2
  }  else  if (str.toLowerCase() === "three times daily") {
    return 3
  }  else  if (str.toLowerCase() === "four times daily") {
    return 4
  }  
  const match = str.match(/\d+/);
  return match ? parseInt(match[0]) : 0;
}



function CheckTitle({ key, value, complete=true }: { key: string, value: any, complete?: boolean }): JSX.Element {
  const keyname = key.replace(/_/g, ' ');
  let value2 = "";
  // console.log("key is: ", key, "complete is: ", complete);
  if (!ListOfNotCapitalizations.includes(keyname)) {
    value2 = capitalizeFirstWord(value);
  } else{
    value2 = value;
  }
  
  // console.log(`In check title: value: ${value}, keyname: ${keyname}, complete: ${complete}`)
  if (complete === false && ListOfHeadings.includes(keyname)) {
    // console.log("ENTERED IN INCOMPLETE")
    if (value2 !== undefined && value2 !== "" && value2 !== null){
      
      return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
         <Typography variant="h6" sx={{color: 'orange', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
            {value2}
          </Typography>
        </div>
      );
    }else {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{color: 'orange', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
        {key.replace(/_/g, ' ')} 
        </Typography>
      </div>
    );
  } 
  } else if (ListOfHeadings.includes(keyname)) {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center', fontSize: 'clamp(15px, 1vw, 24px)' }}>
            {value2}
        </div>
      );
  } else  if (value2 !== undefined && value2 !== "" && value2 !== null) {
      return (
        <div style={{ display: 'inline-flex', alignItems: 'center', fontSize: 'clamp(15px, 0.8vw, 24px)' }}>
              {value2.length > 20 ? `${value2.slice(0, 20)}...` : value2}
          </div>
        );
    } else {
      return (
        <>
        </>
      );
  }
  return <></>;
}


// /Helpers for editable state
interface InputFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  width?: string;
  num?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({ label, value, onChange, required = false, width="100%", num=false }) => {
  const [active, setActive] = useState(false);

  return (
    <FormControl sx={{ width: width }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: active ? '#008AD8' : '#CCD4DB', // Conditional color change based on active state
        }}
      >
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </Typography>
      <Box>
        <TextField
          type={num ? "number" : "text"}
          size="small"
          value = {value}
          onChange={onChange}
          onFocus={() => setActive(true)}  // Set active to true when focused
          onBlur={() => setActive(false)}  // Set active to false when focus is lost
          variant="outlined"
          fullWidth
          InputProps={{
            sx: {
              height: "4vh",
              border: `1px solid ${required && value === "" ? 'orange': active ? '#008AD8' : '#CCD4DB'}`, // Conditional border color
              backgroundColor: "white",
              fontSize: 'clamp(15px, 0.8vw, 24px)',
              padding: '8px 8px', // Reduce padding to ensure left alignment
              paddingLeft: '0px', // Ensure text starts at the leftmost edge
              borderRadius: '8px',
              '&:focus': {
                backgroundColor: 'transparent', // Prevents background color change on focus
              },
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: 'clamp(15px, 0.8vw, 24px)',
              color: active ? '#008AD8' : '#B4B8BB', // Conditional label color
            },
          }}
          />
        </Box>
    </FormControl>
);
}


interface DropdownFieldProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: string[];
  required?: boolean;
  width?: string | number;
  sx?: any;
}

const DropdownField: React.FC<DropdownFieldProps> = ({ label, value, onChange, options, required = false, width = '100%', sx = {} }) => {
  const [active, setActive] = useState(false);

  // console.log("[IN DROP DOWN], value is: ", value);
  
  return (
    <FormControl sx={{ width: width || '100%' }}>
      {/* Typography is used for the label */}
      <Typography
        variant="h6" // Adjust the variant as needed
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: active ? '#008AD8' : '#CCD4DB'
        }}
      >
          {label} {required && <span style={{ color: 'red' }}>*</span>}
      </Typography>
    
      <Select
        size="small"
        value={value}
        onChange={onChange}
        onFocus={() => setActive(true)}  // Set active to true when focused
        onBlur={() => setActive(false)}  // Set active to false when focus is lost
        displayEmpty
        renderValue={(selected) => !selected ? <em>--</em> : selected}
        fullWidth
        sx={{
          height: "4vh",
          border: `1px solid ${value === "" ? 'orange': active ? '#008AD8' : '#CCD4DB'}`,
          backgroundColor: "white",
          fontSize: 'clamp(15px, 0.8vw, 24px)',
        }}
      >
        <MenuItem value="" disabled><em>--</em></MenuItem>
        {options.map((option: string) => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </Select>

  </FormControl>
);
}

interface DateFieldProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  width?: string | number;
  sx?: any;
}

const DateField: React.FC<DateFieldProps> = ({ label, value, onChange, required = false, width = '100%', sx = {} }) => {
  const [active, setActive] = useState(false);

  return (
  <FormControl sx={{ width: width || '100%' }}>
  {/* Typography is used for the label */}
  <Typography
    variant="h6" // Adjust the variant as needed
    sx={{
      fontSize: 'clamp(10px, 0.7vw, 16px)',
      marginBottom: '0px', // Spacing below the label
      paddingLeft: '0.45vw',    // Align label horizontally
      paddingBottom: '0px',
      color: active ? '#008AD8' : '#CCD4DB',
    }}
  >
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </Typography>
    <Box sx={{ backgroundColor: 'white', padding: '0px', borderRadius: '8px', border: '1px solid #CCD4DB' , ...sx}}>
      <input
        type="date"
        value={value}
        onChange={onChange}
        onFocus={() => setActive(true)}  // Set active to true on focus
        onBlur={() => setActive(false)}  // Set active to false on blur
        style={{
          height: "4vh",
          width: '100%',
          border: `1px solid ${value === "" ? 'orange': active ? '#008AD8' : '#CCD4DB'}`,
          backgroundColor: "white",
          fontSize: 'clamp(15px, 0.8vw, 24px)',
        }}
      />
    </Box>
  </FormControl>
);
}





function TitleSubRow({title, subtitle, showDropdown}: {title: JSX.Element | string, subtitle?: JSX.Element | string, showDropdown: boolean}): JSX.Element {
    if (!subtitle) {
      return (
        <Stack direction="row" alignItems="flex-start">
            <Typography variant="h6"  sx={{color: 'black', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
              {title} {showDropdown && <ArrowRightIcon sx={{ fontSize: '1.2vw' }} />}
            </Typography>
        </Stack>
      )
  } else {
    return (
      <Stack direction="column" alignItems="flex-start">
          <Typography variant="h6" sx={{color: 'black', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
            {title} {showDropdown && <ArrowRightIcon sx={{ fontSize: '1.2vw' }} />}
          </Typography>
            <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
            {subtitle} 
          </Typography>
      </Stack>
    )
  } 
}

function SimpleText({text1, text2, align="left"}: {text1:JSX.Element | string, text2?:JSX.Element | string,  align?: 'left' | 'center' | 'right'}): JSX.Element {
  if(!text2) {
    return (
      <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)', textAlign: align }}>
          {text1} 
        </Typography>
    )
  } else {
    return (
      <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)', textAlign: align }}>
          {text1}, {text2} 
        </Typography>
    )
  }
}

function SelectBar({ Bin, title, field, width, active=false, updateState, setActive }: {Bin: any, title: string, field: string, width?: string, active?: boolean,updateState: (field: string, value: string) => void; setActive: (value: boolean) => void;}): JSX.Element {
  const value = Bin[field];
  return (
    <Box key={field} sx={{ width: width || "100%"}}>
        <Select
          size="small"
          value={Bin[field]}
          onChange={(e) => updateState(field, e.target.value)} //this is updating state of itemstate, will leave it be and focus on designing.
          onFocus={() => setActive(true)}  // Set active to true when focused
          onBlur={() => setActive(false)}  // Set active to false when focus is lost
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>{Bin[field] ? Bin[field] : "--"}</em>;
            }
            return selected;
          }}
          fullWidth
          sx={{
            height: "4vh",
            border: `1px solid ${value === "" ? 'orange': active ? '#008AD8' : '#CCD4DB'}`,
            backgroundColor: "white",
            fontSize: 'clamp(15px, 0.8vw, 24px)',
          }}
        >
          {getFieldOptions(title, field).map((option: any) => (
            <MenuItem key={option} value={option} sx={{fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
  )
}

function LabelledSelectBar({ Bin, title, field, width, label, updateState }: { Bin: any, title: string, field: string, width?: string, label: string, updateState: (field: string, value: string) => void; }): JSX.Element {
  const [active, setActive] = useState(false);
  const value = Bin[field];
  return (
    <FormControl sx={{ width: width || '100%' }}>
      {/* Typography is used for the label */}
      <Typography
        variant="h6" // Adjust the variant as needed
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: active ? '#008AD8' : '#CCD4DB',
        }}
      >
        {label}
      </Typography>
      <Box sx={{ marginTop: '4px', marginBottom: '4px' }}>
        <SelectBar Bin={Bin} title={title} field={field} active={active} updateState={updateState} setActive={setActive}/>
      </Box>
    </FormControl>
  );
}


//Make a text area
function StyledTextField({ Bin, field, width, active = false, updateState, setActive }: {Bin: any, field: string, width?: string, active?: boolean, updateState: (field: string, value: string) => void, setActive: (value: boolean) => void}): JSX.Element {
  const value = Bin[field];
  return (
    <Box key={field} sx={{ width: width || "100%" }}>
      <TextField
        size="small"
        value={Bin[field]}
        onChange={(e) => updateState(field, e.target.value)} // Updating state of itemstate
        onFocus={() => setActive(true)}  // Set active to true when focused
        onBlur={() => setActive(false)}  // Set active to false when focus is lost
        variant="outlined"
        fullWidth
        InputProps={{
          sx: {
            height: "4vh",
            border: `1px solid ${value === "" ? 'orange': active ? '#008AD8' : '#CCD4DB'}`, // Conditional border color
            backgroundColor: "white",
            fontSize: 'clamp(15px, 0.8vw, 24px)',
            padding: '8px 8px', // Reduce padding to ensure left alignment
            paddingLeft: '0px', // Ensure text starts at the leftmost edge
            borderRadius: '8px',
            '&:focus': {
              backgroundColor: 'transparent', // Prevents background color change on focus
            },
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: 'clamp(15px, 0.8vw, 24px)',
            color: active ? '#008AD8' : '#B4B8BB', // Conditional label color
          },
        }}
      />
    </Box>
  );
}

function LabelledTextField({ Bin, field, width, label, updateState }: { Bin: any, field: string, width?: string, label: string, updateState: (field: string, value: string) => void; }): JSX.Element {
  const [active, setActive] = useState(false);
  const value = Bin[field];
  return (
    <FormControl sx={{ width: width || '100%' }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: active ? '#008AD8' : '#CCD4DB',  // Conditional color change based on active state
        }}
      >
        {label}
      </Typography>
      <Box sx={{ marginTop: '4px', marginBottom: '4px' }}>
        <StyledTextField Bin={Bin} field={field} active={active} updateState={updateState} setActive={setActive}/>
      </Box>
    </FormControl>
  );
}

//Multi select: #TODO: MAKE A HANDLE MULTIPLE OPTIONS FUNCTION 
function MultiSelectBar({ Bin, title, field, width, active=false, updateState, setActive }: {Bin: any, title: string, field: string, width?: string, active?: boolean,updateState: (field: string, value: string) => void; setActive: (value: boolean) => void;}): JSX.Element {
  const [currentState, setCurrentState] = useState<string>(Bin[field] || "");
  const value = Bin[field];
  const handleOptionChange = (option: string) => {
    let updatedState: string[];
  
    if (option.startsWith("No ")) {
      // If the option starts with "No", deselect all others and select only this option
      updatedState = [option];
    } else if (option === "Select All") {
      // If "Select All" is selected, add all available options
      updatedState = getFieldOptions(title, field).slice(2);
    } else {
      const currentArray = currentState ? currentState.split(", ") : [];
      if (currentArray.includes(option)) {
        // If the option is already selected, remove it
        updatedState = currentArray.filter((item) => item !== option);
      } else {
        // If the option is not selected, add it
        updatedState = [...currentArray.filter(Boolean), option];

        // Remove any "No" options if other options are selected
        updatedState = updatedState.filter((item) => !item.startsWith("No "));
      }
    }
  
    // Convert the array back to a comma-separated string
    const updatedString = updatedState.join(", ");
    setCurrentState(updatedString);
    updateState(field, updatedString);
  };
    return (
      <Box key={field} sx={{ width: "100%" }}>
        <Select
          size="small"
          value={currentState.split(", ")}
          onChange={() => {}} // Prevent default onChange behavior
          onFocus={() => setActive(true)}  // Set active to true when focused
          onBlur={() => setActive(false)}  // Set active to false when focus is lost
          multiple
          displayEmpty
          renderValue={(selected) => {
            if (!currentState || currentState.trim() === "") {
              return <em>--</em>;
            }
            return selected.join(", ");
          }}
          fullWidth
          sx={{
            height: "4vh",
            border: `1px solid ${value === "" ? 'orange': active ? '#008AD8' : '#CCD4DB'}`,
            backgroundColor: "white",
            fontSize: 'clamp(15px, 0.8vw, 24px)',
          }}
        >
          {getFieldOptions(title, field).map((option: string) => (
            <MenuItem key={option} value={option} sx={{ fontSize: 'clamp(15px, 0.8vw, 24px)',  padding: '1px 2px' }}>
              <Checkbox
                checked={currentState.split(", ").includes(option)}
                onChange={() => handleOptionChange(option)}
              />
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  }

function LabelledMultiSelectBar({ Bin, title, field, width, label, updateState }: { Bin: any, title: string, field: string, width?: string, label: string, updateState: (field: string, value: string) => void; }): JSX.Element {
  const [active, setActive] = useState(false);
  const value = Bin[field];
  return (
    <FormControl sx={{ width: width || '100%' }}>
      {/* Typography is used for the label */}
      <Typography
        variant="h6" // Adjust the variant as needed
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: active ? '#008AD8' : '#CCD4DB',
        }}
      >
        {label}
      </Typography>
      <Box sx={{ marginTop: '4px', marginBottom: '4px' }}>
        <MultiSelectBar Bin={Bin} title={title} field={field} active={active} updateState={updateState} setActive={setActive}/>
      </Box>
    </FormControl>
  );
}

interface Exercise {
  Exercise_Name: string;
  Exercise_Frequency: string;
  Exercise_Duration: string;
  [key: string]: string;
}

let EmptyExercise = {
  Exercise_Name: "",
  Exercise_Frequency: "",
  Exercise_Duration: ""
}




//Making an exercise Layout:
function ExerciseLayout({exercise}: {exercise: Exercise}): JSX.Element {
  // console.log("exercise received in layout: ", exercise);
  const keys = Object.keys(exercise) as Array<keyof Exercise>;
  return (
    <>
     <Stack direction="column" alignItems="flex-start">
          <Typography variant="h6" sx={{color: 'black', whiteSpace: 'nowrap',fontSize: 'clamp(15px, 1vw, 24px)',}}>
            {exercise[keys[0]]}
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
            { exercise[keys[1]] !== "" ? `${exercise[keys[1]]} days per week` : ""} 
          </Typography>
          <Typography sx={{ color: 'gray', fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
            {exercise[keys[2]]} 
          </Typography>
          </Stack>
      </Stack>
    
    </>
  )
}

function ExerciseBar({value, field, width, label, SetExercise}: {value: string, field: string, width?: string, label: string, SetExercise: (val: string) => void;  }) {
  const [active, setActive] = useState(false);
  return (
    <FormControl sx={{ width: String(width) || '100%' }}>
      {/* Typography is used for the label */}
      <Typography
        variant="h6" // Adjust the variant as needed
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: active ? '#008AD8' : '#B4B8BB'
        }}
      >
        {label}
      </Typography>
      <Box sx={{ marginTop: '4px', marginBottom: '4px' }}>

          <Box key={field} sx={{ width: '100%' }}>
        <Select
          size="small"
          value={value}
          onChange={(e) => {SetExercise(e.target.value)}} //this is updating state of itemstate, will leave it be and focus on designing.
          onFocus={() => setActive(true)}  // Set active to true when focused
          onBlur={() => setActive(false)}  // Set active to false when focus is lost
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>{value ? value : "--"}</em>;
            }
            return selected;
          }}
          fullWidth
          sx={{
            height: "4vh",
            border: `1px solid ${value === "" ? 'orange' : active ? '#008AD8' : '#CCD4DB'}`,
            backgroundColor: "white",
            fontSize: 'clamp(15px, 0.8vw, 24px)',
          }}
        >
          {getFieldOptions("socialHx", field).map((option: any) => (
            <MenuItem key={option} value={option} sx={{fontSize: 'clamp(15px, 0.8vw, 24px)'}}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>     
      </Box>
    </FormControl>
  )

}


function ExerciseEditable({exercise, isNew=false, showDelete, index, setAddExercise, setExerciseToEdit, UpdateExerciseState, DeleteExercise}: {exercise: Exercise, isNew?: boolean, showDelete: boolean, index: number, setAddExercise?: (value: boolean) => void; setExerciseToEdit?: (exercise: Exercise) => void; UpdateExerciseState: (exercise: Exercise, index: number ) => void; DeleteExercise: (index: number) => void;}): JSX.Element {
  // console.log("exercise received in layout: ", exercise);
  const [name, setName] = useState<string>("");
  const [freq, setFreq] = useState<string>("");
  const [duration, setDuration] = useState<string>("");

  const keys = Object.keys(exercise) as Array<keyof Exercise>;
  const handleClick = () => {
    if (isNew) {
      // setAddExercise?.(false); // Optional chaining in case setAddExercise is not passed
    } else {
      setExerciseToEdit?.(EmptyExercise); // Optional chaining in case setExerciseToEdit is not passed
    }
  };

  const setExerciseName = (val: string) =>{
    setName(val);
    // console.log("val in exercise name: ", val);
    if (val === "No Exercise") {
      // console.log("we in no exercise");
      const updatedExercise = { ...exercise };
      updatedExercise[keys[0]] = val;
      updatedExercise[keys[1]] = "";
      updatedExercise[keys[2]] = "";
      UpdateExerciseState(updatedExercise, index);
      if (isNew === true) {
        setAddExercise?.(false);
      }
    }
    else if (index !== -1) {
    const updatedExercise = { ...exercise };
    updatedExercise[keys[0]] = val;
    UpdateExerciseState(updatedExercise, index);
    }
  }

  const setExerciseFreq = (val: string) =>{
    setFreq(val);
    if (index !== -1) {
    const updatedExercise = { ...exercise };
    updatedExercise[keys[1]] = val;
    UpdateExerciseState(updatedExercise, index);
    }
  }

  const setExerciseDuration = (val: string) =>{
    setDuration(val);
    if (index !== -1) {
    const updatedExercise = { ...exercise };
    updatedExercise[keys[2]] = val;
    UpdateExerciseState(updatedExercise, index);
    }
  }

  const AddingExercise = () => {
    const updatedExercise = { ...exercise };
    updatedExercise[keys[0]] = name;
    updatedExercise[keys[1]] = freq;
    updatedExercise[keys[2]] = duration;
    if (isNew === true){
      if (name !== "" ) {
        UpdateExerciseState(updatedExercise, index)
        setAddExercise?.(false);
      }
    } else {
      UpdateExerciseState(updatedExercise, index)
    }
    
  }


return (
  <>
    <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
              <div onClick={handleClick}>
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}>{ isNew === true ? "Add New Exercise" : exercise[keys[0]] }</h2>
            </div>
          </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#FDFEFF', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
              {isNew && (
              <IconButton onClick={() => setAddExercise?.(false)} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: 'clamp(16px, 1vw, 20px)', }, }}>
                <CloseIcon />
              </IconButton>
              )}
            <Stack sx={{marginTop: "2vh"}}>
            <ExerciseBar  value={isNew ? name : exercise[keys[0]] === "" ? "--" : exercise[keys[0]]} field="Exercise_Name" width="100%" label="Type of Exercise" SetExercise={(val: string) => {setExerciseName(val)}} />
        <Stack direction="row" spacing={1} sx={{ marginTop: '8px' }}>
        <ExerciseBar  value={isNew ? freq : exercise[keys[1]] === "" ? "--" : exercise[keys[1]]} field="Exercise_Frequency" width="60%" label="# of days per week" SetExercise={(val: string) => {setExerciseFreq(val)}} />
        <ExerciseBar  value={isNew ? duration : exercise[keys[2]] === "" ? "--" : exercise[keys[2]]} field="Exercise_Duration" width="40%" label="Duration" SetExercise={(val: string) => {setExerciseDuration(val)}} />
        </Stack>
        </Stack>
          <Stack direction="row" spacing={1}>
          {(isNew === false && showDelete) && (
            <Button
                onClick={(e) => {
                  e.stopPropagation();
                  DeleteExercise(index);
                }}
                variant="contained"
                sx={{
                  backgroundColor: '#FF8080',
                  borderRadius: '8px',
                  width: '5vw', 
                  height: '3vh',
                  marginLeft: 'auto', 
                  display: 'flex',
                  justifyContent: 'center', 
                  alignItems: 'center',  
                  fontSize: 'clamp(15px, 0.8vw, 24px)' 
                }}
              >
                Delete
                </Button>
              )}

            {(
              isNew && (
                <Button
                onClick={(e) => {
                  e.stopPropagation();
                  AddingExercise();
                }}
                variant="contained"
                sx={{
                  backgroundColor: '#0CAF5D',
                  borderRadius: '8px',
                  width: '5vw', 
                  height: '3vh',
                  marginLeft: 'auto', 
                  display: 'flex',
                  justifyContent: 'center', 
                  alignItems: 'center',  
                  fontSize: 'clamp(15px, 0.8vw, 24px)' 
                }}
              >
                Save
                </Button>
              )
            )}
            </Stack>
        </Box>
  </>
)
}


function DeleteConfirmation({ handleDeleteClose, handleDeleteConfirm, name }: { handleDeleteClose: () => void, handleDeleteConfirm: () => void, name: string | JSX.Element }): JSX.Element {
  return (
    <div style={{width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white"}}>
      <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', padding: '10px' }}>
        Confirm Deletion
      </Typography>

      <Typography sx={{ color: 'black', textAlign: 'center', padding: '10px' }}>
        Are you sure you want to delete "{name}"?
      </Typography>

      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
        <Button onClick={handleDeleteClose} sx={{ backgroundColor: '#EDF2FC', color: '#008AD8', width: '45%' }}>
          Cancel
        </Button>

        <Button onClick={handleDeleteConfirm} sx={{ backgroundColor: '#D24E4B', color: 'white', width: '45%' }}>
          Delete
      </Button>

      </Stack>
    </div>
  )
}

function SuccessMessage({msg, setShowSuccessMessage}: {msg: string, setShowSuccessMessage: (value: boolean) => void}): JSX.Element {
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSuccessMessage(false);
    }, 2000);
    return () => clearTimeout(timer);
  }
  , [setShowSuccessMessage]);

  return (
    <div style={{width: "100%", border: '2px solid #34BC78', borderRadius: '8px', backgroundColor: "white"}}>
      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
      <img src={GreenTick} alt="Green Tick" style={{ width: "20px", height: "20px"}} />
      <Typography sx={{ color: 'black', textAlign: 'center', padding: '1px' }}>
      {msg}
      </Typography>
      </Stack>
    </div>
  )

}

function DiscontinueReason({ handleDiscontinueClose, handleDiscontinueConfirm, name, setDelReason }: { handleDiscontinueClose: () => void, handleDiscontinueConfirm: () => void, name: string | JSX.Element, setDelReason: (value: string) => void }): JSX.Element {
  const [value, setValue] = useState('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    setDelReason(event.target.value);
  };
  
  return (
    <div style={{width: "70%", border: '1px solid #CCD4DB', borderRadius: '8px', padding: "10px", backgroundColor: "white"}}>
      <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', padding: '10px', marginBottom: "5px" }}>
       Are you sure you want to discontinue "{name}"?
      </Typography>
      <FormControl sx={{ width: '100%' }}>
      <Typography
        variant="h6" // Adjust the variant as needed
        sx={{
          fontSize: 'clamp(10px, 0.7vw, 16px)',
          marginBottom: '0px', // Spacing below the label
          paddingLeft: '0.45vw',    // Align label horizontally
          paddingBottom: '0px',
          color: '#CCD4DB'
        }}
      >
          Reason for Discontinuing?
      </Typography>
    
        <Select
          size="small"
          displayEmpty
          fullWidth
          value={value}
          onChange={handleChange}
          sx={{
            height: "4vh",
            border: `1px solid #CCD4DB`,
            backgroundColor: "white",
            fontSize: 'clamp(15px, 0.8vw, 24px)',
          }}
        >
          <MenuItem value="Adverse Drug Effect">Adverse Drug Effect</MenuItem>
          <MenuItem value="Drug Interaction">Drug Interaction</MenuItem>
          <MenuItem value="Duplicate Therapy">Duplicate Therapy</MenuItem>
          <MenuItem value="Ineffective treatment">Ineffective treatment</MenuItem>
          <MenuItem value="No longer needed">No longer needed</MenuItem>
          <MenuItem value="Improve Adherence">Improve Adherence</MenuItem>
          <MenuItem value="Non-formulary">Non-formulary</MenuItem>
          <MenuItem value="Availability Issues">Availability Issues</MenuItem>
          <MenuItem value="Regulatory Recall">Regulatory Recall</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>

      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
        <Button onClick={handleDiscontinueClose} sx={{ backgroundColor: '#EDF2FC', color: '#008AD8', width: '45%' }}>
          Cancel
        </Button>

        <Button onClick={handleDiscontinueConfirm} sx={{ backgroundColor: '#D24E4B', color: 'white', width: '45%' }}>
          Delete
        </Button>
      </Stack>
    </div>
  );
}





// ===============================================================================SOCIAL HISTORY BIN============================================================================================================================

export function SocialHxBinInActive({ Bin }: SocialHxInActiveProps) {
  return (
    <Stack spacing={0}>
      {Object.entries(Bin).map(([mainCategory, subCategories]) => (
        <Box key={mainCategory}>
          <Typography variant="h6" component="div" sx={{ color: 'black', mt: mainCategory === "Personal" ? 0 : 1, mb: 1 }}> 
            {mainCategory.replace(/_/g, ' ')}
          </Typography>
          {Object.entries(subCategories).map(([subCategory, value]) => (
            <Box key={subCategory}>
              <Typography component="span" sx={{ color: 'gray' }}>
                {subCategory.replace(/_/g, ' ')}
              </Typography>
              <Typography
                component="span"
                sx={{ color: value ? 'gray' : 'gray' }}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Stack>
  );
}


export function SocialHxBinActive( { Bin, title, updateState }: {Bin: any, title: string,  updateState: (key: string, value: string) => void;}) {
  const [MaritalStatusView, setMaritalStatus] = useState(true);
  const [OccupationView, setOccupation] = useState(true);
  const [DietView, setDiet] = useState(true);
  const [ExerciseView, setExercise] = useState(true);
  const [RoutineView, setRoutine] = useState(true);
  const [SleepView, setSleep] = useState(true);
  const [StressView, setStress] = useState(true);
  const [IdentityView, setIdentity] = useState(true);
  const [EducationView, setEducation] = useState(true);


  const [ExerciseToEdit, setExerciseToEdit] = useState<Exercise>(EmptyExercise);
  const [AddExercise, setAddExercise] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showMaritalDropdown, setMaritalShowDropdown] = useState(false);
  const [showOccupationDropdown, setOccupationShowDropdown] = useState(false);
  const [showDietDropdown, setDietShowDropdown] = useState(false);
  const [showExerciseDropdown, setExerciseShowDropdown] = useState(false);
  const [showRoutineDropdown, setRoutineShowDropdown] = useState(false);
  const [showSleepDropdown, setSleepShowDropdown] = useState(false);
  const [showStressDropdown, setStressShowDropdown] = useState(false);
  const [showIdentityDropdown, setIdentityShowDropdown] = useState(false);
  const [showEducationDropdown, setEducationShowDropdown] = useState(false);

  const keys = Object.keys(Bin);

  // console.log("They keys for socialHX bin are:", JSON.stringify(Bin));

  //ERROR HANDLING FROM MODEL
  
  for (const key of keys) {
    const value = Bin[key as keyof typeof Bin];
    // console.log("PRINTING DATATYPES OF KEY VALUES");
    // console.log(`${key}: ${Array.isArray(value) ? "Array" : typeof value}`);
    //must string check:
    if (key !== "Exercise" && key !== "specific_consumptions" && key !== "Stress_Symptoms" && key !== "Stress_Management" && key !== "Diet_Pattern" && key !== "Occupation") {
      if (typeof Bin[key] !== "string"){
        // console.log("[TYPE CORRECTION] TO STRING of :", key);
        Bin[key] = "";
      }
      const fieldOptions = getFieldOptions("socialHx", key) as string[];
      if (fieldOptions.map((option: string) => option.toLowerCase()).includes(value.toLowerCase()) === false) {  
        // console.log("[TYPE CORRECTION] option ", value, "didnt exist for", key, "so made empty");
        Bin[key] = "";
      }
    }
    //for multi select (potentially arrays)
    if (key === "specific_consumptions" || key === "Stress_Symptoms" || key === "Stress_Management" || key === "Diet_Pattern") {
      // console.log("entered multi select for", key);
      if (typeof Bin[key] === "string") {
        Bin[key] = Bin[key];
      } else if (Array.isArray(value)) {
        // console.log("[TYPE CORRECTION] received array in multiselect for", key);
        Bin[key] = "";
         // If the array contains only strings, join them into a comma-separated string
         if (value.every((item: any) => typeof item === "string")) {
            Bin[key] = value.join(", ");
            // console.log("[TYPE CORRECTION] corrected array for key ", key, "now bin has", Bin[key])
          } else {
              // Otherwise, assign an empty string
              Bin[key] = "";
          }
      } else {
        Bin[key] = "";
      }
    }
    //for exercise:
    if (key === "Exercise") {
      // console.log("Entered exercise type check")
      if (Array.isArray(value) === false) {
        // console.log("[TYPE CORRECTION] exercise type fix invoked to array")
        Bin[key] = []
      }
    }
     
  }
  





  // Function to handle the toggle of edit mode
  const handleExerciseToggle = (exercise: Exercise) => {
    if (ExerciseToEdit.Exercise_Name === exercise.Exercise_Name) {
      // If the same exercise is clicked again, close the edit mode
      setExerciseToEdit(EmptyExercise);
    } else {
      // Otherwise, set the current exercise to edit mode
      setExerciseToEdit(exercise);
    }
  };

  let MaritalTitleVal: string = Bin["Marital_Status"];
  let MaritalTitle = "";
  let MaritalIncomplete = true;
    if (MaritalTitleVal !== "" && MaritalTitleVal !== undefined) {
      MaritalIncomplete = false;
        const isMaritalStatusAtStart = /^Marital Status:/i.test(MaritalTitleVal);
        if (!isMaritalStatusAtStart) {
            MaritalTitleVal = MaritalTitleVal.replace(/Marital Status\s*/i, "");
            MaritalTitle = `Marital Status: ${MaritalTitleVal.trim()}`;
        }
    } else {
      MaritalTitle = "Marital Status";
    }


  let IdentityTitleVal: string = Bin["Identity"];
  let IdentityTitle = "";
  let IdentityIncomplete = true;

  if (IdentityTitleVal !== "" && IdentityTitleVal !== undefined) {
    IdentityIncomplete = false;
    const isIdentityAtStart = /^Identity:/i.test(IdentityTitleVal);
    if (!isIdentityAtStart) {
        IdentityTitleVal = IdentityTitleVal.replace(/Identity\s*/i, "");
        IdentityTitle = `Identity: ${IdentityTitleVal}`;
    }
} else {
  IdentityTitle = "Identity";
}


  let OccupationTitleVal: string = Bin["Occupation"];
  let OccupationTitle = "Occupation";
  let OccupationIncomplete = true;
  if (OccupationTitleVal !== "" && OccupationTitleVal !== undefined) {
    const isOccupationAtStart = /^Occupation:/i.test(OccupationTitleVal);
    if (!isOccupationAtStart) {
        OccupationTitleVal = OccupationTitleVal.replace(/Occupation\s*/i, "");
        OccupationTitle = `Occupation: ${OccupationTitleVal}`;
    }
  } else {
    OccupationTitle = "Occupation";
  }

if (Bin["Occupation"] !== "" && Bin["Occupation_Type"] !== "" && Bin["Occupation"] !== undefined && Bin["Occupation_Type"] !== undefined )  {
  OccupationIncomplete = false;
}

let EducationTitleVal: string = Bin["Education"];
let EducationTitle = "";
let EducationIncomplete = true;

if (EducationTitleVal !== "" && EducationTitleVal !== undefined) {
  EducationIncomplete = false;
  const isEducationAtStart = /^Education:/i.test(EducationTitleVal);
  if (!isEducationAtStart) {
      EducationTitleVal = EducationTitleVal.replace(/Education\s*/i, "");
      EducationTitle = `Education: ${EducationTitleVal}`;
  }
} else {
  EducationTitle = "Education";
}

  let SleepTitleVal: string = Bin["Sleep_Same_Time"];
  let ActualSleepTitle = "";
  let SleepIncomplete = true;

  if (SleepTitleVal === "Yes") {
      ActualSleepTitle = "Sleep: Regular";
  } else if (SleepTitleVal === "No") {
    ActualSleepTitle = "Sleep: Irregular";
  } else if (SleepTitleVal !== "" && SleepTitleVal !== undefined) {
      const isSleepAtStart = /^Sleep:/i.test(SleepTitleVal);
      if (!isSleepAtStart) {
          SleepTitleVal = SleepTitleVal.replace(/Sleep\s*/i, "");
          ActualSleepTitle = `Sleep`;
      }
  } else {
    ActualSleepTitle = "Sleep";
  }

  if (Bin["Sleep_Same_Time"] !== "" && Bin["Sleep_Duration"] !== "" && Bin["Sleep_Quality"] !== "" && Bin["Insomnia_Symptoms"] !== "" && Bin["Sleep_Same_Time"] !== undefined && Bin["Sleep_Duration"] !== undefined && Bin["Sleep_Quality"] !== undefined && Bin["Insomnia_Symptoms"] !== undefined ) {
    SleepIncomplete = false;
  }


  let DietTitleVal: string = Bin["Set_Meal_Schedule"];
  let ActualDietTitle = "";
  let DietIncomplete = true;

  if (DietTitleVal === "Yes") {
      ActualDietTitle = "Follows a set schedule for meals";
  } else if (DietTitleVal === "No") {
    ActualDietTitle = "Does not follow a set schedule for meals";
  } else if (DietTitleVal !== "" && DietTitleVal !== undefined) {
      const isDietAtStart = /^Diet:/i.test(DietTitleVal);
      if (!isDietAtStart) {
          DietTitleVal = DietTitleVal.replace(/Diet\s*/i, "");
          ActualDietTitle = `Dietary Habits`;
      }
  } else {
    ActualDietTitle = "Dietary Habits";
  }

  if  (Bin["Set_Meal_Schedule"] !== "" && Bin["Diet_Pattern"] !== ""  && Bin["cups_fruit"] !== ""  && Bin["cups_veggies"] !== "" && Bin["helpings_packaged"] !== "" && Bin["specific_consumptions"] !== "" && Bin["sugar_spoons"] !== "" && Bin["Articial_Sweetener"] !== "" 
    && Bin["Set_Meal_Schedule"] !== undefined && Bin["Diet_Pattern"] !== undefined  && Bin["cups_fruit"] !== undefined  && Bin["cups_veggies"] !== undefined && Bin["helpings_packaged"] !== undefined && Bin["specific_consumptions"] !== undefined && Bin["sugar_spoons"] !== undefined && Bin["Articial_Sweetener"] !== undefined 
  ) {
    DietIncomplete = false;
  }
  
  let DietSubtitle: string;
  let numfruits: string = Bin["cups_fruit"] === "None" ? "" : Bin["cups_fruit"];
  let numveggies: string = Bin["cups_veggies"] === "None" ? "" : Bin["cups_veggies"];
  if (numfruits  !== "" && numveggies !== ""){
  DietSubtitle = `${numfruits} of fruit, ${numveggies} of veggies`;
  } else if (numfruits !== "") {
    DietSubtitle = `${numfruits} of fruit`;
  } else if (numveggies !== "") {
    DietSubtitle = `${numveggies} of veggies`;
  } else if (Bin["cups_fruit"] === "None" && Bin["cups_veggies"] === "None" ) {
    DietSubtitle = "No cups of fruits or veggies";
  }  else {
    DietSubtitle = "";
  }

  DietSubtitle = DietSubtitle.length > 20 ? `${DietSubtitle.slice(0, 20)}...` : DietSubtitle


  let RoutineTitleVal: string = Bin["Routine"];
  let RoutineTitle = "";
  let RoutineIncomplete = true;

  if (RoutineTitleVal !== "" && RoutineTitleVal !== undefined) {
    const isRoutineAtStart = /^Routine:/i.test(RoutineTitleVal);
    if (!isRoutineAtStart) {
        RoutineTitleVal = RoutineTitleVal.replace(/Routine\s*/i, "");
        RoutineTitle = `Routine: ${RoutineTitleVal}`;
    }
  } else {
    RoutineTitle = "Routine";
  }
  if ( Bin["Routine"] !== "" && Bin["Routine"] !== undefined) {
    RoutineIncomplete = false;
  }


  let StressTitleVal: string = Bin["Stress_Level"];
  let StressTitle = "";
  let StressIncomplete = true;

  if (StressTitleVal !== "" && StressTitleVal !== undefined) {
    const isStressAtStart = /^Stress:/i.test(StressTitleVal);
    if (!isStressAtStart) {
        //removed cuz of confusing options
        // StressTitleVal = StressTitleVal.replace(/Stress\s*/i, "");
        StressTitle = `Stress: ${StressTitleVal}`;
    }
} else {
  StressTitle = "Stress";
}

if (Bin["Stress_Level"] !== "" && Bin["Stress_Symptoms"] !== "" && Bin["Stress_Management"] !== "" && Bin["Stress_Level"] !== undefined && Bin["Stress_Symptoms"] !== undefined && Bin["Stress_Management"] !== undefined  ) {
  StressIncomplete = false;
}


// Now to look at exercise:
// first compute highest, get its title and subtitle read
// when editing, display all exercises and make add/del functions differently

let AllExercises = Bin["Exercise"];
let ExerciseIncomplete = false;
if (!Array.isArray(AllExercises)) {
  AllExercises = [AllExercises];  // Convert it to an array
}

// console.log("AllExercises: ", AllExercises);

if (AllExercises.length === 0) {
  ExerciseIncomplete = true;
}


// Initialize variables to keep track of the most time-taking exercise
let mostTimeTakingExercise: Exercise = EmptyExercise;
let maxTime = 0;

// Iterate over each exercise
AllExercises.forEach((exercise: Exercise) => {
  // Extract frequency and duration as integers using regex
  const keys = Object.keys(exercise) as Array<keyof Exercise>;
  const namestr = exercise[keys[0]];
  const freqstr = exercise[keys[1]];
  const durstr = exercise[keys[2]];
  const frequency = extractNumber(exercise[keys[1]]);
  const duration = extractNumber(exercise[keys[2]]);

  if (namestr !== "No Exercise" && (freqstr === "" || durstr === "")) {
    ExerciseIncomplete = true;
  }

  // Calculate total time (frequency * duration)
  const totalTime = frequency * duration;

  // Update the most time-taking exercise
  if (totalTime >= maxTime) {
    maxTime = totalTime;
    mostTimeTakingExercise = exercise;
  }
});

// Log the most time-taking exercise
let Exercise_Name: string;
let Exercise_Frequency: string;
let Exercise_Duration: string;

if (mostTimeTakingExercise) {
  const ExerciseKeys = Object.keys(mostTimeTakingExercise);
  Exercise_Name = mostTimeTakingExercise[ExerciseKeys[0]];
  if (Exercise_Name === "No Exercise"){
    mostTimeTakingExercise[ExerciseKeys[1]] = "";
    mostTimeTakingExercise[ExerciseKeys[2]] = "";
  }
  if (mostTimeTakingExercise[ExerciseKeys[1]] !== "") {
    Exercise_Frequency = `${mostTimeTakingExercise[ExerciseKeys[1]]} days`;
  } else {
    Exercise_Frequency = "";
  }
  Exercise_Duration = mostTimeTakingExercise[ExerciseKeys[2]];
} else {
  // console.log("No exercises found.");
    Exercise_Name = "";
    Exercise_Frequency ="";
    Exercise_Duration = "";
}
let ExerciseSubtitle: string;
if (Exercise_Frequency !== ""  && Exercise_Duration !== "") {
  ExerciseSubtitle = `${Exercise_Duration}, ${Exercise_Frequency}`;
} else if ( Exercise_Duration === "") {
  ExerciseSubtitle = `${Exercise_Frequency}`; 
} else if (Exercise_Frequency !== "" ) {
  ExerciseSubtitle = `${Exercise_Duration}`;
} else {
  ExerciseSubtitle = "";
}


if ((window.innerWidth < 1300 || Exercise_Name === "Running or Jogging" || Exercise_Name === "Strength Training" ) && ExerciseSubtitle !== "") {
  ExerciseSubtitle = `${ExerciseSubtitle.substring(0, 12)}...`;
}


const UpdateExerciseState= (newExercise: Exercise, index: number) => {
  // console.log("received", JSON.stringify(newExercise), "index: ", index)
  const keys = Object.keys(newExercise) as Array<keyof Exercise>;

  if (index !== -1) {
    AllExercises[index] = newExercise;
  } else {
    AllExercises.push(newExercise);
  }

  // console.log("Updated AllExercises: ", AllExercises);
  updateState("Exercise", AllExercises);
}

const DeleteExercise = (index: number) => {
  if (index >= 0 && index < AllExercises.length) {
    // Remove the exercise at the specified index
    AllExercises.splice(index, 1);
    
    // console.log("Deleted exercise at index:", index);
    // console.log("Updated AllExercises: ", AllExercises);
    
    // Update the state after deletion
    updateState("Exercise", AllExercises);
  } else {
    // console.log("Invalid index:", index);
  }
};

  const setStatusOn = (func: React.Dispatch<React.SetStateAction<boolean>>) => {
    func(false);
  };
  
  const setStatusOff = (func: React.Dispatch<React.SetStateAction<boolean>>) => {
    func(true);
  };

  const handleMaritalMouseEnter = () => {
    setMaritalShowDropdown(true);
  };

  const handleOccupationMouseEnter = () => {
    setOccupationShowDropdown(true);
  }
  
  const handleMaritalMouseLeave = () => {
    setMaritalShowDropdown(false);
  };

  const handleOccupationMouseLeave = () => {
    setOccupationShowDropdown(false);
  };


  const handleIdentityMouseEnter = () => {
    setIdentityShowDropdown(true);
  }
  
  const handleIdentityMouseLeave = () => {
    setIdentityShowDropdown(false);
  };


  const handleEducationMouseEnter = () => {
    setEducationShowDropdown(true);
  }
  
  const handleEducationMouseLeave = () => {
    setEducationShowDropdown(false);
  };



  const handleDietMouseEnter = () => {
    setDietShowDropdown(true);
  };
  
  const handleDietMouseLeave = () => {
    setDietShowDropdown(false);
  };
  
  const handleExerciseMouseEnter = () => {
    setExerciseShowDropdown(true);
  };
  
  const handleExerciseMouseLeave = () => {
    setExerciseShowDropdown(false);
  };
  
  const handleRoutineMouseEnter = () => {
    setRoutineShowDropdown(true);
  };
  
  const handleRoutineMouseLeave = () => {
    setRoutineShowDropdown(false);
  };
  
  const handleSleepMouseEnter = () => {
    setSleepShowDropdown(true);
  };
  
  const handleSleepMouseLeave = () => {
    setSleepShowDropdown(false);
  };
  
  const handleStressMouseEnter = () => {
    setStressShowDropdown(true);
  };
  
  const handleStressMouseLeave = () => {
    setStressShowDropdown(false);
  };
  


  return (
    <Stack spacing={0} sx={{marginBottom: "20px", position: "relative", }}>
      {/* Marital Status: */}
      <div onMouseEnter={handleMaritalMouseEnter} onMouseLeave={handleMaritalMouseLeave}>
        <div style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
          {MaritalStatusView ? (
             <div onClick={() => setStatusOn(setMaritalStatus)} >
          <TitleSubRow title={
            
            <span style={{ color: MaritalIncomplete ? 'orange' : 'inherit' }}>
            {MaritalTitle}
           </span> 
            
            } showDropdown={false}/>
           </div>
          ) : (
            <>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box>
               <div onClick={() => setStatusOff(setMaritalStatus)}>
              <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> {

              <span style={{ color: MaritalIncomplete ? 'orange' : 'inherit' }}>
              Marital Status
              </span> 

              }</h2> 
              </div>
              </Box>
            < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            <Stack direction="row" spacing={1} >
            {/* {(Bin["Marital_Status"] !== "Other" && getFieldOptions("socialHx", "Marital_Status").includes(Bin["Marital_Status"]) || Bin["Marital_Status"] === "" || Bin["Marital_Status"] === undefined)  ? ( */}
            <LabelledSelectBar Bin={Bin} title={title} field={"Marital_Status"} label="Marital Status" width="100%" updateState={updateState} />
            {/*  ) : (
             <LabelledTextField Bin={Bin} field={"Marital_Status"} label="Marital Status" width="100%" updateState={updateState} />
             )} */}
            </Stack>
            </Box>
            </>
          )}
        </div>
       </div>

       {/* Identity: */}
       <div onMouseEnter={handleIdentityMouseEnter} onMouseLeave={handleIdentityMouseLeave}>
        <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
          {IdentityView ? (
             <div onClick={() => setStatusOn(setIdentity)} >
          <TitleSubRow title={
            
            <span style={{ color: IdentityIncomplete ? 'orange' : 'inherit' }}>
            {IdentityTitle}
           </span>  
            
            } showDropdown={false}/>
          </div>
          ) : (
            <>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box>
               <div onClick={() => setStatusOff(setIdentity)} >
              <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> {
                 <span style={{ color: IdentityIncomplete ? 'orange' : 'inherit' }}>
                 Identity
                </span> 
                }</h2> 
              </div>
              </Box>
            < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            <Stack direction="row" spacing={1} >
            {/* {(Bin["Identity"] !== "Other" && getFieldOptions("socialHx", "Identity").includes(Bin["Identity"])) || Bin["Identity"] === "" || Bin["Identity"] === undefined  ? ( */}
            <LabelledSelectBar Bin={Bin} title={title} field={"Identity"} label="Identity" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Identity"} label="Identity" width="100%" updateState={updateState} />
            )} */}
            </Stack>
            </Box>
            </>
          )}
        </div>
       </div>



      {/* Occupation  */}
      <div onMouseEnter={handleOccupationMouseEnter} onMouseLeave={handleOccupationMouseLeave} >
      <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
        {OccupationView ? (
          <div onClick={() => setStatusOn(setOccupation)}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
          <TitleSubRow showDropdown={false} title={
            
            <span style={{ color: OccupationIncomplete ? 'orange' : 'inherit' }}>
            {OccupationTitle.length > 20 ? `${OccupationTitle.slice(0, 20)}...` : OccupationTitle}
           </span>      
            
            } />
          <SimpleText text1={CheckTitle({key: "Occupation_Type", value: Bin["Occupation_Type"], complete: true})} align="right"/>
          </Stack>
          </div>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
            <div onClick={() => setStatusOff(setOccupation)} >
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> {
                 <span style={{ color: OccupationIncomplete ? 'orange' : 'inherit' }}>
                 Occupation
                </span>   
            }</h2> 
            </div>
            </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
          </Stack>
          <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
          <Stack >       
            <LabelledTextField Bin={Bin} field={"Occupation"} label="Occupation" width="100%" updateState={updateState} />
          <Stack direction="row" spacing={1} sx={{ marginTop: '8px' }}>
          {/* {(Bin["Occupation_Type"] !== "Other" && getFieldOptions("socialHx", "Occupation_Type").includes(Bin["Occupation_Type"])) || Bin["Occupation_Type"] === "" || Bin["Occupation_Type"] === undefined  ? ( */}
            <LabelledSelectBar Bin={Bin} title={title} field={"Occupation_Type"} label="Occupation type" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Occupation_Type"} label="Occupation type" width="100%" updateState={updateState} />
            )} */}
          </Stack>
          </Stack>
          </Box>
          </>
        )}
      </div>
      </div>

      {/* Education */}
      <div onMouseEnter={handleEducationMouseEnter} onMouseLeave={handleEducationMouseLeave} >
      <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
        {EducationView ? (
          <div onClick={() => setStatusOn(setEducation)} >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
          <TitleSubRow showDropdown={false} title={
             <span style={{ color: EducationIncomplete ? 'orange' : 'inherit' }}>
             {EducationTitle}
            </span> 
            } />
          </Stack>
          </div>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
            <div onClick={() => setStatusOff(setEducation)}>
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}>
              {
                 <span style={{ color: EducationIncomplete ? 'orange' : 'inherit' }}>
                 Education
                </span> 
              }
              </h2> 
            </div>
            </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
          </Stack>
          <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
          <Stack >
            <LabelledSelectBar Bin={Bin} title={title} field={"Education"} label="Education" width="100%" updateState={updateState} />
          </Stack>
          </Box>
          </>
        )}
      </div>
      </div>



      {/* Diet */}
      <div onMouseEnter={handleDietMouseEnter} onMouseLeave={handleDietMouseLeave} style={{ marginTop: "15px" }}>
      <div   style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
        {DietView ? (
          <div onClick={() => setStatusOn(setDiet)} >
          <TitleSubRow showDropdown={false} 
          title={ 
          <span style={{ color: DietIncomplete ? 'orange' : 'inherit' }}>
          {ActualDietTitle}
         </span> 
         }
          subtitle={DietSubtitle} /> 
          </div>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
             <div onClick={() => setStatusOff(setDiet)} >
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> { <span style={{ color: DietIncomplete ? 'orange' : 'inherit' }}>
          Dietary Habits
         </span>} </h2>
            </div>
             </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            <Stack >
          <LabelledSelectBar Bin={Bin} title={title} field={"Set_Meal_Schedule"} label="Do you follow a set schedule for meals?" width="100%" updateState={updateState} />
          <div style={{ marginTop: '8px' }}>
          {/* {(Bin["Diet_Pattern"] !== "Other" && getFieldOptions("socialHx", "Diet_Pattern").includes(Bin["Diet_Pattern"])) || Bin["Diet_Pattern"] === "" || Bin["Diet_Pattern"] === undefined ? ( */}
            <LabelledMultiSelectBar Bin={Bin} title={title} field={"Diet_Pattern"} label="Have you observed any of the following patterns?" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Diet_Pattern"} label="Have you observed any of the following patterns?" width="100%" updateState={updateState} />
            )} */}
          </div>
            <Stack direction="row" sx={{ marginTop: '8px', width: '100%'  }} justifyContent="space-between" >
            <LabelledSelectBar Bin={Bin} title={title} field={"cups_fruit"} label="# of cups of fruit per day" width="45%" updateState={updateState} />
            <LabelledSelectBar Bin={Bin} title={title} field={"cups_veggies"} label="# of cups of veggies per day" width="52%" updateState={updateState} />
            </Stack>

            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={"helpings_packaged"} label="# of helpings of packed/canned/frozen food per day" width="100%" updateState={updateState} />
            </div>

            <div style={{ marginTop: '8px' }}>
            <LabelledMultiSelectBar Bin={Bin} title={title} field={"specific_consumptions"} label="Do you consume any of the following in your day?" width="80%" updateState={updateState} />
            </div>

            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={"sugar_spoons"} label="Tea spoons of added sugar in your diet?" width="100%" updateState={updateState} />
            </div>

            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={"Articial_Sweetener"} label="Artificial Sweetener exposure?" width="100%" updateState={updateState} />
            </div>
          </Stack>
          </Box>
          </>
        )}
      </div>
      </div>


      {/* Exercise */}
      <div onMouseEnter={handleExerciseMouseEnter} onMouseLeave={handleExerciseMouseLeave} >
      <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
        {ExerciseView ? (
          <div onClick={() => setStatusOn(setExercise)}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="column" spacing={0}>
              <TitleSubRow showDropdown={false} title={
                <span style={{ color: ExerciseIncomplete ? 'orange' : 'inherit' }}>
                 Exercise: {Exercise_Name}
                </span> 
                } />
            </Stack>
          <SimpleText text1={ExerciseSubtitle} align="right"/>
          </Stack>
          </div>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
            <div onClick={() => setStatusOff(setExercise)}>
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> 
            { <span style={{ color: ExerciseIncomplete ? 'orange' : 'inherit' }}>
              Exercise
            </span>}</h2> 
            </div>
            </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            
            <div>
                  {AllExercises.map((exercise: Exercise, index: number) => (
                    <div
                      key={index}
                      onClick={() => handleExerciseToggle(exercise)}
                      style={{ cursor: 'pointer', marginBottom: '10px' }}
                    >
                      {ExerciseToEdit.Exercise_Name === exercise.Exercise_Name ? (
                        // Render editable layout if this exercise is being edited
                        <ExerciseEditable exercise={ExerciseToEdit} index={index} showDelete={AllExercises.length >= 2 ? true : false} setExerciseToEdit={setExerciseToEdit} UpdateExerciseState={UpdateExerciseState} DeleteExercise={DeleteExercise}/>
                      ) : (
                        // Render normal layout if not being edited
                        <ExerciseLayout exercise={exercise} />
                       
                      )}
                    </div>
                  ))}
                </div>

             {AddExercise ? (
              <ExerciseEditable exercise={EmptyExercise} isNew={true} index={-1} showDelete={AllExercises.length >= 2 ? true : false} setAddExercise={setAddExercise} UpdateExerciseState={UpdateExerciseState} DeleteExercise={DeleteExercise}/>
              ) : (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setAddExercise(true);
              }}
              variant="contained"
              sx={{
                marginTop: '1vh',
                width: '100%',  // Set button to take 100% width
                backgroundColor: '#E9E9E9', // Set background color
                height: '3vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 'clamp(15px, 0.8vw, 24px)',
                color: 'black', // Set text color to black
                '&:hover': {
                  backgroundColor: '#D3D3D3', // Optional: lighter hover color
                }
              }}
            >
              + Add Exercise
            </Button>

        )}
        </Box>
        </>
        )}
      </div>
      </div>


      {/* Routine */}
      <div onMouseEnter={handleRoutineMouseEnter} onMouseLeave={handleRoutineMouseLeave} >
      <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
        {RoutineView ? (
          <div onClick={() => setStatusOn(setRoutine)}>
            <TitleSubRow showDropdown={false} title={
             <span style={{ color: RoutineIncomplete ? 'orange' : 'inherit' }}>
             {RoutineTitle}
            </span>} subtitle={<></>} />
          </div>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
            <div onClick={() => setStatusOff(setRoutine)}>
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> { <span style={{ color: RoutineIncomplete ? 'orange' : 'inherit' }}>
             Routine
            </span>}</h2> 
            </div>
            </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            <Stack >
            {/* {(Bin["Routine"] !== "Other" && getFieldOptions("socialHx", "Routine").includes(Bin["Routine"])) || Bin["Routine"] === "" || Bin["Routine"] === undefined  ? ( */}
            <LabelledSelectBar Bin={Bin} title={title} field={"Routine"} label="How often does your daily schedule change?" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Routine"} label="How often does your daily schedule change?" width="100%" updateState={updateState} />
            )} */}
            </Stack>
          </Box>
          </>
        )}
      </div>
      </div>


      {/* Sleep */}
      <div onMouseEnter={handleSleepMouseEnter} onMouseLeave={handleSleepMouseLeave} >
      <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
        {SleepView ? (
          <div onClick={() => setStatusOn(setSleep)}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <TitleSubRow showDropdown={false} title={
              <span style={{ color: SleepIncomplete ? 'orange' : 'inherit' }}>
              {ActualSleepTitle}
             </span> } 
             subtitle={CheckTitle({key: "Sleep_Quality", value: Bin["Sleep_Quality"], complete: true})} />
             <div style={{marginTop: "0.5vh"}}>
            <SimpleText text1={CheckTitle({key: "Sleep_Duration", value: Bin["Sleep_Duration"], complete: true})} align="right"/>
            </div>
          </Stack>
          </div>
        ) : (
          <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
          <Box>
            <div onClick={() => setStatusOff(setSleep)}>
            <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> {<span style={{ color: SleepIncomplete ? 'orange' : 'inherit' }}>
              Sleep
             </span> } </h2> 
            </div>
            </Box>
          < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            <Stack >
              <LabelledSelectBar Bin={Bin} title={title} field={"Sleep_Duration"} label="Hours of sleep" width="100%" updateState={updateState} />
            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={"Sleep_Same_Time"} label="Do you go to bed and wake up at the same time everyday?" width="100%" updateState={updateState} />
            </div>
            <div style={{ marginTop: '8px' }}>
            {/* {(Bin["Sleep_Quality"] !== "Other" && getFieldOptions("socialHx", "Sleep_Quality").includes(Bin["Sleep_Quality"])) || Bin["Sleep_Quality"] === "" || Bin["Sleep_Quality"] === undefined  ? ( */}
            <LabelledSelectBar Bin={Bin} title={title} field={"Sleep_Quality"} label="How do you rate your overall sleep quality?" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Sleep_Quality"} label="How do you rate your overall sleep quality?" width="100%" updateState={updateState} />
            )} */}
            </div>
            <div style={{ marginTop: '8px' }}>
            <LabelledSelectBar Bin={Bin} title={title} field={"Insomnia_Symptoms"} label="Do you experience any of the following types of insomnia?" width="100%" updateState={updateState} />
            </div>
            </Stack>
          </Box>
          </>
            )}
          </div>
          </div>


      {/* Stress */}
      <div onMouseEnter={handleStressMouseEnter} onMouseLeave={handleStressMouseLeave} >
      <div  style={{ cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1 }} >
          {StressView ? (
            <div onClick={() => setStatusOn(setStress)}>
            <TitleSubRow showDropdown={false} title={
              <span style={{ color: StressIncomplete ? 'orange' : 'inherit' }}>
              {StressTitle}
             </span> 
              
              } subtitle={CheckTitle({key: "Stress_Management", value: Bin["Stress_Management"]})} />
            </div>
          ) : (
            <>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box>
              <div onClick={() => setStatusOff(setStress)}>
              <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 'normal', fontSize: 'clamp(15px, 1.1vw, 24px)' }}> { <span style={{ color: StressIncomplete ? 'orange' : 'inherit' }}>
              Stress
             </span> }</h2> 
              </div>
              </Box>
            < ArrowDropDownIcon sx={{ fontSize: '1.2vw' }}/> 
            </Stack>
            <Box sx={{ backgroundColor: '#F8F9FA', padding: '14px', borderRadius: '8px',  border: '1px solid darkgray', position: "relative" }}>
            <Stack >
            <LabelledSelectBar Bin={Bin} title={title} field={"Stress_Level"} label="What is the patient's current stress level?" width="100%" updateState={updateState} />
            <div style={{ marginTop: '8px' }}>
            {/* {(Bin["Stress_Symptoms"] !== "Other" && getFieldOptions("socialHx", "Stress_Symptoms").includes(Bin["Stress_Symptoms"])) || Bin["Stress_Symptoms"] === "" || Bin["Stress_Symptoms"] === undefined  ? ( */}
             <LabelledMultiSelectBar Bin={Bin} title={title} field={"Stress_Symptoms"} label="Does the patient experience any stress related symptoms?" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Stress_Symptoms"} label="Does the patient experience any stress related symptoms?" width="100%" updateState={updateState} />
            )} */}
            </div>
            <div style={{ marginTop: '8px' }}>
            
            {/* {(Bin["Stress_Management"] !== "Other" && getFieldOptions("socialHx", "Stress_Management").includes(Bin["Stress_Management"])) || Bin["Stress_Management"] === "" || Bin["Stress_Management"] === undefined  ? ( */}
             <LabelledMultiSelectBar Bin={Bin} title={title} field={"Stress_Management"} label="What are the patient's daily stress-management practices" width="100%" updateState={updateState} />
            {/* ) : (
            <LabelledTextField Bin={Bin} field={"Stress_Management"} label="What are the patient's daily stress-management practices" width="100%" updateState={updateState} />
            )} */}
            </div>
            </Stack>
            </Box>
            </>
          )}
      </div>
      </div>


      {/* Other */}


      {/* Global Save */}
      {showSuccessMessage ? (
         <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1, width: '60%', opacity: 1, }} >
        <SuccessMessage
          msg={"Social Hx saved Successfuly"}
          setShowSuccessMessage={setShowSuccessMessage}
        />
        </div>
      ) : (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setStatusOff(setMaritalStatus);
            setStatusOff(setIdentity);
            setStatusOff(setDiet);
            setStatusOff(setOccupation);
            setStatusOff(setExercise);
            setStatusOff(setRoutine);
            setStatusOff(setSleep);
            setStatusOff(setStress);
            setStatusOff(setEducation);
            setShowSuccessMessage(true);
          }}
          variant="contained"
          sx={{
            backgroundColor: '#0CAF5D',
            borderRadius: '8px',
            width: '5vw',
            height: '3vh',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'clamp(15px, 0.8vw, 24px)',
          }}
        >
          Save
        </Button>
      )} 


  </Stack>
    );
  }



// ======================================================================================MEDICINE BIN============================================================================================================================

export function MedsBinInActive({ Bin }: MedsInActiveProps) {
  return (
    <>
  <Stack direction="row" justifyContent="center" alignItems="center">
    <Typography component="span" sx={{ color: 'gray' }}>
      No Medicines found
    </Typography>
  </Stack>
  </>
  )
}

function MedsTradeLayout({ Bin, Options}: { Bin: any, Options: any }): JSX.Element { 
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
  
  let incomplete = false;
  let highDosage = false;
  let dosage = 0;

  for (let i = 0; i < values.length; i++) {
    if ( keys[i] !== "Deleted" && keys[i] !== "Delete_Reason"   && keys[i] !== "Discontinued_by" && (values[i] === "" || values[i] === undefined)) {
      incomplete = true;
      break;
    }
  }

  if (Bin["Deleted"] !== undefined && Bin["Deleted"].toLowerCase() === "true") {
    return <></>;
  }

  let number: string = Bin["Number"];
  let unit: string = Bin["Unit"];
  let salt: string = Bin["Salt"];
  let route: string = Bin["Route"];
  let freq: string = Bin["Frequency"];

  // CHECK IF THE MODEL VALUES ARE IN THE OPTIONS ARRAY, THEN KEEP THEM AS IS, ELSE MAKE THEM EMPTY.
  if (Options !== undefined) {
    // console.log("Options received in meds trade: ", JSON.stringify(Options));
    if (Options?.Trade_Name !== "" && Options?.Number?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Number"].trim().toLowerCase()) === false) {
      number = "";
    } 
    if (Options?.Trade_Name !== "" && Options?.Unit?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Unit"].trim().toLowerCase()) === false) {
      unit = "";
    } 
    if (Options?.Trade_Name !== "" && Options?.Salt?.includes(Bin["Salt"]) === false) {
      salt = "";
    } 
    if (Options?.Trade_Name !== "" && Options?.Route?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Route"].trim().toLowerCase()) === false) {
      route = "";
    } else if (Bin["Route"].trim().toLowerCase() === "iv") {
      route = "Intravenous";
    }else if (Bin["Route"].trim().toLowerCase() === "po") {
      route = "Oral";
    }else if (Bin["Route"].trim().toLowerCase() === "im") {
      route = "Intramuscular";
    }else if (Bin["Route"].trim().toLowerCase() === "sc") {
      route = "Subcutaneous";
    }
    if (Options?.Trade_Name !== "" && Options?.Frequency?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Frequency"].trim().toLowerCase()) === false) {
      freq = "";
    } else if (Bin["Frequency"].trim().toLowerCase() === "qd") {
      route = "Once Daily";
    }else if (Bin["Frequency"].trim().toLowerCase() === "bid") {
      route = "Twice Daily";
    }else if (Bin["Frequency"].trim().toLowerCase() === "tid") {
      route = "Three Times Daily";
    }else if (Bin["Frequency"].trim().toLowerCase() === "qid") {
      route = "Four Times Daily";
    }else if (Bin["Frequency"].trim().toLowerCase() === "prn") {
      route = "As Needed";
    }
  }


  if (Bin["Trade_Name"] === "Aspirin") {

  } else if (Bin["Trade_Name"] === "Panadol") {
    dosage = extractNumber(Bin["Number"]) * extractNumber(Bin["Frequency"]);
    // console.log("for panadol, number: ", extractNumber(Bin["Number"]), "freq: ", extractNumber(Bin["Frequency"]), "result: ", dosage );
    if (dosage > 4000){
      highDosage = true;
    }
  } else if (Bin["Trade_Name"] === "Ibuprofen") {
    
  }

  return (
    <>
      <div style={{ backgroundColor: 'white', padding: '5px', borderRadius: '8px', marginTop: '0vh', marginBottom: '2vh', cursor: "pointer", height: '4vh' }}
      >
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
      <Stack>
      <Stack key="Bin" direction="row" alignItems="center" spacing={1}>

      <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
        {CheckTitle({key: "Trade_Name", value: Bin["Trade_Name"], complete: !(incomplete) })}
      </Typography>
      <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
        {CheckTitle({key: "Number", value: number})}{CheckTitle({key: "Unit", value: unit})}   {highDosage && ( 
        <Tooltip title={`High dosage (${dosage.toString()} per day) for medicine detected, would you like to edit it?`} arrow> 
          <WarningAmberIcon style={{ color: 'red', cursor: 'pointer', fontSize: '0.9vw' }} />
         </Tooltip>)}
      </Typography>

    </Stack>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      {CheckTitle({key: "Salt", value: salt})} 
    </Typography>
    </Stack>
      <Stack>

    <Typography component="span" sx={{ color: 'gray', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
      {CheckTitle({key: "Route", value: route})} <br />
      {CheckTitle({key: "Frequency", value: freq})} 
    </Typography>

    </Stack>
    </Stack>
    </div>
    </>
  )
}

interface BinActiveProps {
  Bin: any; 
  onStateChange: (key: string, value: string, updateState?: any) => void;
}

  //ERROR HANDLING HERE:
  //0) obviously type check for objects in an array and strings in all of em
  //1) assign current physician as prescribed by
  //2) set all deleted to false and delete reasons to none
  //3) check if start date empty, then set current date
  //4) check if end date empty, then set ongoing

export function MedsBinActive({ Bin, onStateChange }: BinActiveProps) {


  let BinArray = Array.isArray(Bin) ? [...Bin] : [Bin];
  let fields = [
    'Trade_Name', 'Salt', 'Number', 'Unit', 'Route', 
    'Frequency', 'Start_date', 'End_date', 
    'Prescribed_by', 'Deleted', 'Delete_Reason', 'Discontinued_by'
  ];
  
  // Create a new array to hold the corrected items
  let correctedArray = BinArray.map((originalItem) => {
    let item = { ...originalItem }; // Create a shallow copy of the item
  
    // Check if the item is an object
    if (typeof item !== 'object' || item === null || Array.isArray(item)) {
      // If not, return null to filter it out later
      return null;
    }
  
    // Ensure all keys in the fields array are present in the item
    for (const field of fields) {
      // If a field is missing, add it with an empty string as the value
      if (!(field in item)) {
        item[field] = '';
      }
    }
  
    // Iterate over each key in the object
    for (const key in item) {
      // Check if the value is not a string
      if (typeof item[key] !== 'string') {
        // Replace the value with an empty string
        item[key] = '';
      }
  
      try {
        if (key === "Deleted") {
          if (item[key] !== "true") {
            item[key] = "false";
          }
        } else if (key === "Start_date" && item[key] === "") {
          item[key] = new Date().toISOString().split("T")[0];
        } else if (key === "End_date" && item[key] === "") {
          item[key] = "Ongoing";
        } else if (key === "Frequency") {
          if (item[key].toLowerCase() === "qd"){
            item[key] = "Once Daily";
          }
          if (item[key].toLowerCase() === "bid"){
            item[key] = "Twice Daily";
          }
          if (item[key].toLowerCase() === "tid"){
            item[key] = "Three Times Daily";
          }
          if (item[key].toLowerCase() === "qid"){
            item[key] = "Four Times Daily";
          }
          if (item[key].toLowerCase() === "prn"){
            item[key] = "As Needed";
          }
        } else if (key === "Route") {
          if (item[key].toLowerCase() === "po"){
            item[key] = "Oral";
          }
          if (item[key].toLowerCase() === "iv"){
            item[key] = "Intravenous";
          }
          if (item[key].toLowerCase() === "im"){
            item[key] = "Intramuscular";
          }
          if (item[key].toLowerCase() === "sc"){
            item[key] = "Subcutaneous";
          }
        }
      } catch (e) {
        // console.log("[ERROR WHILE TYPE CORRECTING]", e);
      }
    }
  
    return item; // Return the corrected item
  }).filter(Boolean); // Filter out any null values
  
  console.log("[TYPE CORRECTION] MEDS BIN ARRAY NOW IS: ", correctedArray);

  BinArray = correctedArray;

  // const MedsOptions = getMedicineData(BinArray);
  // console.log("MedsOptions array is:", JSON.stringify(MedsOptions));

  const { data, error, isLoading } = useGetMedicineDataQuery({
    input: BinArray
  });

  

  const OptionsArray = data?.getMedicineData?.meds;
  console.log("[GET MEDS OPTIONS] data returned: ", OptionsArray);

  const [expanded, setExpanded] = useState(false);
  const [AddNew, setAddNew] = useState(false);
  const [inEditing, setInEditing] = useState(false);

  //keep track of editing/editing index via trade name.
  
  const toggleExpanded = () => setExpanded(!expanded);

  const nonDeletedCount = BinArray.reduce((count: number, item: any) => {
    if (item["Deleted"].toLowerCase() !== "true") {
      return count + 1;
    }
    return count;
  }, 0);

  console.log("[IN BINLAYOUTS] binArray is: ", JSON.stringify(BinArray));
  let topItems = expanded ? BinArray : BinArray.filter(item => item.Deleted?.toLowerCase() !== "true").slice(0, 3);
  console.log("[IN BINLAYOUTS] topItems is: ", JSON.stringify(topItems));
  // let topItems = BinArray;
  // console.log(`[TESTING MEDSBIN] topItems in MedsBinActive: ${JSON.stringify(topItems)}`);
  const [ItemsDisplay, setItemsDisplay] = useState(topItems);
 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [SuccessMsg, setSuccessMsg] = useState("");
  const [binToEdit, setBinToEdit] = useState<any>();
  const [binToEditIndex, setBinToEditIndex] = useState(-1);


  useEffect(() => {
    setItemsDisplay(topItems);
  }, [expanded]);

  const toggleAddNewOn = () => {
    setAddNew(true);
  }

  const toggleEditModeOn = () => {
    setInEditing(true);
  }

  return (
    <>
     {
      AddNew ? (
        <MedsTradeEditableLayout Bin={{}} updateState={setItemsDisplay} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} setSuccessMsg={setSuccessMsg} inEditing={false} binToEditIndex={binToEditIndex} OptionsArray={OptionsArray}/>
      ) : 
      inEditing ? (
        <>
        <MedsTradeEditableLayout Bin={binToEdit} updateState={setItemsDisplay} handleClose={() => setInEditing(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} setSuccessMsg={setSuccessMsg}  inEditing={true} binToEditIndex={binToEditIndex} OptionsArray={OptionsArray}/>
        </>
      ) : (
        <Stack spacing={2} position="relative">
        {isLoading ? (
          <div style={{height: '40vh', position: "relative"}} >
          <SmallSehatSpinner />
          <Typography variant="subtitle2" color="#404B5A">
            Fetching meds sheet...
          </Typography>
          </div>
        ) : (
          <>
            {ItemsDisplay
              .map((item: any, originalIndex: number) => ({ item, originalIndex }))
              .filter(({ item }) => item["Deleted"] !== "true")
              .map(({ item, originalIndex }) => (
                <div
                  key={originalIndex}
                  onClick={() => {
                    setBinToEdit(item);
                    setBinToEditIndex(originalIndex);
                    toggleEditModeOn();
                  }}
                  style={{ opacity: showSuccessMessage ? 0.5 : 1 }}
                >
                  <MedsTradeLayout Bin={item} Options={OptionsArray?.[originalIndex]} />
                </div>
              ))
            }
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {nonDeletedCount > 3 && (
                <Button
                  onClick={toggleExpanded}
                  sx={{
                    backgroundColor: '#E9E9E9',
                    color: '#404B5A',
                    width: '30%',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {expanded ? 'Collapse All' : 'Expand All'}
                </Button>
              )}
              <Button
                onClick={toggleAddNewOn}
                sx={{
                  backgroundColor: '#E9F5FC',
                  color: '#404B5A',
                  width: '30%',
                  paddingTop: '6px',
                  paddingBottom: '6px',
                  border: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                }}
              >
                + Add Medicine
              </Button>
            </div>
            {showSuccessMessage && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                  width: '60%',
                  opacity: 1,
                }}
              >
                <SuccessMessage msg={SuccessMsg} setShowSuccessMessage={setShowSuccessMessage} />
              </div>
            )}
          </>
        )}
      </Stack>
     )}
  </>
  );
}



export function MedsTradeEditableLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage, setSuccessMsg, inEditing, binToEditIndex, OptionsArray }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any, setSuccessMsg: (value: string) => void, inEditing: boolean, binToEditIndex: number, OptionsArray: any }) {
  let keys = Object.keys(Bin);
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );  
 
  // [MED QA2] add the delete and deletereason and add strings here, and add those fields when updating
  
  const [numberValue, setNumberValue] = useState<string>(inEditing === false ? "" : !OptionsArray?  Bin["Number"] : (OptionsArray[binToEditIndex]?.Trade_Name === "" || OptionsArray[binToEditIndex]?.Number?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Number"].trim().toLowerCase())  ) ? Bin["Number"] : "");
  const [tradeName, setTradeNameInput] = useState<string>(inEditing === false ? "" : Bin["Trade_Name"] || "");
  const [salt, setSaltInput] = useState<string>( inEditing === false ? "" : !OptionsArray ? Bin["Salt"] : (OptionsArray[binToEditIndex]?.Trade_Name === "" ? Bin["Salt"] : OptionsArray[binToEditIndex]?.Salt));
  const [unitValue, setUnitValue] = useState<string>(inEditing === false ? "" : !OptionsArray ? Bin["Unit"] : (OptionsArray[binToEditIndex]?.Trade_Name === "" ||  OptionsArray[binToEditIndex]?.Unit?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Unit"].trim().toLowerCase())) ? Bin["Unit"] : "");
  const [frequencyValue, setFrequencyValue] = useState<string>(inEditing === false ? "" : !OptionsArray? Bin["Frequency"] : (OptionsArray[binToEditIndex]?.Trade_Name === "" || OptionsArray[binToEditIndex]?.Frequency?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Frequency"].trim().toLowerCase())) ? Bin["Frequency"] : "");
  const [routeValue, setRouteValue] = useState<string>(inEditing === false ? "" : !OptionsArray ? Bin["Route"] : (OptionsArray[binToEditIndex]?.Trade_Name === "" || OptionsArray[binToEditIndex]?.Route?.split(",").map((num: string) => num.trim().toLowerCase()).includes(Bin["Route"].trim().toLowerCase())) ? Bin["Route"] : "");
  const [prescribedBy, setPrescribedByInput] = useState<any>(currentPhysician);
  const [startDateValue, setStartDateValue] = useState<string>(Bin["Start_date"] || "");
  const [endDateValue, setEndDateValue] = useState<string>(Bin["End_date"] || "");
  const [deleteValue, setDeleteValue] = useState<string>(Bin["Delete"] || "");
  const [deleteReasonValue, setDeleteReasonValue] = useState<string>(Bin["Delete_Reason"] || "");
  const [discontinuedByValue, setDiscontinuedByValue] = useState<string>(currentPhysician);

  const [isOngoing, setIsOngoing] = useState(endDateValue === "Ongoing");

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDiscotinueConfirmation, setShowDiscontinueConfirmation] = useState(false);
  const [delName, setDelName] = useState(Bin["Trade_Name"]);
  const [delReason, setDelReason] = useState("");

  const handleTradeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTradeNameInput(event.target.value);
  };

  const handleSaltChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaltInput(event.target.value);
  };

  const handleNumberChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setNumberValue(value);
  };

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setUnitValue(value);
  };

  const handleRouteChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setRouteValue(value);
  };

  const handleFrequencyChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFrequencyValue(value);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDateValue(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDateValue(value);
  };

  const handlePrescribedByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrescribedByInput(event.target.value);
  };


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOngoing(event.target.checked);
    if (event.target.checked) {
      setEndDateValue('Ongoing'); // Clear the end date if "Ongoing" is checked
    }
  };

    //DeleteFunction for medicine:
    const handleDeleteMedicine = (TradeName: string): any => {
      TradeName = TradeName.toString();
      //[MED QA2]just splice where name is
      const newState = {
        Trade_Name: TradeName || "",
        Salt: salt || "",
        Number: numberValue || "",
        Unit: unitValue || "",
        Route: routeValue || "",
        Frequency: frequencyValue || "",
        Start_date: startDateValue || "",
        End_date: endDateValue || "",
        Prescribed_by: prescribedBy || "",
        Deleted: "true",
        Delete_Reason: "Simply Deleted by doctor",
        Discontinued_by: discontinuedByValue || "",
      };
      const key: string = 'meds#'.concat(TradeName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, JSON.stringify(newState), updateState);
    };

    const handleDiscontinueMedicine = (TradeName: string): any => {
      TradeName = TradeName.toString();
      //[MED QA2]just modify the true/false
      const newState = {
        Trade_Name: TradeName || "",
        Salt: salt || "",
        Number: numberValue || "",
        Unit: unitValue || "",
        Route: routeValue || "",
        Frequency: frequencyValue || "",
        Start_date: startDateValue || "",
        End_date: endDateValue || "",
        Prescribed_by: prescribedBy || "",
        Deleted: "true",
        Delete_Reason: delReason || "",
        Discontinued_by: discontinuedByValue || "",
      };
      const key: string = 'meds#'.concat(TradeName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, JSON.stringify(newState), updateState);

    };
  
    const handleCancel = () => {
      setShowDeleteConfirmation(false);
      setShowDiscontinueConfirmation(false);
      setDelName("");
    }
  
    const handleDeleteConfirm = () => {
      // console.log("Delete confirmed, now remove it from list");
      handleDeleteMedicine(delName);
      handleCancel();
      handleClose();
      setSuccessMsg("Medicine Deleted Successfuly")
      setShowSuccessMessage(true);
    };
  
    const handleDiscontinueConfirm = () => {
      handleDiscontinueMedicine(delName);
      handleCancel();
      handleClose();
      setSuccessMsg("Medicine Discontinued Successfuly")
      setShowSuccessMessage(true);
    }

  const handleSave = () => {
      // Add your save logic here
      const newState = {
        Trade_Name: capitalizeFirstWord(tradeName) || "",
        Salt: salt || "",
        Number: numberValue || "",
        Unit: unitValue || "",
        Route: routeValue || "",
        Frequency: frequencyValue || "",
        Start_date: startDateValue || "",
        End_date: endDateValue || "",
        Prescribed_by: prescribedBy || "",
        Deleted: deleteValue || "",
        Delete_Reason: deleteReasonValue || "",
        Discontinued_by: discontinuedByValue || "",
      };
      const key: string = 'meds#'.concat(tradeName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, JSON.stringify(newState), updateState);
      handleClose();
      if (inEditing === true) {
        setSuccessMsg("Medicine Updated Successfuly");
      } else {
        setSuccessMsg("Medicine Added Successfuly");
      }
      setShowSuccessMessage(true);
      // console.log("[IN MEDS SAVE] index is: ", binToEditIndex);
  };

  // console.log("[IN MEDS editable] Edit index is: ", binToEditIndex);

  return (
    <>
      <div >
        { inEditing ? (
          <>
        <Box sx={{ marginBottom: '16px' }}>
          <h1 style={{ margin: '0', fontSize: 'clamp(15px, 1.2vw, 24px)', color: '#343a40', fontWeight: 'normal' }}>Edit Medicine</h1>
        </Box>
        </>
        ) : (
          <>
           <Box sx={{ marginBottom: '16px' }}>
          <h1 style={{ margin: '0', fontSize: 'clamp(15px, 1.2vw, 24px)', color: '#343a40', fontWeight: 'normal' }}>Add Medicine</h1>
        </Box>
        <Box sx={{ marginBottom: '0px' }}>
          <h2 style={{ margin: '0', fontSize: 'clamp(15px, 0.8vw, 24px)', color: '#6c757d', fontWeight: 'normal' }}>Please add medicine details</h2>
        </Box>
          </>
        )}
      </div>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '5px'}}>
        
        <IconButton onClick={() => {handleClose()}} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: 'clamp(16px, 1vw, 20px)', }, }}>
          <CloseIcon />
        </IconButton>
        <div style={{marginTop: '12px'}}>
        <InputField label="Trade Name" value={tradeName} onChange={handleTradeNameChange} required />
        </div>
        <div style={{marginTop: '8px'}}>
        <InputField label="Salt" value={salt} onChange={handleSaltChange} required />
        </div>

        {/* Number and Unit in one row */}
        {/* [MED QA2] if empty string, then pass a emptyindicator bool and make label red */}
        <div style={{marginTop: '8px'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        { OptionsArray && OptionsArray[binToEditIndex].Trade_Name !== "" ? (
          <DropdownField 
            label="Strength" 
            value={numberValue} 
            onChange={handleNumberChange} 
            options={OptionsArray[binToEditIndex].Number.split(',')} 
            required 
            width="48%"
          />
        ): (
          <>
          <InputField label="Strength" value={numberValue} onChange={handleNumberChange} required width="48%" />
          </>
        )}
        { OptionsArray && OptionsArray[binToEditIndex].Trade_Name !== "" ? (
          <DropdownField 
            label="Unit" 
            value={unitValue} 
            onChange={handleUnitChange} 
            options={OptionsArray[binToEditIndex].Unit.split(',')} 
            required 
            width="48%"
          />
        ): (
          <>
          <InputField label="Unit" value={unitValue} onChange={handleUnitChange} required width="48%" />
          </>
        )}
        </Box>
        </div>

        {/* Route and Frequency in one row */}
        <div style={{marginTop: '8px'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DropdownField 
            label="Route" 
            value={routeValue} 
            onChange={handleRouteChange} 
            options={OptionsArray && OptionsArray[binToEditIndex].Trade_Name !== "" ? OptionsArray[binToEditIndex].Route.split(',') : getFieldOptions("meds", "Route")} 
            required 
            width="48%"
          />
          <DropdownField 
            label="Frequency" 
            value={frequencyValue} 
            onChange={handleFrequencyChange} 
            options={OptionsArray && OptionsArray[binToEditIndex].Trade_Name !== "" ? OptionsArray[binToEditIndex].Frequency.split(',') : getFieldOptions("meds", "Frequency")} 
            required 
            width="48%"
          />
        </Box>
        </div>

        <div style={{marginTop: '8px'}}>
          <DateField 
            label="Start Date (MM/DD/YY)" 
            value={startDateValue} 
            onChange={handleStartDateChange} 
            required={false} 
            width="100%"
          />
          </div>

          <div style={{ marginTop: '8px' }}>
          <input
            type="checkbox"
            checked={isOngoing}
            onChange={handleCheckboxChange}
            id="ongoing-checkbox"
          />
          <label htmlFor="ongoing-checkbox" style={{ marginLeft: '8px', color: '#CCD4DB', fontSize: 'clamp(10px, 0.7vw, 16px)' }}>
            Medicine is currently Ongoing
          </label>
        </div>


        {!isOngoing && (
          <div style={{ marginTop: '8px' }}>
            <DateField
              label="End Date (MM/DD/YY)"
              value={endDateValue}
              onChange={handleEndDateChange}
              required
              width="100%"
            />
          </div>
        )}
  
          <div style={{marginTop: '8px'}}>
        <InputField label="Prescribed By" value={prescribedBy} onChange={handlePrescribedByChange} required />
        </div>
        
        <Stack direction="row" spacing={2} sx={{ marginTop: '2vh', marginBottom: '2vh' }}>
        <Button
          variant="contained"
          onClick={() => setShowDeleteConfirmation(true)}
          sx={{
            backgroundColor: '#FF8080',
            borderRadius: '8px',
            width: '6vw', 
            height: '3vh',
            marginLeft: 'auto', 
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',  
            fontSize: 'clamp(15px, 0.8vw, 24px)' 
          }}
          >
            Delete
          </Button>

         {inEditing && <Button
          variant="contained"
          onClick={() => setShowDiscontinueConfirmation(true)}
          sx={{
            backgroundColor: '#008AD8',
            borderRadius: '8px',
            width: '6vw', 
            height: '3vh',
            marginLeft: 'auto', 
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',  
            fontSize: 'clamp(15px, 0.8vw, 24px)' 
          }}
          >
            Discontinue
          </Button>
          }

          <Button
          variant="contained"
          onClick={() => {  handleSave(); }}
          sx={{
            backgroundColor: '#0CAF5D',
            borderRadius: '8px',
            width: '6vw', 
            height: '3vh',
            marginLeft: 'auto', 
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',  
            fontSize: 'clamp(15px, 0.8vw, 24px)' 
          }}
          >
            Save
          </Button>

          </Stack>

      {showDeleteConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DeleteConfirmation handleDeleteClose={handleCancel} handleDeleteConfirm={handleDeleteConfirm} name={delName} />
        </div>
      )}

      {showDiscotinueConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DiscontinueReason handleDiscontinueClose={handleCancel} handleDiscontinueConfirm={handleDiscontinueConfirm} name={delName} setDelReason={setDelReason}/>
        </div>
      )}
      </Box>


    </>
  );
}




//======================================================================FAMILY HX BIN============================================================================================

function PatientStatusWidget({ status, setStatus }: { status: string, setStatus: (value: any) => void }) {
  const handleChange = (event: any) => {
    setStatus(event.target.value);
  };

  return (
    <Stack direction="column" sx={{ marginBottom: '1vh', width: "100%" }}>
      <Typography variant="h6" component="div" sx={{ color: "#AEBECD", fontWeight: "600", fontSize: 'clamp(16px, 1vw, 24px)' }}>
        Patient Status
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: '#F8F9FA',
          border: '1px solid #CCD4DB',
          padding: '4px 8px',
          borderRadius: 2,
          alignItems: 'center',
          gap: 1,
          flexShrink: 0,
        }}
      >
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <RadioGroup row value={status} onChange={handleChange} sx={{ justifyContent: "space-between",  width: "100%", }}>
            <FormControlLabel
              value="NoSignificant"
              control={<Radio />}
              label="Not Significant"
              sx={{
                marginRight: 1,
                color: "#929FB1",
                fontSize: 'clamp(8px, 0.8vw, 15px)',
                whiteSpace: 'normal',
                "& .MuiFormControlLabel-label": { fontSize: 'clamp(8px, 0.8vw, 15px)' }
              }}
            />
            <FormControlLabel
              value="Unknown"
              control={<Radio />}
              label="Unknown"
              sx={{
                color: "#929FB1",
                fontSize: 'clamp(8px, 0.8vw, 15px)',
                whiteSpace: 'normal',
                "& .MuiFormControlLabel-label": { fontSize: 'clamp(8px, 0.8vw, 15px)' }
              }}
            />
            <FormControlLabel
              value="Adopted"
              control={<Radio />}
              label="Adopted"
              sx={{
                marginRight: 1,
                color: "#929FB1",
                fontSize: 'clamp(8px, 0.8vw, 15px)',
                whiteSpace: 'normal',
                "& .MuiFormControlLabel-label": { fontSize: 'clamp(8px, 0.8vw, 15px)' }
              }}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
}



export function FamilyHxBinInActive({ Bin }: MedsInActiveProps) {
  const [status, setStatus] = useState('');
  return (
    <>
  <Stack direction="column" justifyContent="center" alignItems="center">
    <PatientStatusWidget status={status} setStatus={setStatus}/>
    <Typography component="span" sx={{ color: 'gray', marginBottom: '1vh' }}>
      No Family History found
    </Typography>
  </Stack>
  </>
  )
}

function FamilyHxBinMedicalConditionLayout({Bin, incomplete }: {Bin: any, incomplete: boolean}): JSX.Element {
  let ConditionIncomplete = false;
  if (Bin["Name"]  === "" || Bin["Name"] === null || Bin["Name"] === undefined  || Bin["Age"]  === "" || Bin["Age"] === null || Bin["Age"] === undefined ) {
    ConditionIncomplete = true;
  }
  return (
    <>
    <div style={{ backgroundColor: 'white', borderRadius: '8px',  width: "100%" }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack key="Bin" direction="row" alignItems="center" >
      <Typography component="span" variant="h6"  sx={{ color: 'Black', margin: 0, padding: 0, fontSize: 'clamp(15px, 1.3vw, 28px)', }}>
        {CheckTitle({key: "Medical_condition", value: Bin["Name"], complete: !(ConditionIncomplete) })}
      </Typography>
    </Stack>
      <Stack>
    <Typography component="span" sx={{ color: '#929FB1', mr: 1, margin: 0, padding: 0, textAlign: 'right', display: 'block' }}>
    {Bin["Age"] !== "" ? (<> Onset: {CheckTitle({ key: "Age", value: Bin["Age"] })} Years </> ) : ""}
    </Typography>
    </Stack>
    </Stack>
    </div>
    </>
  )
}

function FamilyHxBinRelationLayout({Bin, setBinToEdit, setBinToEditIndex, toggleEditModeOn}: {Bin: any, setBinToEdit: (bin: any) => void; setBinToEditIndex: (index: number) => void; toggleEditModeOn: any; }): JSX.Element {
  // console.log("[CHECKING UI] in familyhxRelation: ", JSON.stringify(Bin));
  let incomplete = false;
  Object.keys(Bin).forEach((key) => {
    if (key === "Medical_condition" || key === "Reviewed_by" || key === "Reviewed_at" || key === "Additional_notes") {
        return;
    }
    if (key === "Age_at_death" && Bin["Status"] !== "Deceased") {
        return;
    }
    if (Bin[key] === "" || Bin[key] === null || Bin[key] === undefined) {
        incomplete = true;
    }
  });
  return (
  <>
  <Stack direction="column">
    <Stack direction="row" justifyContent="space-between">
       <Typography variant="h4" component="div" sx={{ color: "#AEBECD", fontWeight: "600" }}>
        {CheckTitle({key: "Relation", value: Bin["Relation"], complete: !(incomplete) })}
      </Typography>
     <Typography component="span" sx={{ color: '#929FB1', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
      {Bin["Age_at_death"] !== "" && Bin["Status"] === "Deceased" ? <>Died at {CheckTitle({key: "Age_at_death", value: Bin["Age_at_death"]})} years of age</> : ""}
    </Typography>  
    </Stack> 
    {Bin["Medical_condition"].map((item: any, idx: number) => (
      <div style ={{ width: "100%"}}>
        <FamilyHxBinMedicalConditionLayout key={idx} Bin={item} incomplete={incomplete} />
      </div>
      ))}
    <Typography component="span" sx={{ color: '#929FB1', margin: 0, padding: 0, display: 'block' }}>
    {CheckTitle({key: "Additional_notes", value: Bin["Additional_notes"] })}
    </Typography> 
  </Stack>
  </>
  )
}

export function FamilyHxBinActive({ Bin, onStateChange }: BinActiveProps) { 
  let BinArray = Array.isArray(Bin) ? [...Bin] : [Bin]; // Type correcting the data received from model.
  let fields = [
    'Relation', 'Status', 'Age_at_death', 'Medical_condition',  'Additional_notes', 'Reviewed_by', 'Reviewed_at'
  ];
  let correctedArray = BinArray.map((originalItem) => {
    let item = { ...originalItem }; // Create a shallow copy of the item
    if (typeof item !== 'object' || item === null || Array.isArray(item)) {
      return null;
    }
    for (const field of fields) {
      if (!(field in item)) {
        if (field === "Medical_condition") {
          item[field] = [];
        } else{
          item[field] = '';
        }
      }
    }
    for (const key in item) {
      if (key !== "Medical_condition" && typeof item[key] !== 'string') {
        item[key] = '';
      } else if (key === "Medical_condition" && Array.isArray(item[key]) === false ) {
        item[key] = [];
      }
    }
    return item; // Return the corrected item
  }).filter(Boolean); // Filter out any null values
  // console.log("[TYPE CORRECTION] FAMILYHX BIN ARRAY NOW IS: ", correctedArray);

  let PriorityOrder = getFieldOptions("familyHx", "Relation");

  let PriorityOrderAdopted = getFieldOptions("familyHx", "Relation_adopted");

  correctedArray.sort((a, b) => {
    // console.log("[TESTING SORT ORDER] a is: ", JSON.stringify(a), "b is: ", JSON.stringify(b));
    const aPriority = PriorityOrderAdopted.indexOf(a["Relation"]) !== -1 ? PriorityOrderAdopted.indexOf(a["Relation"]) : PriorityOrder.indexOf(a["Relation"]);
    const bPriority = PriorityOrderAdopted.indexOf(b["Relation"])  !== -1 ? PriorityOrderAdopted.indexOf(b["Relation"]) : PriorityOrder.indexOf(b["Relation"]);
    return (aPriority === -1 ? Infinity : aPriority) - (bPriority === -1 ? Infinity : bPriority);
  });

  const [familyHxBin, setFamilyHxBin] = useState(correctedArray);
  
  const [expanded, setExpanded] = useState(false);
  const [ItemsDisplay, setItemsDisplay] = useState(correctedArray);

  const [AddNew, setAddNew] = useState(false);
  const [inEditing, setInEditing] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [SuccessMsg, setSuccessMsg] = useState("");
  const [binToEdit, setBinToEdit] = useState<any>();
  const [binToEditIndex, setBinToEditIndex] = useState(-1);
  const [status, setStatus] = useState('');
  const [unknownReason, setunknownReason] = useState('');


  const toggleExpanded = () => setExpanded(!expanded);

  const toggleAddNewOn = () => {
    setAddNew(true);
  }

  const toggleEditModeOn = () => {
    setInEditing(true);
  }

  const handleUnknownReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setunknownReason(value);
  };

  const handleRevertStatus = () => {
    setStatus("");
    const newRelation = {
      Relation: "",
      Status: "",
      Age_at_death: "",
      Additional_notes:  "",
      Medical_condition: [{Name: "", Age: ""}],
      Reviewed_by: "",
      Reviewed_at: "",
    };
    const key = 'familyHx#RevertStatus' 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newRelation), setFamilyHxBin);
  }


  const initialItemsDisplay = useMemo(() => {
    return familyHxBin.length > 0 && familyHxBin[0]["Medical_condition"].length >= 4
      ? [familyHxBin[0]]
      : familyHxBin.slice(0, 2);
  }, [familyHxBin]);
  
  useEffect(() => {
    setItemsDisplay(expanded ? familyHxBin : initialItemsDisplay);
  }, [expanded, familyHxBin, initialItemsDisplay]);

  const handleUnknownHistory = () => {
    const newRelation = {
      Relation: "Unknown Family History",
      Status: "",
      Age_at_death: "",
      Additional_notes: unknownReason || "",
      Medical_condition: [],
    };
    const key = 'familyHx#UnknownHistory' 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newRelation), setFamilyHxBin);
    setSuccessMsg("Unknown Family History Reason saved Successfuly");
    setShowSuccessMessage(true);
  }

  const handleNotSignificantHistory = () => {
    const newRelation = {
      Relation: "No Significant Family History",
      Status: "",
      Age_at_death: "",
      Additional_notes:  "",
      Medical_condition: [],
    };
    const key = 'familyHx#UnknownHistory' 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newRelation), setFamilyHxBin);
    setSuccessMsg("Marked Not significant Family Hx Successfuly");
    setShowSuccessMessage(true);
  }

  useEffect(() => {
    if (status === "NoSignificant") {
      handleNotSignificantHistory();
    }
  }, [status]);


  // console.log("[In FAMILYHX] status is: ", status);

  return (
    <>
    {
      AddNew ? (
        <FamilyHxBinEditable Bin={{}} updateState={setFamilyHxBin} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} setSuccessMsg={setSuccessMsg} inEditing={false} binToEditIndex={binToEditIndex} status={status}/>
      ) : 
      inEditing ? (
        <>
        <FamilyHxBinEditable Bin={binToEdit} updateState={setFamilyHxBin} handleClose={() => setInEditing(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} setSuccessMsg={setSuccessMsg}  inEditing={true} binToEditIndex={binToEditIndex} status={status}/>
        </>
      ) : (
        <Stack direction="column" justifyContent="center" alignItems="center" position="relative">
        <PatientStatusWidget status={status} setStatus={setStatus}/>

        {status === "NoSignificant" ? (
          <Typography component="span" sx={{ color: 'gray', marginBottom: '1vh' }}>
            Patient has No Significant Family History
          </Typography>
        ) : status === "Unknown" ? (
         <Stack direction="column" justifyContent="center" sx={{ backgroundColor: '#F8F9FA', border: '1px solid #CCD4DB', padding: 1, borderRadius: 2, alignItems: 'center',  flexWrap: 'nowrap', width: "100%", marginBottom: "1vh", opacity: showSuccessMessage ? 0.5 : 1}} >
          <div style={{marginTop: '8px', minWidth: "100%"}}>
            <InputField label="Reason for Unknown family History" value={unknownReason} onChange={handleUnknownReasonChange} required width="100%"/>
          </div>
          <Button
            variant="contained"
            onClick={() => {handleUnknownHistory();}} //add a unknown reason save handler
            sx={{
              backgroundColor: '#0CAF5D',
              borderRadius: '8px',
              width: '6vw', 
              height: '3vh',
              marginLeft: 'auto', 
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center',  
              fontSize: 'clamp(15px, 0.8vw, 24px)',
              marginTop: "0.5vh",
              marginBottom: "0.5vh"
            }}>
              Save
          </Button>
          </Stack>
        ) : (
        <>
          {Object.entries(ItemsDisplay).map(([key, items], idx) => (
              <div style ={{ marginBottom: '1vh', marginTop: '2vh', width: "100%",  cursor: 'pointer', opacity: showSuccessMessage ? 0.5 : 1}}  onClick={() => {setBinToEdit(items); setBinToEditIndex(idx); toggleEditModeOn();}}>
              <FamilyHxBinRelationLayout key={idx} Bin={items} setBinToEdit={setBinToEdit} setBinToEditIndex={setBinToEditIndex}  toggleEditModeOn={toggleEditModeOn} />
              </div>
            ))}
            </>
          )}

          {status !== "NoSignificant" && status !== "Unknown" ? (
        <div style={{ display: 'flex', justifyContent: familyHxBin.length > 2 ? 'space-between' : 'right', width: "100%", marginTop: '2vh' }}>
          {familyHxBin.length > 2 ? (
            <Button
              onClick={toggleExpanded}
              sx={{
                backgroundColor: '#E9E9E9',
                color: '#404B5A',
                width: '30%',
                paddingTop: '6px',
                paddingBottom: '6px',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              }}
            >
              {expanded ? 'Collapse All' : 'Expand All'}
            </Button>
            ) : (<></>)}
          <Button
            onClick={toggleAddNewOn}
            sx={{
              backgroundColor: '#E9F5FC',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            + Add Relation
          </Button>
        </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'right', width: "100%", marginTop: '2vh' }}>
          <Button
            onClick={handleRevertStatus}
            sx={{
              backgroundColor: '#E9F5FC',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            Add Family Hx
          </Button>
        </div>
        )}
        {showSuccessMessage && (
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1, width: '60%', opacity: 1, }} >
            <SuccessMessage msg={SuccessMsg} setShowSuccessMessage={setShowSuccessMessage} />
          </div>
        )}
      </Stack>
   )}
  </>
  )
}

function FamilyHxBinConditionEditable({Bin, onStateChange, inEditing, relation, updateState, setDelName, setShowDeleteConfirmation, setDelRelation}: {Bin: any,  onStateChange: (key: string, value: string, updateState?: any) => void; inEditing: boolean, relation: string, updateState: any, setDelName: any, setShowDeleteConfirmation: any, setDelRelation: any}) {
  const [condition, setCondition] = useState<string>(inEditing === false ? "" : Bin["Name"] || "")
  const [Age, setAge] = useState<string>(inEditing === false ? "" : Bin["Age"] || "")
  const EditKeyCondition = inEditing === false ? "" : Bin["Name"] || "";

  const WriteEdits = () => {
    const newCondition = {
        Name: condition || "",
        Age: Age || "",
      };
      const key = 'familyHx#Condition#'.concat(relation, "#", EditKeyCondition);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, JSON.stringify(newCondition), updateState); //add updatestate here
  }
  const handleMedicalConditionChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setCondition(value);
  };
  const handleDateOnsetChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setAge(value);
  };

  const handleDeleteSelected = () =>{
    setDelName(condition);
    setShowDeleteConfirmation(true);
    setDelRelation(false);
  }

  useEffect(() => {
      WriteEdits();
  }, [condition, Age]);
  return (
    <>  
     < div style={{marginTop: '8px'}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DropdownField 
              label="Medical Condition" 
              value={condition} 
              onChange={handleMedicalConditionChange} 
              options={getFieldOptions("familyHx", "Medical_condition")} 
              required 
              width="50%"
            />
          <InputField label="Age of Onset" value={Age} onChange={handleDateOnsetChange} required num={true} width="30%" />

          <IconButton onClick={() => {handleDeleteSelected()}} sx={{ padding: '0.3vw', '& .MuiSvgIcon-root': { fontSize: 'clamp(16px, 1.5vw, 25px)', }, }}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
        </div>
    </>
  )
}

function FamilyHxBinEditable({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage, setSuccessMsg, inEditing, binToEditIndex, status }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any, setSuccessMsg: (value: string) => void, inEditing: boolean, binToEditIndex: number, status: string }) {
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  ); 

  const [Relation, setRelation] = useState<string>(inEditing === false ? "" : Bin["Relation"] || "")
  const [Status, setStatus] = useState<string>(inEditing === false ? "" : Bin["Status"] || "")
  const [AgeDeath, setAgeDeath] = useState<string>(inEditing === false ? "" : Bin["Age_at_death"] || "")
  const [AdditionalNotes, setAdditionalNotes] = useState<string>(inEditing === false ? "" : Bin["Additional_notes"] || "")
  const [ReviewedBy, setReviewedBy] = useState<string>(inEditing === false ? "" : Bin["Reviewed_by"] || "")
  const [ReviewedAt, setReviewedAt] = useState<string>(inEditing === false ? "" : Bin["Reviewed_at"] || "")

  interface Condition {
    Name: string;
    Age: string;
  }
  const [MedicalCondition, setMedicalCondition] = useState<Condition[]>([ { Name: "", Age: "" }]); // this is only used when adding new Relation
 
  const handleMedicalConditionChange = (e: SelectChangeEvent<string>, index: number) => {
    const newName = e.target.value;
    setMedicalCondition((prevConditions) =>
      prevConditions.map((condition, i) =>
        i === index ? { ...condition, Name: newName } : condition
      )
    );
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newAge = e.target.value; 
    setMedicalCondition((prevConditions) =>
      prevConditions.map((condition, i) =>
        i === index ? { ...condition, Age: newAge } : condition
      )
    );
  };

  const handleNewAddNewCondition = () => {
    setMedicalCondition((prev) => [...prev, { Name: "", Age: "" }]);
  };
  
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [delName, setDelName] = useState(inEditing === false ? "" : "Relation ".concat(Bin["Relation"]) || "");
  const [isDeceased, setIsDeceased] = useState(Bin["Status"] === "Deceased");

  const [delRelation, setDelRelation] = useState(true);

  const [ConditionSuccessMessage, setConditionSuccessMessage] = useState<string>("");
  const [ConditionShowSuccess, setConditionShowSuccess] = useState(false);

  const EditKeyRelation = inEditing === false ? "" : Bin["Relation"] || "";

  const handleRelationChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setRelation(value);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setStatus(value);
    if (value === "Deceased") {
      setIsDeceased(true);
    } else {
      setAgeDeath("");
      setIsDeceased(false);
    }
  };

  const handleAgeDeathChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setAgeDeath(value);
  };

  const handleAdditionalNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAdditionalNotes(value);
  };

  const handleCancel = () => {
    setShowDeleteConfirmation(false);
    setDelName("");
  }


  const handleDeleteSelected = () => { // so here, we have to filter out all names in "selected" object from Medical_condition array and send it back with "DelCondition" case
    let conditionsArray = Bin["Medical_condition"];
    const updatedConditionsArray = conditionsArray.filter((condition: any) => condition["Name"] !== delName );
    console.log("[deleting conditions] updated Conditions array for this bin: ", JSON.stringify(updatedConditionsArray));
    const key = 'familyHx#DelCondition#'.concat(EditKeyRelation);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(updatedConditionsArray), updateState);
    handleCancel();
    setConditionSuccessMessage("Medical Condition Deleted Successfuly") //check if this properly comes over the Edit layout
    setConditionShowSuccess(true);
  };


   const handleDeleteConfirm = () => {
      // console.log("Delete confirmed, now remove it from list");
      const key = 'familyHx#DelRelation#'.concat(EditKeyRelation);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, "", updateState);
      handleCancel();
      handleClose();
      setSuccessMsg("Relation Deleted Successfuly") 
      setShowSuccessMessage(true);
    };

    const handleAddCondition = () => {
      const newCondition = {
        Name: "",
        Age: "",
      }
      const key = 'familyHx#AddCondition#'.concat(EditKeyRelation);
       // eslint-disable-next-line @typescript-eslint/no-explicit-any
       onStateChange(key, JSON.stringify(newCondition), updateState);
       setConditionSuccessMessage("Medical Condition Added Successfuly");
       setConditionShowSuccess(true);
    }

    const handleReviewClicked = () => {
      const today = new Date();
      const dateString = today.toISOString().split('T')[0];
      setReviewedBy(currentPhysician);
      setReviewedAt(dateString);
    }

     const handleSave = () => {
      const newRelation = {
        Relation: Relation || "",
        Status: Status || "",
        Age_at_death: AgeDeath || "",
        Additional_notes: AdditionalNotes || "",
        Medical_condition: inEditing === false ? MedicalCondition : Bin["Medical_condition"],
        Reviewed_by: ReviewedBy || "",
        Reviewed_at: ReviewedAt || "",
      };
      const key = inEditing === false ? 'familyHx#AddRelation' : 'familyHx#Relation#'.concat(EditKeyRelation);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onStateChange(key, JSON.stringify(newRelation), updateState);
      handleClose();
      if (inEditing === true) {
        setSuccessMsg("Medical Condition Updated Successfuly");
      } else {
        setSuccessMsg("Medical Condition Added Successfuly");
      }
      setShowSuccessMessage(true);
  };
  return (
    <>
     <div >
        { inEditing ? (
          <>
          <h1 style={{ margin: '0', fontSize: 'clamp(15px, 1.2vw, 24px)', color: '#343a40', fontWeight: 'normal' }}>Edit Relation</h1>
          <h2 style={{ margin: '0', fontSize: 'clamp(15px, 0.8vw, 24px)', color: '#6c757d', fontWeight: 'normal' }}>Please add relative's history</h2>
        </>
        ) : (
          <>
          <h1 style={{ margin: '0', fontSize: 'clamp(15px, 1.2vw, 24px)', color: '#343a40', fontWeight: 'normal' }}>Add Relation</h1>
          <h2 style={{ margin: '0', fontSize: 'clamp(15px, 0.8vw, 24px)', color: '#6c757d', fontWeight: 'normal' }}>Please add relative's history</h2>
          </>
        )}
      </div>


      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '5px'}}>
        
        <IconButton onClick={() => {handleClose()}} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: 'clamp(16px, 1vw, 20px)', }, }}>
          <CloseIcon />
        </IconButton>
        
       
        <div style={{marginTop: '16px', opacity: ConditionShowSuccess ? 0.5 : 1}}>
          <DropdownField 
            label="Relationship" 
            value={Relation} 
            onChange={handleRelationChange} 
            options={status !== "Adopted" ? getFieldOptions("familyHx", "Relation") : getFieldOptions("familyHx", "Relation_adopted") } 
            required 
            width="100%"
          />
          </div>
          <div style={{marginTop: '8px', opacity: ConditionShowSuccess ? 0.5 : 1}}>
          <DropdownField 
            label="Status" 
            value={Status} 
            onChange={handleStatusChange} 
            options={getFieldOptions("familyHx", "Status")} 
            required 
            width="100%"
          />
          </div>

        { isDeceased && (
        <div style={{marginTop: '8px', opacity: ConditionShowSuccess ? 0.5 : 1}}>
        <InputField label="Age at death" value={AgeDeath} onChange={handleAgeDeathChange} required num={true} />
        </div>
        )}

        <Box sx={{ backgroundColor: '#EEF4F7BD', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '5px', marginTop: '16px'}}>    
        { inEditing === true ? (
              <>
              {Bin["Medical_condition"].map((item: any, idx: number) => (
                <div style ={{ marginBottom: '0.5vh', width: "100%", opacity: ConditionShowSuccess ? 0.5 : 1}}>
                  <FamilyHxBinConditionEditable key={idx} Bin={item} onStateChange={onStateChange} inEditing={inEditing} relation={EditKeyRelation} updateState={updateState} setDelName={setDelName} setShowDeleteConfirmation={setShowDeleteConfirmation} setDelRelation={setDelRelation}/>
                </div>
                ))}


            <Stack direction="row" justifyContent="center" alignItems="center" sx={{opacity: ConditionShowSuccess ? 0.5 : 1}}>
              <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddCondition(); //handling this rn
                  }}
                  variant="contained"
                  sx={{
                    marginTop: '1vh',
                    marginBottom: '1vh',
                    backgroundColor: '#E1E7EC',
                    fontSize: 'clamp(15px, 0.8vw, 24px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '3px 6px',

                    color: '#404B5A',
                    '&:hover': {
                      backgroundColor: '#D3D3D3',
                    },
                  }}
                >
                  + Add Condition
                </Button>

                </Stack>
                </>
            ) : (  // this is the "Add new case" 
              <>
            {MedicalCondition.map((item, index) => (
              <div key={index} style={{ marginTop: '8px' , opacity: ConditionShowSuccess ? 0.5 : 1}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <DropdownField 
                    label="Medical Condition" 
                    value={item.Name} 
                    onChange={(e) => handleMedicalConditionChange(e, index)} 
                    options={getFieldOptions("familyHx", "Medical_condition")} 
                    required 
                    width="60%"
                  />
                  <InputField 
                    label="Age of Onset" 
                    value={item.Age} 
                    onChange={(e) => handleAgeChange(e, index)} 
                    required 
                    num={true} 
                    width="40%" 
                    />
                  </Box>
                </div>
              ))}
          
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{opacity: ConditionShowSuccess ? 0.5 : 1}}>
              <Button
              onClick={(e) => {
                e.stopPropagation();
                handleNewAddNewCondition();
              }}
              variant="contained"
              sx={{
                marginTop: '1vh',
                marginBottom: '1vh',
                backgroundColor: '#E1E7EC',
                fontSize: 'clamp(15px, 0.8vw, 24px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '3px 6px',
                color: '#404B5A',
                '&:hover': {
                  backgroundColor: '#D3D3D3',
                },
              }}
            >
              + Add Condition
            </Button>

            </Stack>

            </>
            )}

        </Box>

        <div style={{marginTop: '8px'}}>
        <InputField label="Additional Notes" value={AdditionalNotes} onChange={handleAdditionalNotesChange} required={false} />
        </div>
        
      {showDeleteConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DeleteConfirmation handleDeleteClose={handleCancel} handleDeleteConfirm={ delRelation ? handleDeleteConfirm : handleDeleteSelected} name={delName} />
        </div>
      )}

        {ConditionShowSuccess && (
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1, width: '60%', opacity: 1, }} >
            <SuccessMessage msg={ConditionSuccessMessage} setShowSuccessMessage={setConditionShowSuccess} />
          </div>
        )}

      </Box>



      <Stack direction="row" justifyContent="space-between" alignItems="center">

      <Stack direction="column">
      <Stack direction="row" alignItems="center">
      <Checkbox size="small" sx={{ p: 0, mr: 1, fontSize: 9 }} onClick={() => {handleReviewClicked()}} />
      <Typography component="span" sx={{ color: 'gray', fontSize: 10 }}>
        Mark as Reviewed
      </Typography>
      </Stack>

      <Typography component="span" sx={{ color: 'gray', mr: 0, fontSize: 11 }}>
        Last Reviewed By Dr. {ReviewedBy} on {ReviewedAt !== "" ? <>{ReviewedAt}</> : <>Date</> }
      </Typography>
      </Stack>

      <Stack direction="row" spacing={2} sx={{ marginTop: '2vh', marginBottom: '2vh' }}>
          {inEditing && (
          <Button
            variant="contained"
            onClick={() => {
              setDelRelation(true);
              setShowDeleteConfirmation(true);
            }}
            sx={{
              backgroundColor: '#FF8080',
              borderRadius: '8px',
              width: '6vw', 
              height: '3vh',
              marginLeft: 'auto', 
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center',  
              fontSize: 'clamp(15px, 0.8vw, 24px)' 
            }}
            >
            Delete
          </Button>
          )}

       
          <Button
          variant="contained"
          onClick={() => {  handleSave(); }}
          sx={{
            backgroundColor: '#0CAF5D',
            borderRadius: '8px',
            width: '6vw', 
            height: '3vh',
            marginLeft: 'auto', 
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',  
            fontSize: 'clamp(15px, 0.8vw, 24px)' 
          }}
          >
            Save
          </Button>
          </Stack>
          </Stack>
    </>
  )
}
  




//============================================================ALLERGIES BIN=================================================================================

export function AllergiesBinInActive({Bin}: AllergiesInActiveProps) {
  return (
    <>
  <Stack direction="column" justifyContent="space-between">
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      Allergen
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      Allergen Type
    </Typography>
  </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Reaction 
  </Typography>
  </Stack>
    <Stack>
  <Stack direction="row" alignItems="flex-start" spacing={3} sx={{mr: 1}}>
    <EventRepeatIcon sx={{ color: 'gray', fontSize: 21 }} />
    <EditIcon sx={{ color: 'gray', fontSize: 21 }} />
    <DeleteOutlineIcon sx={{ color: 'gray', fontSize: 21 }} />
  </Stack>
  <Typography component="span" sx={{ color: 'black', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
    Category
  </Typography>
  </Stack>
  </Stack>
  {/* Reviewed By Stack: */}
  <Stack direction="row" justifyContent="space-between" >
  <Stack direction="row" alignItems="center">
      <Checkbox size="small" sx={{ p: 0, mr: 1, fontSize: 11 }} />
      <Typography component="span" sx={{ color: 'gray', fontSize: 15 }}>
        Mark as Reviewed
      </Typography>
    </Stack>
  <Typography component="span" sx={{ color: 'gray', mr: 0, fontSize: 14 }}>
    Last Reviewed By Dr. on Date
  </Typography>
  </Stack>
  </Stack>
  </>
  )

}


function AllergiesLayout({ Bin, handleDelete, setShowDeleteConfirmation, setDelName, onStateChange, setItemsDisplay, setShowSuccessMessage }: { Bin: MedsInActiveProps, handleDelete: (TradeName: string) => void, setShowDeleteConfirmation: (value: boolean) => void, setDelName: (value: string) => void, onStateChange: (key: string, value: string, updateState?: any) => void, setItemsDisplay: any, setShowSuccessMessage: any }): JSX.Element {
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
   

  const [DateClicked, setDateClicked] = useState(false);
  const [inEditing, setInEditing] = useState<boolean>(false);

  // console.log(`IN ALLERGIES BIN, BIN IS: ${Bin}`);
  
  let incomplete = false;

  for (let i = 0; i < values.length; i++) {
    if (values[i] === "" || values[i] === undefined) {
      incomplete = true;
      break;
    }
  }

  const toggleDateClicked = () => setDateClicked(!DateClicked);
  const toggleEditModeOn = () => setInEditing(true);
  const toggleEditModeOff = () => setInEditing(false);

  const handleDeleteClick = () => {
    setDelName(values[0])
    setShowDeleteConfirmation(true);
  } 

   const handleUpdateState = (key: string, value: string) => {
        // Example update function, can be customized
        console.log(`Updated ${key} to ${value}`);
      };
 
  return (
    <>

{inEditing ? (

<AllergiesEditableLayout  Bin={Bin} updateState={setItemsDisplay} handleClose={toggleEditModeOff} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage}  />

) : (


    <div style={{ backgroundColor: incomplete ? '#F8D7DA' : 'white', paddingInline: '5px', borderRadius: '8px', margin: 0, paddingTop: '15px', paddingBottom: '15px' }}>
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    {/* <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      {CheckTitle({Bin, keys, index: 0})}
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      {CheckTitle({Bin, keys, index: 1})}
    </Typography> */}
  </Stack>
  {/* <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    {CheckTitle({Bin, keys, index: 2})} 
  </Typography> */}
  </Stack>
    <Stack>
  <Stack direction="row" alignItems="flex-end" spacing={1} sx={{ ml: 'auto' }}>

    <IconButton
      onClick={toggleDateClicked}
      sx={{ color: 'gray' }} >
    <EventRepeatIcon sx={{ fontSize: 21 }} />
    </IconButton>


    <IconButton
      onClick={toggleEditModeOn}
      sx={{ color: 'gray' }}
    >
      <EditIcon sx={{ fontSize: 21 }} />
    </IconButton>



    <IconButton
      onClick={handleDeleteClick}
      sx={{ color: 'gray' }}
    >
      <DeleteOutlineIcon sx={{ fontSize: 21 }} />
    </IconButton>
  </Stack>
    {/* {
      DateClicked ? (
        MedsDatesLayout({startDate: CheckTitle({Bin, keys, index: 4}), endDate: CheckTitle({Bin, keys, index: 5})})
      ) : (
        <Typography component="span" sx={{ color: 'black', mr: 1, margin: 0, textAlign: 'right', display: 'block' }}>
        {CheckTitle({Bin, keys, index: 3})} 
        </Typography>
      )
    } */}
  </Stack>
  </Stack>

  </div>
  )}
  </>
  )
}



export function AllergiesBinActive({ Bin, onStateChange, DeleteItemFromBin }: any) {
  // console.log(`[TESTING ALLERGIESSBIN] Bin in AllergiesBinActive: `, Bin);
  let BinArray = Array.isArray(Bin) ? Bin : [Bin];
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  ); 

  const [expanded, setExpanded] = useState(false);
  const [AddNew, setAddNew] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  let topItems = expanded ? BinArray : BinArray.slice(0, 3);

  const [ItemsDisplay, setItemsDisplay] = useState(topItems);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [binUpdated, setBinUpdated] = useState(true);
  let UpdatedBin = true;

  const [delName, setDelName] = useState("");

  useEffect(() => {
    setItemsDisplay(topItems);
  }, [expanded]);

  //DeleteFunction for medicine:
  const handleDeleteAllergy = (AllergenName: string): any => {
    AllergenName = AllergenName.toString();
    const key: string = 'allergies#'.concat(AllergenName);
    DeleteItemFromBin(key, "", setItemsDisplay); // since no need to specify reason for this case
    BinArray = BinArray.filter((item: any) => item.Allergen !== AllergenName);
    topItems = expanded ? BinArray : BinArray.slice(0, 3);
    setItemsDisplay(topItems);
    // console.log("Bin after deletion: ", JSON.stringify(BinArray, null, 2));
  };


  const handleCancel = () => {
    setShowDeleteConfirmation(false);
    setDelName("");
  }

  const handleDeleteConfirm = () => {
    // console.log("Delete confirmed, now remove it from list");
    handleDeleteAllergy(delName);
    handleCancel();
  };

  const toggleAddNewOn = () => {
    setAddNew(true);
    setBinUpdated(false);
    UpdatedBin = false;
  }

  // console.log("ItemsDisplay:", ItemsDisplay);

  return (
    <>
    <Stack spacing={2} position="relative">
      {ItemsDisplay.map((item: any, index: number) => (
        <AllergiesLayout key={index} Bin={item} handleDelete={handleDeleteAllergy} setShowDeleteConfirmation={setShowDeleteConfirmation}  setDelName={setDelName} onStateChange={onStateChange} setItemsDisplay={setItemsDisplay} setShowSuccessMessage={setShowSuccessMessage} />
      ))}
        { AddNew ? (

        <AllergiesEditableLayout Bin={{}} updateState={setItemsDisplay} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />

        ) : (
          <>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {Bin.length >= 3 && (
          <Button
            onClick={toggleExpanded}
            sx={{
              backgroundColor: '#E9E9E9',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {expanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        <Button
        onClick={toggleAddNewOn}
          sx={{
            backgroundColor: '#E9F5FC',
            color: '#404B5A',
            width: '30%',
            paddingTop: '6px',
            paddingBottom: '6px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          + Add Allergy
        </Button>
      </div>

        <Stack direction="row" justifyContent="space-between" >
        <Stack direction="row" alignItems="center">
            <Checkbox sx={{ p: 0, mr: 1 }} />
            <Typography component="span" sx={{ color: 'gray', fontSize: 15 }}>
              Mark as Reviewed
            </Typography>
          </Stack>
        <Typography component="span" sx={{ color: 'gray', mr: 0, fontSize: 14 }}>
          Last Reviewed By Dr. {currentPhysician} on {new Date().toLocaleDateString()}
        </Typography>
        </Stack>

        </>
      

        )}

      {showDeleteConfirmation && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <DeleteConfirmation handleDeleteClose={handleCancel} handleDeleteConfirm={handleDeleteConfirm} name={delName} />
        </div>
      )}

      {showSuccessMessage && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <SuccessMessage msg="Allergy" setShowSuccessMessage={setShowSuccessMessage} />
        </div>
      )}

    </Stack>
    </>
  );
}

export function AllergiesEditableLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any}) {
  let keys = Object.keys(Bin);
  let values = Object.values(Bin);
  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  );  

  const [allergen, setAllergen] = useState<string>(Bin[keys[0]]);
  const [allergenType, setAllergenType] = useState<string>(Bin[keys[1]]);
  const [reaction, setReaction] = useState<string>(Bin[keys[2]]);
  const [category, setCategory] = useState<string>(Bin[keys[3]]);
  const [startDateValue, setStartDateValue] = useState<string>(Bin[keys[4]]);
  const [endDateValue, setEndDateValue] = useState<string>(Bin[keys[5]]);
  const [carryEpipen, setCarryEpipen] = useState<string>(Bin[keys[6]]);
  const [usedEpipen, setUsedEpipen] = useState<string>(Bin[keys[7]]);
  const [lastUsedEpipen, setLastUsedEpipen] = useState<string>(Bin[keys[8]]);
  const [additionalNotes, setAdditionalNotes] = useState<string>(Bin[keys[9]]);
  const [reviewedByInput, setReviewedByInput] = useState<string>(currentPhysician);
  


  const handleAllergenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllergen(event.target.value);
  };

  const handleAllergenTypeChange = (event: SelectChangeEvent<string>) => {
    setAllergenType(event.target.value);
  };

  const handleReactionChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setReaction(value);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setCategory(value);
    
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDateValue(value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDateValue(value);
  };

  const handleCarryEpipenChange = (event: SelectChangeEvent<string>) => {
    setCarryEpipen(event.target.value);
    if (event.target.value === "No") {
      setUsedEpipen("No");
      setLastUsedEpipen("1991-01-01");
    } 
  };


  const handleUsedEpipenChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setUsedEpipen(value);
    if (event.target.value === "No") {
      setLastUsedEpipen("1991-01-01");
    }
  };

  const handleLastUsedEpipenChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setLastUsedEpipen(value);
  };

  const handleAdditionalNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalNotes(event.target.value);
  }

  const handleReviewedByChange = () => {
    setReviewedByInput(currentPhysician);
  }



  const handleSave = () => {
    // Add your save logic here
    const newState = {
      Allergen: allergen || "",
      AllergenType: allergenType || "",
      Reaction: reaction || "",
      Category: category || "",
      StartDate: startDateValue || "",
      EndDate: endDateValue || "",
      CarryEpipen: carryEpipen || "",
      UsedEpipen: usedEpipen || "",
      LastUsedEpipen: lastUsedEpipen || "",
      AdditionalNotes: additionalNotes || "",
      ReviewedBy: reviewedByInput || ""
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key: string = 'allergies#'.concat(allergen);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newState), updateState);
    setShowSuccessMessage(true);
    handleClose();
  };

  const handleCloseClicked = () => {
    // Implement the logic
    handleClose();
  };


  return (
    <>
      {/* Heading and Subheading */}
      <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add allergy details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

         <DropdownField 
            label="Category" 
            value={category} 
            onChange={handleCategoryChange} 
            options={getFieldOptions('allergies', 'Category')} 
            required 
            width="100%"
          />

        <InputField label="Allergen" value={allergen} onChange={handleAllergenChange} required />

        <DropdownField 
            label="Allergen Type" 
            value={allergenType} 
            onChange={handleAllergenTypeChange}
            options={getFieldOptions('allergies', 'Allergen_Type')} 
            required 
            width="100%"
          />

        {/* Start Date and End Date in one row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <DateField 
            label="Start Date" 
            value={startDateValue} 
            onChange={handleStartDateChange} 
            required 
            width="48%"
          />
          <DateField 
            label="End Date" 
            value={endDateValue} 
            onChange={handleEndDateChange} 
            required 
            width="48%"
          />
        </Box>

        <DropdownField 
            label="Do you carry an Epipen?" 
            value={carryEpipen} 
            onChange={handleCarryEpipenChange} 
            options={getFieldOptions('allergies', 'Carry_Epipen')} 
            required 
            width="100%"
          />

        <DropdownField 
            label="Have you ever used an Epipen?" 
            value={usedEpipen} 
            onChange={handleUsedEpipenChange} 
            options={getFieldOptions('allergies', 'Used_Epipen')} 
            required 
            width="100%"
            sx={{
              opacity: carryEpipen === "No" ? 0.5 : 1,
              pointerEvents: carryEpipen === "No" ? 'none' : 'auto',
            }}  
          />

          <DateField 
            label="When was the last time you used an Epipen?"
            value={lastUsedEpipen}
            onChange={handleLastUsedEpipenChange} 
            required 
            width="100%"
            sx={{
              opacity: carryEpipen === "No" ? 0.5 : usedEpipen === "No" ? 0.5 : 1,
              pointerEvents: carryEpipen === "No" ? 'none' : usedEpipen === "No" ? "none" : 'auto',
            }}  
          />

          <DropdownField 
            label="Reaction" 
            value={reaction} 
            onChange={handleReactionChange} 
            options={getFieldOptions('allergies', 'Reaction')} 
            required 
            width="100%"  
          />

        <InputField label="Additional Notes" value={additionalNotes} onChange={handleAdditionalNotesChange} required />

        <InputField label="Reviewed By" value={reviewedByInput} onChange={handleReviewedByChange} required />

        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Save
        </Button>
      </Box>
    </>
  );
}









// ======================================================================PAST MEDICAL HX BIN==================================================================================================

export function PastMedicalHxBinInActive({Bin}: any) {
  return (
    <>
  <Stack direction="column" justifyContent="space-between">
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
    <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
      Disease
    </Typography>
    <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
      Specification
    </Typography>
  </Stack>
  <Stack direction="row" alignItems="flex-start" sx={{mr: 1}}>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Date of Onset    
  </Typography>
  </Stack>
  </Stack>
  </Stack>
  </>
  )

}


function PastMedicalHxLayout({ Bin, onStateChange, setItemsDisplay, setShowSuccessMessage }: { Bin: MedsInActiveProps, onStateChange: (key: string, value: string, updateState?: any) => void, setItemsDisplay: any, setShowSuccessMessage: any }): JSX.Element {
  const keys = Object.keys(Bin) as Array<keyof typeof Bin>; 
  let values = Object.values(Bin);
  const DateOfOnset = Bin[keys[2]];
  // console.log(`Keys are: ${keys}`)

  const [OnsetDate, setOnsetDate] = useState<any>(DateOfOnset);
  const [DateClicked, setDateClicked] = useState(false);
  const [TagsClicked, setTagsClicked] = useState<boolean>(false);
   
  let incomplete = false;

  for (let i = 0; i < values.length; i++) {
    if (values[i] === "" || values[i] === undefined) {
      incomplete = true;
      break;
    }
  }

  const toggleDateClicked = () => setDateClicked(!DateClicked);

  const handleDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnsetDate(event.target.value);
  }

  const toggleTags = () => setTagsClicked(!TagsClicked);

return (
  <>
  <Stack direction="column" justifyContent="space-between">
  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
    <div onClick={toggleTags} style={{ cursor: 'pointer'}}>
    <Stack key="Bin" direction="row" alignItems="center" spacing={1}>
      {/* <Typography component="div" variant="h6"  sx={{ color: 'Black', mr: 1 }}>
        {CheckTitle({Bin, keys, index: 0})}
      </Typography>
      <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
        {CheckTitle({Bin, keys, index: 1})}
      </Typography> */}
    </Stack>
    </div>
  <Stack direction="row" alignItems="flex-start" sx={{mr: 1}}>
  <div onClick={toggleDateClicked} style={{ cursor: 'pointer'}}>
  <Typography component="span" sx={{ color: 'gray', mr: 1 }}>
    Onset: {OnsetDate}  
  </Typography>
  </div>
  </Stack>
  </Stack>
  </Stack>

  {TagsClicked ? (
    <TagsLayout Bin={Bin} setItemsDisplay={setItemsDisplay} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />
  ) : (
    <></>
  )}

  {DateClicked ? (
    <DateField 
      label="Please select a date of Onset" 
      value={OnsetDate} 
      onChange={handleDateChanged} 
      required 
      width="95%"
    />
  ) : (
    <></>
 )}
  </>
)
}

function OrgansDropdown({ Bin }: any) {
  const keys = Object.keys(Bin);
  const [selectedOrgans, setSelectedOrgans] = useState<string[]>(["Affected Organs"]);

  const organOptions = ['Heart', 'Kidneys', 'Brain', 'Eyes', 'Liver', 'Feet'];

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSelectedOrgans(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div style={{
      padding: '5px 10px',
      borderRadius: '5px',
      marginTop: '10px',
      maxHeight: '40px',  // Adjusted maxHeight to 40px
      minWidth: '100px',
    }}>
      <FormControl 
        fullWidth 
        sx={{
          height: '40px',  // Set height to 40px
          backgroundColor: '#E1E7EC',
        }}>
        <Select
          labelId="organs-select-label"
          id="organs-select"
          multiple
          value={selectedOrgans || "Affected Organs"}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          sx={{
            height: '40px',  // Set height of the dropdown
            border: 'none',  // Remove the border
            '.MuiOutlinedInput-notchedOutline': { border: 0 },  // Remove default border
            padding: '0 10px',  // Adjust padding to fit the height
            backgroundColor: '#fff',  // Set background color to white
            '&:hover': {
              border: 'none',  // Ensure no borders on hover
            }
          }}
        >
          {organOptions.map((organ) => (
            <MenuItem key={organ} value={organ}>
              <Checkbox checked={selectedOrgans.indexOf(organ) > -1} />
              <ListItemText primary={organ} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


function TagsLayout({ Bin, setItemsDisplay, onStateChange, setShowSuccessMessage }: any) {
  const keys = Object.keys(Bin);
  const PositiveTags = Bin[keys[3]];
  const NegativeTags = Bin[keys[4]];
  const NeutralTags = Bin[keys[5]];

  // State to track the hovered tag and its type (positive, negative, neutral)
  const [hoveredTag, setHoveredTag] = useState<{ tag: string, type: string } | null>(null);
  const [AddNewTag, setAddNewTag] = useState<boolean>(false);

  return (
    <div style={{ position: 'relative', padding: '10px', width: '100%' }}>
      {/* Tags container */}
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', backgroundColor: '#F8F9FA', border: '1px solid #CCD4DB', padding: '20px' }}>
        {/* Positive Tags */}
        {PositiveTags.map((tag: string, index: number) => (
          <div
            key={`positive-${index}`}
            style={{
              backgroundColor: '#CCF1DE',
              padding: '5px 10px',
              borderRadius: '5px',
              color: '',
            }}
            onMouseEnter={() => setHoveredTag({ tag, type: 'positive' })}
            onMouseLeave={() => setHoveredTag(null)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={PositiveTagIcon} style={{ marginRight: 4, marginBottom: 0, width: 13, height: 13 }} />
              {tag}
            </Stack>
          </div>
        ))}

        {/* Negative Tags */}
        {NegativeTags.map((tag: string, index: number) => (
          <div
            key={`negative-${index}`}
            style={{
              backgroundColor: '#FCD9D9',
              padding: '5px 10px',
              borderRadius: '5px',
              color: '',
            }}
            onMouseEnter={() => setHoveredTag({ tag, type: 'negative' })}
            onMouseLeave={() => setHoveredTag(null)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <img src={NegativeTagIcon} style={{ marginRight: 4, marginBottom: 0, width: 13, height: 13 }} />
              {tag}
            </Stack>
          </div>
        ))}

        {/* Neutral Tags */}
        {NeutralTags.map((tag: string, index: number) => (
          <div
            key={`neutral-${index}`}
            style={{
              backgroundColor: '#CDE7F6',
              padding: '5px 10px',
              borderRadius: '5px',
              color: '',
            }}
            onMouseEnter={() => setHoveredTag({ tag, type: 'neutral' })}
            onMouseLeave={() => setHoveredTag(null)}
          >
            {tag}
          </div>
        ))}

        {/* Organs Affected: */}
      <div>
      <OrgansDropdown Bin={Bin} />
      </div>

      <div
        style={{
          backgroundColor: '#E1E7EC',
          padding: '5px 50px',
          borderRadius: '5px',
          color: '',
          cursor: 'pointer',
        }}

        onClick={() => setAddNewTag(true)}
      >
        + 
      </div>
      

      </div>

      

      {/* Conditionally render TagKeywordsLayout beneath the hovered tag */}
      {hoveredTag && (
        <div style={{ marginTop: '20px' }}>
          <TagKeywordsLayout Bin={Bin} Tag={hoveredTag.tag} />
        </div>
      )}

      {/* Conditionally render PastMedicalAddNewTagLayout */}
      {AddNewTag && (
        <PastMedicalAddNewTagLayout Bin={Bin} updateState={setItemsDisplay} handleClose={() => setAddNewTag(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />
      )}
    </div>
  );
}


function TagKeywordsLayout({ Bin, Tag }: any) {
  const keys = Object.keys(Bin);
  const AllKeywords = Bin[keys[6]];
  const TagKeywords = AllKeywords[Tag] || [];
  console.log(`TagKeywords: `, TagKeywords, "allKeywords: ", AllKeywords);

  return (
    <div style={{ position: 'relative', padding: '5px', width: '33%' }}>
      {/* Arrow pointing upwards */}
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderBottom: '10px solid #CCD4DB',
          position: 'absolute',
          top: 0,
          left: 20,
        }}
      />

      {/* Tags container */}
      <div
        style={{
          display: 'flex',
          gap: '3px',
          flexWrap: 'wrap',
          backgroundColor: '#F8F9FA',
          border: '1px solid #CCD4DB',
          padding: '5px',
        }}
      >
        {TagKeywords.length > 0 ? (
          <>
          <Typography component="span" sx={{ color: 'black', fontSize: 14 }}>
            Keywords:
          </Typography>
          {TagKeywords.map((keyword: string, index: number) => (
            <div
              key={`keyword-${index}`}
              style={{
                padding: '1px 2px',
                borderRadius: '5px',
                color: 'black',
              }}
            >
              {keyword}
            </div>
          ))}
          </>
        ) : (
          <div>No keywords found</div>
        )}
      </div>
    </div>
  );
}


function PastMedicalAddNewTagLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any }) {

  const [TagName, setTagName] = useState<string>("");
  const [Keywords, setKeywords] = useState<any>();
  const [color, setColor] = useState<string>("Blue");

  const keys = Object.keys(Bin);

  const [DiseaseName, setDiseaseName] = useState<any>(Bin[keys[0]]);
  const [Specification, setSpecification] = useState<any>(Bin[keys[1]]);
  const [DateOfOnset, setDateOfOnset] = useState<any>(Bin[keys[2]]);
  const [PositiveTags, setPositiveTags] = useState<any>(Bin[keys[3]]);
  const [NegativeTags, setNegativeTags] = useState<any>(Bin[keys[4]]);
  const [NeutralTags, setNeutralTags] = useState<any>(Bin[keys[5]]);
  const [TagKeywords, setTagKeywords] = useState<any>(Bin[keys[6]]);
  const [OrgansAffected, setOrgansAffected] = useState<any>(Bin[keys[7]]);




  const handleTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    setColor(event.target.value);
  };

  const handleSave = () => {

    if (color === "#CCF1DE") {
      setPositiveTags([...PositiveTags, TagName]);
    } else if (color === "#FCD9D9") {
      setNegativeTags([...NegativeTags, TagName]);
    } else {
      setNeutralTags([...NeutralTags, TagName]);
    }

    const newTagKeywords = {
      ...TagKeywords,
      [TagName]: Keywords.split(',').map((keyword: string) => keyword.trim()),
    };


    const newState = {
      Disease: DiseaseName || "",
      Specification: Specification || "",
      Onset: DateOfOnset || "",
      Positive_Tags: PositiveTags,
      Negative_Tags: NegativeTags,
      Neutral_Tags: NeutralTags,
      Tag_Keywords: newTagKeywords,
      Organs_Affected: OrgansAffected,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key: string = 'pastMedicalHx#'.concat(DiseaseName);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newState), updateState);
    setShowSuccessMessage(true);
    handleClose();
  }

  const handleCloseClicked = () => {
    handleClose();
  }

  return (
    <>
       {/* Heading and Subheading */}
       <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add Tag details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

        <Stack direction="row" justifyContent="space-between">
        <div style={{ width: '70%' }}>
        <InputField label="Tag Name" value={TagName} onChange={handleTagNameChange} required />
        </div>

        <DropdownField 
            label="Color" 
            value={color} 
            onChange={handleColorChange}
            options={getFieldOptions('pastMedicalHx', 'TagColors')} 
            required 
            width="20%"
          />
        
        </Stack>

          <InputField label="Keywords (comma-separated)" value={Keywords} onChange={handleKeywordsChange} required />

        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Add Tag
        </Button>
      </Box>


    </>
  )
 
}


function PastMedicalAddNewConditionLayout({ Bin, updateState, handleClose, onStateChange, setShowSuccessMessage }: { Bin: any, updateState: any, handleClose: () => void;   onStateChange: (key: string, value: string, updateState?: any) => void; setShowSuccessMessage?: any }) {

  const [DiseaseName, setDiseaseName] = useState<string>("");
  const [DateOfOnset, setDateOfOnset] = useState<any>();

  const handleDiseaseNameChange = (event: SelectChangeEvent<string>) => {
    setDiseaseName(event.target.value);
  };

  const handleDateOfOnsetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfOnset(event.target.value);
  };

  const handleSave = () => {
    const newState = {
      Disease: DiseaseName || "",
      Specification: "",
      Onset: DateOfOnset || "",
      Positive_Tags: [],
      Negative_Tags: [],
      Neutral_Tags: [],
      Tag_Keywords: {},
      Organs_Affected: [],
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key: string = 'pastMedicalHx#'.concat(DiseaseName);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange(key, JSON.stringify(newState), updateState);
    setShowSuccessMessage(true);
    handleClose();
  }

  const handleCloseClicked = () => {
    handleClose();
  }

  return (
    <>
       {/* Heading and Subheading */}
       <Box sx={{ marginBottom: '0px' }}>
        <h2 style={{ margin: '0', fontSize: '13px', color: '#6c757d', fontWeight: 'normal' }}>Please add Disease details</h2>
      </Box>

      {/* Grey Box Container */}
      <Box sx={{ backgroundColor: '#F8F9FA', padding: '9px', borderRadius: '8px', border: '1px solid #D3D3D3', position: 'relative', paddingTop: '25px'}}>
        {/* Close Button */}
        <IconButton onClick={handleCloseClicked} sx={{ position: 'absolute', top: '8px', right: '8px', color: 'red', padding: '0', '& .MuiSvgIcon-root': { fontSize: '24px', }, }}>
          <CloseIcon />
        </IconButton>

         <DropdownField 
            label="Disease Name" 
            value={DiseaseName} 
            onChange={handleDiseaseNameChange} 
            options={getFieldOptions('pastMedicalHx', 'Disease')} 
            required 
            width="100%"
          />

        
          <DateField 
            label="Date of Onset"
            value={DateOfOnset}
            onChange={handleDateOfOnsetChange}
            required 
            width="100%"
          />

        
        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{
            borderRadius: '8px',
            marginTop: '16px',
            width: '100px', 
            height: '35px',
            marginLeft: 'auto', 
            display: 'block' 
          }}
        >
          Save
        </Button>
      </Box>


    </>
  )
}



export function PastMedicalHxBinActive({ Bin, onStateChange, DeleteItemFromBin }: any) {
  console.log(`[TESTING PastMedicalHx BIN] Bin in pmhBinActive: `, Bin);
  let BinArray = Array.isArray(Bin) ? Bin : [Bin];

  const currentPhysician: any = useSelector(
    (state: RootState) => state.currentUser.currentUser?.givenName
  ); 

  const [expanded, setExpanded] = useState(false);
  const [AddNew, setAddNew] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  let topItems = expanded ? BinArray : BinArray.slice(0, 3);

  const [ItemsDisplay, setItemsDisplay] = useState(topItems);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  let UpdatedBin = true;

  const [delName, setDelName] = useState("");

  useEffect(() => {
    setItemsDisplay(topItems);
  }, [expanded]);


  const handleCancel = () => {
    setDelName("");
  }

  const handleDeleteConfirm = () => {
    console.log("Delete confirmed, now remove it from list");
    handleCancel();
  };

  const toggleAddNewOn = () => {
    setAddNew(true);
    UpdatedBin = false;
  }

  console.log("ItemsDisplay (in pmhactive):", ItemsDisplay);

  return (
    <>
    <Stack spacing={2} position="relative">
      {ItemsDisplay.map((item: any, index: number) => (
        <PastMedicalHxLayout key={index} Bin={item}  onStateChange={onStateChange} setItemsDisplay={setItemsDisplay} setShowSuccessMessage={setShowSuccessMessage} />
      ))}
        { AddNew ? (

        <PastMedicalAddNewConditionLayout Bin={{}} updateState={setItemsDisplay} handleClose={() => setAddNew(false)} onStateChange={onStateChange} setShowSuccessMessage={setShowSuccessMessage} />

        ) : (
          <>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {Bin.length >= 3 && (
          <Button
            onClick={toggleExpanded}
            sx={{
              backgroundColor: '#E9E9E9',
              color: '#404B5A',
              width: '30%',
              paddingTop: '6px',
              paddingBottom: '6px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
            }}
          >
            {expanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        <Button
        onClick={toggleAddNewOn}
          sx={{
            backgroundColor: '#E9F5FC',
            color: '#404B5A',
            width: '50%',
            paddingTop: '6px',
            paddingBottom: '6px',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          + Add Medical Condition
        </Button>
      </div>
        </>
      

        )}

      {showSuccessMessage && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , position: "absolute",  top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, width: '100%', }} >
        <SuccessMessage msg="Allergy" setShowSuccessMessage={setShowSuccessMessage} />
        </div>
      )}

    </Stack>
    </>
  );
}


export default SocialHxBinInActive;

