import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { type Theme, useTheme, SxProps, Button, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// this is a wrapper around the material card component that
// applies specific app appearance.

// 'secondary' cards use the primary color. this is intentional.
function chooseSecondaryColor(
  theme: Theme,
  fade: "none" | "faded" | "veryFaded" | undefined
) {
  switch (fade) {
    case "faded":
      return theme.palette.primaryFaded.main;
    case "veryFaded":
      return theme.palette.primaryVeryFaded.main;
    default:
      return theme.palette.primary.main;
  }
}
export interface BinInfoCardProps {
  title: string | React.ReactNode;
  role: "primary" | "secondary";
  titleAlign?: "center" | "left";
  inTextAlign?: "center" | "left";
  fade?: "none" | "faded" | "veryFaded";
  sx?: SxProps<Theme>;
  allowEdit?: boolean;
  onEditStateChanged?: (b: boolean) => void;
  inEditMode?: boolean;
  allowComparison?: boolean;
  inCompMode?: boolean;
  onCompModeChanged?: (b: boolean) => void;
  children: React.ReactNode;
  isActive?: boolean;
  titleColor?: string;
  titleVarient?: string;
}
function BinInfoCard({
  title,
  role,
  titleAlign,
  inTextAlign,
  fade,
  sx,
  allowEdit = false,
  inEditMode = false,
  onEditStateChanged = () => {},
  allowComparison = false,
  inCompMode = false,
  onCompModeChanged = () => {},
  children,
  isActive = true,
  titleColor = "black",
  titleVarient = "h4",
}: BinInfoCardProps) {
  const theme = useTheme();
  const intl = useIntl();
  // yes these are intentionally swapped
  const backgroundColor1 =
    role === "primary"
      ? "#AAD8F2"
      : chooseSecondaryColor(theme, fade);
    
    //change bakground color again on basis of isActive, if false, make it gray
    const backgroundColor2 = isActive ? backgroundColor1 : theme.palette.grey[700];
    //if role is primary, then keep it as is
    const backgroundColor = role === "primary" ? backgroundColor1 : backgroundColor2;

  const toggleEdit = useCallback(() => {
    onEditStateChanged(!inEditMode);
  }, [inEditMode, onEditStateChanged]);

  const toggleMode = useCallback(() => {
    onCompModeChanged(!inCompMode);
  }, [inCompMode, onCompModeChanged]);

  const buttonColor = useTheme().palette.themeText.main;


  const action = useMemo(() => {
    if (allowEdit) {
     return (
      <MoreHorizIcon style={{ color: "white", fontSize: 40}} />
     )
    }
    return undefined;
  }, [
    allowEdit,
  ]);

  const textAlign = titleAlign || (role === "primary" ? "center" : "left");
  const fullSx = { width: "100%",  borderRadius: "25px",    ...sx };
  const titleVarient2 = titleVarient || "h4";
  return (
    <Card sx={fullSx}>
      <CardHeader
        titleTypographyProps={{ variant: "h3", textAlign: textAlign }}
        title={title}
        action={action}
        sx={{
          backgroundColor: "#AAD8F2",
          paddingY: "6px",
          minHeight: "3.2em",
          color: titleColor,
        }}
      />
      <CardContent
        sx={{
          overflowY: "scroll",
          textAlign: inTextAlign || "center",
        }}
      >
        {" "}
        {children}
      </CardContent>
    </Card>
  );
}

export default BinInfoCard;
