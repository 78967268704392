import { forwardRef } from "react";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import AmchurCard from "../../components/AmchurCard";
import { TranscriptionSection } from "../../hooks/useTranscriptionState";
import TranscriptionSectionView from "./TranscriptionSectionView";

export interface TranscriptionPanelProps {
  transcription: TranscriptionSection[];
  transcriptionHeight: string;
  transcriptionListHeight: string;
  transcriptionInProgress: boolean;
}
function TranscriptionPanelInternal(
  {
    transcription,
    transcriptionHeight,
    transcriptionListHeight,
  }: TranscriptionPanelProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  return (
    <AmchurCard
      title="Transcription"
      role="secondary"
      sx={{ flexGrow: "4"}}
    >
      <List
        sx={{ overflowY: "scroll", maxHeight: transcriptionListHeight }}
        ref={ref}
      >
        <TransitionGroup>
          {transcription.map((section, ix) => (
            <Collapse key={ix}>
              <TranscriptionSectionView
                key={ix}
                keywords={[]}
                section={section}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
    </AmchurCard>
  );
}

const TranscriptionPanel = forwardRef(TranscriptionPanelInternal);

export default TranscriptionPanel;
