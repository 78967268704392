import { DateTime } from "luxon";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { SiteNoteModel } from "../../models/modelTypes";

interface OneSiteNoteViewProps {
  siteNote: SiteNoteModel;
  // isAdmin: boolean;
  // isSelected: boolean;
  onClick: (id: string) => void;
  // onChangeSelectState: (f: boolean) => void;
}

function OneSiteNoteView({
  siteNote,
  // isSelected,
  // isAdmin,
  onClick,
}: // onChangeSelectState,
OneSiteNoteViewProps) {
  const createdAt = siteNote.createdAt;
  //const onChange = checkboxChangeHandler(onChangeSelectState);

  return (
    <TableRow key={siteNote.noteId}>
      <TableCell>
        <Button onClick={() => onClick(siteNote.noteId || "")}>
          {siteNote.noteId}
        </Button>
      </TableCell>
      <TableCell>{siteNote.filename}</TableCell>
      <TableCell>{siteNote.user}</TableCell>

      <TableCell>{createdAt.toLocaleString(DateTime.DATETIME_MED)}</TableCell>
    </TableRow>
  );
}
export default OneSiteNoteView;
