import { useRef, useState, type ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { AppDispatch } from "../../state/store";
import { uploadSiteNotesMiddleware } from "../../middleware/siteNoteMiddleware";
import useCurrentRole from "../../hooks/useCurrentRole";
import SiteNotesList from "./SiteNotesList";

interface GeneralFileUploaderProps {
  onSubmit: (f: File[]) => Promise<boolean>;
}
function GeneralFileUploader({ onSubmit }: GeneralFileUploaderProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      setFiles(Array.from(target.files));
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  const resetForm = () => {
    formRef?.current?.reset();
  };
  const submitFiles = () => {
    setIsUploading(true);
    onSubmit(files)
      .then((ok) => {
        setIsUploading(false);
        if (ok) {
          resetForm();
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Stack direction="row" alignItems="center">
      <form ref={formRef}>
        <input type="file" name="image" onChange={onChange} multiple />
      </form>

      <Button onClick={submitFiles} disabled={isUploading}>
        Upload
      </Button>
      {isUploading && <CircularProgress />}
    </Stack>
  );
}
function SiteNotesRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const onSubmit = (files: File[]) => {
    return dispatch(uploadSiteNotesMiddleware({ files })).then((result) => {
      return uploadSiteNotesMiddleware.fulfilled.match(result);
    });
  };

  return <SiteNotesUI onSubmit={onSubmit} />;
}

interface SiteNotesUIProps {
  onSubmit: (f: File[]) => Promise<boolean>;
}

function SiteNotesUI({ onSubmit }: SiteNotesUIProps) {
  const role = useCurrentRole();
  const isSu = role === "su";
  return (
    <Stack>
      <Typography>Site Notes here</Typography>
      <GeneralFileUploader onSubmit={onSubmit} />
      {isSu && <SiteNotesList />}
    </Stack>
  );
}

export default SiteNotesRoute;
