import { useNavigate } from "react-router-dom";
import { waitForMedAssist } from "shared-utils";
import {
  InitiateVisitInput,
  PatientId,
  useInitiateVisitMutation,
} from "../graphql/generated";
import { useServiceBag } from "../services/ServiceBag";

function useStartVisit() {
  const { envService } = useServiceBag();
  const [initiateVisit] = useInitiateVisitMutation();
  const navigate = useNavigate();

  const startTheVisit = (clinicId: string, patientId: PatientId) => {
    const visitInfo: InitiateVisitInput = {
      visitId: envService.randomUUID(),
      clinicId,
      ...patientId,
      createdAt: envService.currentTime(),
    };
    // maybe do this after triage?
    waitForMedAssist(visitInfo);
    initiateVisit({ visitInfo })
      .then((result) => {
        console.log(`result: ${JSON.stringify(result)}`);
        // maybe we should navigate to triage? we'll get to that later.
        navigate("/greeter-dashboard");
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };

  return { startTheVisit };
}

export default useStartVisit;
