import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

export interface RecordingListState {
  selectedDate?: DateTime;
}

const initialState: RecordingListState = {};

interface SetRecordingSelectedDateParams {
  date?: DateTime;
}

const recordingListSlice = createSlice({
  name: "RecordingList",
  initialState,
  reducers: {
    setRecordingSelectedDate: (
      state,
      action: PayloadAction<SetRecordingSelectedDateParams>
    ) => {
      state.selectedDate = action.payload.date;
    },
  },
});

export default recordingListSlice.reducer;
export const { setRecordingSelectedDate } = recordingListSlice.actions;
