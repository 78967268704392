import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AssessmentState {
  notes?: string;
  revisedNotes?: string;
  assessment: Record<string, string>;
  diagnoses?: string[];
  symptoms: string[];
}

const initialState: AssessmentState = {
  notes: "", //"You have a cold",
  revisedNotes: "",
  assessment: {},
  diagnoses: [],
  symptoms: [],
};

interface SetAssessmentParams {
  notes?: string;
  assessment: Record<string, string>;
  diagnoses?: string[];
  symptoms?: string[];
}

const assessmentSlice = createSlice({
  name: "Assessment",
  initialState,
  reducers: {
    setAssessment: (state, action: PayloadAction<SetAssessmentParams>) => {
      console.log(
        `Set assessment is called payload ${JSON.stringify(action.payload)}`
      );
      state.notes = action.payload.notes; // original notes
      state.revisedNotes = action.payload.notes; // revised Notes
      state.assessment = action.payload.assessment;
      state.diagnoses = action.payload.diagnoses || [];
      state.symptoms = action.payload.symptoms || [];
      console.log(`state is now ${JSON.stringify(state)}`);
    },
    updateNotes: (state, action: PayloadAction<string>) => {
      state.revisedNotes = action.payload;
    },
  },
});

export default assessmentSlice.reducer;
export const { setAssessment, updateNotes } = assessmentSlice.actions;
