import * as R from "ramda";
import { type Variant } from "@mui/material/styles/createTypography";
import { type Theme, ThemeProvider, createTheme } from "@mui/material/styles";

const overrideTheme = (variant: Variant, theme: Partial<Theme>) =>
  createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          variant: variant,
        },
      },
    },
  });

interface DefaultTypeOverrideProps {
  variant: Variant;
  children: React.ReactNode;
}

function DefaultTypeOverride({ variant, children }: DefaultTypeOverrideProps) {
  const themeCreator = R.curry(overrideTheme)(variant);
  return <ThemeProvider theme={themeCreator}>{children}</ThemeProvider>;
}

export default DefaultTypeOverride;
