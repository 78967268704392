export const createRecordingMutation = /* GraphQL */ `
  mutation createRecording($recording: RecordingInput!) {
    createRecording(recording: $recording) {
      uploadUrl
    }
  }
`;

export const createRecordingArchiveMutation = /* GraphQL */ `
  mutation createRecordingArchive($archiveId: String!, $index: Int!) {
    createRecordingArchive(archiveId: $archiveId, index: $index)
  }
`;
