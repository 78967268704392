import * as R from "ramda";
import { DateTime } from "luxon";
import { limitToRecordingStatuses } from "shared-utils";
import {
  type RecordingXformQuery,
  type SiteNotesXformQuery,
} from "../models/modelTypes";
import { api, RecordingsByCriteriaQuery, SiteNotesQuery } from "./generated";

// We enhance the generated endpoints here for two purposes:
// Tags allow the RTK-query code to automatically clear & refresh the cache when a mutation
// invalidates the query.
// Transformations allow us to replace the ISO strings with luxon DateTime objects, and also
// to add types to generic strings that should really have a limited set of values.

function transformDateProp(prop: string) {
  return R.compose(
    R.unless(R.isNil, DateTime.fromISO.bind(null)),
    R.prop(prop)
  );
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
function transformQueryDate<T>(objectName: string, dateProp: string, query: T) {
  const queryDictionary = query as Record<string, Record<string, string>[]>;
  const transformSpecifiedProp = transformDateProp(dateProp);
  const objects = queryDictionary[objectName].map((obj) => {
    return { ...obj, [dateProp]: transformSpecifiedProp(obj) };
  });
  return { ...query, [objectName]: objects };
}

function transformQueryEnum<T>(
  objectName: string,
  enumProp: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conversion: (v: any) => any,
  query: T
) {
  const queryDictionary = query as Record<string, Record<string, string>[]>;
  const transformSpecifiedProp = R.compose(
    R.unless(R.isNil, conversion),
    R.prop(enumProp)
  );

  const objects = queryDictionary[objectName].map((obj) => {
    return { ...obj, [enumProp]: transformSpecifiedProp(obj) };
  });
  return { ...query, [objectName]: objects };
}

const transformSiteNotes = R.curry(transformQueryDate<SiteNotesQuery>)(
  "siteNotes",
  "createdAt"
) as (q: SiteNotesQuery) => SiteNotesXformQuery;

const transformRecordingsDate = R.curry(
  transformQueryDate<RecordingsByCriteriaQuery>
)("recordings", "recordedAt") as (
  q: RecordingsByCriteriaQuery
) => RecordingXformQuery;

const transformRecordingsEnum = R.curry(
  transformQueryEnum<RecordingsByCriteriaQuery>
)("recordings", "status", limitToRecordingStatuses);

// exported only for tests
export const transformRecordings = R.compose(
  transformRecordingsEnum,
  transformRecordingsDate
) as unknown as (q: RecordingsByCriteriaQuery) => RecordingXformQuery;

export const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Recording", "SiteNote", "Visit"],
  endpoints: {
    recordingsByCriteria: {
      providesTags: ["Recording"],
      transformResponse: transformRecordings,
    },
    updateRecordingStatus: {
      invalidatesTags: ["Recording"],
    },
    updateRecordingNotes: {
      invalidatesTags: ["Recording"],
    },
    siteNotes: {
      providesTags: ["SiteNote"],
      transformResponse: transformSiteNotes,
    },
    visits: {
      providesTags: ["Visit"],
    },
    initiateVisit: {
      invalidatesTags: ["Visit"],
    },
    updateVisitProgress: {
      invalidatesTags: ["Visit"],
    },
  },
});

// type corresponding to the tags added via addTagTypes above.
type TagTypeList = Parameters<typeof enhancedApi.util.invalidateTags>[0];
export type TagType = TagTypeList[number];

export default enhancedApi;
