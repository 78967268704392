import { useMemo, useState } from "react";
import GridLayout from "react-grid-layout";

function updatedPanel(
  panel: GridLayout.Layout,
  collapsed: string[]
): GridLayout.Layout {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const isCollapsed = collapsed.includes(panel.i);
  const height = isCollapsed ? 1 : panel.h;
  const maxHeight = isCollapsed ? 1 : panel.maxH;
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  return { ...panel, h: height, maxH: maxHeight };
}

function useCollapsiblePanels(initialLayout: GridLayout.Layout[]) {
  //const [layout, setLayout] = useState(initialLayout);
  const [collapsed, setCollapsed] = useState<string[]>([]);

  const isCollapsedState = (panelId: string) => collapsed.includes(panelId);

  const setCollapsedState = (panelId: string, isCollapsed: boolean) => {
    if (isCollapsed) {
      if (!isCollapsedState(panelId)) {
        setCollapsed([...collapsed, panelId]);
      }
    } else {
      setCollapsed(collapsed.filter((id) => id !== panelId));
    }
  };

  const layout: GridLayout.Layout[] = useMemo(() => {
    return initialLayout.map((panel) => updatedPanel(panel, collapsed));
  }, [collapsed, initialLayout]);

  return { layout, setCollapsedState, isCollapsedState };
}

export default useCollapsiblePanels;
