import AmchurDialog from "../../components/AmchurDialog";

interface CreatePatientSuccessDialogProps {
  open: boolean;
  onClose: () => void;
}
function CreatePatientSuccessDialog({
  open,
  onClose,
}: CreatePatientSuccessDialogProps) {
  return (
    <AmchurDialog
      isOpen={open}
      onCloseRequested={onClose}
      titleColor="success"
      title="Create Patient"
      contentText="This patient is now ready to use the Android app."
    />
  );
}

export default CreatePatientSuccessDialog;
