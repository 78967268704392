import * as R from "ramda";
import {
  createNewRecording,
  zipDownloadsFrom,
} from "../domain/recordingDomain";
import { type RecordingKeys } from "../graphql/generated";
import { type AppDispatch } from "../state/store";
import { createApiThunk } from "./amchurMiddleware";

export interface UploadRecordingParams {
  recordingDataURI: string;
  patientId: string;
  patientIdType: string;
}
export const uploadRecordingMiddleware = createApiThunk(
  "recording/uploadRecording",
  async (params: UploadRecordingParams, serviceBag, getState) => {
    const currentUser = getState().currentUser?.currentUser?.email;
    if (currentUser == null) {
      return false;
    }
    return await createNewRecording(
      params.recordingDataURI,
      currentUser,
      params.patientId,
      params.patientIdType,
      serviceBag
    );
  }
);

export const getRecordingDownloadUrlMiddleware = createApiThunk(
  "recording/downloadRecording",
  async (recordingId: string, serviceBag, getState) => {
    return serviceBag.apiService.getRecordingDownloadUrl({ id: recordingId });
  }
);

export const getRecordingDownloadUrlsMiddleware = createApiThunk(
  "recording/downloadRecordings",
  async (recordingIds: string[], serviceBag, getState) => {
    const urls = await serviceBag.apiService.getRecordingDownloadUrls({
      ids: recordingIds,
    });
    return zipDownloadsFrom(recordingIds, urls || [], serviceBag.fileService);
  }
);

const isSameRecording = R.curry((a: RecordingKeys, b: RecordingKeys) => {
  return a.user === b.user && a.recordingId === b.recordingId;
});

const isSameRecordingAs = (r: RecordingKeys) =>
  isSameRecording(r) as (r: RecordingKeys) => boolean;

const withoutRecording = (
  recording: RecordingKeys,
  recordings: RecordingKeys[]
) => {
  return R.reject(isSameRecordingAs(recording), recordings);
};

export const deleteRecordingMiddleware = createApiThunk(
  "recording/deleteRecording",
  async (recordingKeys: RecordingKeys, serviceBag, getState, thunkApi) => {
    await serviceBag.apiService.deleteRecording(recordingKeys);
    const updater = serviceBag.apiService.updateCachedQueryData(
      "recordingsByCriteria",
      { criteria: { since: "2021-10-01" } },
      (state) => {
        state.recordings = withoutRecording(
          recordingKeys,
          state.recordings as RecordingKeys[]
        );
      }
    );
    const dispatch = thunkApi.dispatch as AppDispatch;
    dispatch(updater);
  }
);

export interface UpdateRecordingStatusParams extends RecordingKeys {
  status?: string;
  assignedTo?: string;
}
