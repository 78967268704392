import { type ApiService } from "../graphql/apiService";
import { type Patient } from "../graphql/generated";
import { type FileService } from "../services/fileService";

export async function uploadPatientPhoto(
  patient: Patient,
  imageBase64: string,
  apiService: ApiService,
  fileService: FileService
): Promise<void> {
  const s3Url = await apiService.getPatientUploadUrl({
    id: patient.patientId,
    patientIdType: patient.patientIdType,
  });
  return fileService.uploadBase64ToS3(s3Url, imageBase64).then();
}
