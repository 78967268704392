import HeartIcon from "@mui/icons-material/Favorite";
import IconOnCircle, { type IconProps } from "./IconOnCircle";

function VitalsIcon() {
  const heartIcon = (props: IconProps) => <HeartIcon {...props} />;

  return <IconOnCircle icon={heartIcon} />;
}

export default VitalsIcon;
