/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query getMedicineData($input: [MedicineInput!]!) {\n    getMedicineData(input: $input) {\n      meds {\n        Trade_Name\n        Salt\n        Number\n        Unit\n        Route\n        Frequency\n      }\n    }\n  }\n": types.GetMedicineDataDocument,
    "\n  query allHealthOrgs {\n    allHealthOrgs {\n      orgId\n      name\n    }\n  }\n": types.AllHealthOrgsDocument,
    "\n  query audioResults($clinicId: String!, $visitId: String!) {\n    visit(clinicId: $clinicId, visitId: $visitId) {\n      transcription\n      questions\n      originalNotes\n      patientId\n      patientIdType\n      diagnoses\n      assessment\n      allergies\n      drugs\n      hopi\n      meds\n      obgyn\n      screenings\n      socialHx\n      surgicalHx\n      vaccine\n      pastMedicalHx\n      a_p\n      familyHx\n      symptoms\n    }\n  }\n": types.AudioResultsDocument,
    "\n    mutation benchmarkSaving($benchmark: BenchmarkInput!) {\n        createBenchmark(benchmark: $benchmark) {\n        benchmarkId\n        socialHx\n          familyHx\n          meds\n          allergies\n          habits\n        }\n    }\n    ": types.BenchmarkSavingDocument,
    "\n  query clinicById($clinicId: String!) {\n    clinic(clinicId: $clinicId) {\n      clinicId\n      name\n      country\n      province\n      city\n    }\n  }\n": types.ClinicByIdDocument,
    "\n  query clinicPatients($clinicId: String!) {\n    clinic(clinicId: $clinicId) {\n      patients {\n        patientId\n        patientIdType\n        firstName\n        lastName\n      }\n    }\n  }\n": types.ClinicPatientsDocument,
    "\n  query clinicsInHealthOrg($orgId: String!) {\n    clinicsInHealthOrg(orgId: $orgId) {\n      clinicId\n      name\n    }\n  }\n": types.ClinicsInHealthOrgDocument,
    "\n  mutation createPatient($patient: PatientCreation!) {\n    createPatient(patient: $patient) {\n      patientId\n      patientIdType\n    }\n  }\n": types.CreatePatientDocument,
    "\n  mutation createRecording($recording: RecordingInput!) {\n    createRecording(recording: $recording) {\n      uploadUrl\n    }\n  }\n": types.CreateRecordingDocument,
    "\n  mutation createRecordingArchive($archiveId: String!, $index: Int!) {\n    createRecordingArchive(archiveId: $archiveId, index: $index)\n  }\n": types.CreateRecordingArchiveDocument,
    "\n  mutation createSiteNote($siteNote: SiteNoteInput!) {\n    createSiteNote(note: $siteNote) {\n      uploadUrl\n    }\n  }\n": types.CreateSiteNoteDocument,
    "\n  mutation createWebUser($webUser: WebUserCreation!) {\n    createWebUser(webUser: $webUser) {\n      email\n    }\n  }\n": types.CreateWebUserDocument,
    "\n  mutation deleteRecording($recordingKeys: RecordingKeys!) {\n    deleteRecording(recording: $recordingKeys)\n  }\n": types.DeleteRecordingDocument,
    "\n  mutation generateAssessment(\n    $clinicId: String!\n    $visitId: String\n    $sessionId: String!\n  ) {\n    generateAssessment(\n      clinicId: $clinicId\n      visitId: $visitId\n      sessionId: $sessionId\n    )\n  }\n": types.GenerateAssessmentDocument,
    "\n  mutation initiateVisit($visitInfo: InitiateVisitInput!) {\n    initiateVisit(visitInfo: $visitInfo) {\n      patientId\n      patientIdType\n      visitId\n      urgency\n      waitingFor\n      assocPhysician\n    }\n  }\n": types.InitiateVisitDocument,
    "\n  query patient($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      patientId\n      patientIdType\n      firstName\n      lastName\n      birthday\n      patientId\n      patientIdType\n    }\n  }\n": types.PatientDocument,
    "\n  query patientUploadPhoto($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      photoUploadUrl\n    }\n  }\n": types.PatientUploadPhotoDocument,
    "\n  query patientsById($ids: [PatientId!]!) {\n    patientsById(ids: $ids) {\n      firstName\n      lastName\n      patientId\n      patientIdType\n    }\n  }\n": types.PatientsByIdDocument,
    "\n  query patientMedication($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      medications {\n        medicationId\n        name\n        dosage\n        status\n        schedule\n      }\n    }\n  }\n": types.PatientMedicationDocument,
    "\n  mutation patientUpdate($patient: PatientUpdate!) {\n    updatePatient(patient: $patient) {\n      firstName\n      lastName\n    }\n  }\n": types.PatientUpdateDocument,
    "\n  query recordingsByCriteria($criteria: RecordingQueryInput!) {\n    recordings(criteria: $criteria) {\n      recordingId\n      recordedAt\n      user\n      patientId\n      status\n      assignedTo\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n": types.RecordingsByCriteriaDocument,
    "\n  query recording($id: String!) {\n    recording(id: $id) {\n      downloadUrl\n    }\n  }\n": types.RecordingDocument,
    "\n  query recordingsById($ids: [String]!) {\n    recordingsById(ids: $ids) {\n      downloadUrl\n    }\n  }\n": types.RecordingsByIdDocument,
    "\n  query recordingsByPrefixId($id: String!) {\n    recordingsByPrefixId(id: $id) {\n      downloadUrl\n    }\n  }\n": types.RecordingsByPrefixIdDocument,
    "\n  query siteNotes($criteria: SiteNoteQueryInput!) {\n    siteNotes(criteria: $criteria) {\n      filename\n      noteId\n      user\n      createdAt\n    }\n  }\n": types.SiteNotesDocument,
    "\n  query siteNotesById($ids: [String]!) {\n    siteNotesById(ids: $ids) {\n      downloadUrl\n    }\n  }\n": types.SiteNotesByIdDocument,
    "\n  mutation startTranscriptionSession($visitId: String) {\n    startTranscriptionSession(visitId: $visitId)\n  }\n": types.StartTranscriptionSessionDocument,
    "\n  mutation transcribeAudio(\n    $clinicId: String\n    $visitId: String\n    $sessionId: String!\n    $traceId: String!\n    $audio: String!\n  ) {\n    transcribeAudio(\n      clinicId: $clinicId\n      visitId: $visitId\n      sessionId: $sessionId\n      traceId: $traceId\n      audio: $audio\n    )\n  }\n": types.TranscribeAudioDocument,
    "\n  mutation updateRecordingStatus(\n    $keys: RecordingKeys!\n    $update: RecordingStatusUpdate!\n  ) {\n    updateRecordingStatus(recording: $keys, status: $update) {\n      status\n      assignedTo\n    }\n  }\n": types.UpdateRecordingStatusDocument,
    "\n  mutation updateRecordingNotes(\n    $keys: RecordingKeys!\n    $update: RecordingNotesUpdate!\n  ) {\n    updateRecordingNotes(recording: $keys, updates: $update) {\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n": types.UpdateRecordingNotesDocument,
    "\n  mutation updateVisitAssessment(\n    $visitId: String!\n    $clinicId: String!\n    $assessment: AssessmentInput!\n  ) {\n    updateVisitAssessment(\n      visitId: $visitId\n      clinicId: $clinicId\n      assessmentDetails: $assessment\n    ) {\n      patientId\n      patientIdType\n      assessment\n    }\n  }\n": types.UpdateVisitAssessmentDocument,
    "\n  mutation updateVisitDetails(\n    $patientId: String!\n    $patientIdType: String!\n    $createdAt: Date!\n    $visitId: String!\n    $clinicId: String!\n    $visit: VisitInput!\n    $vitals: VitalsInput!\n  ) {\n    updateVisitDetails(\n      visitId: $visitId\n      clinicId: $clinicId\n      visitDetails: $visit\n    ) {\n      patientId\n      patientIdType\n      visitId\n      chiefComplaint\n      noteToPhysician\n      reasonForComing\n    }\n    createVitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventId: $visitId\n      eventType: \"V\"\n      createdAt: $createdAt\n      vitals: $vitals\n    ) {\n      patientId\n      patientIdType\n      eventId\n      eventType\n      height\n      weight\n      temperature\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      systolicBloodPressure\n      diastolicBloodPressure\n      frontPainPoints\n      backPainPoints\n    }\n  }\n": types.UpdateVisitDetailsDocument,
    "\n  mutation updateVisitNotes(\n    $visitId: String!\n    $clinicId: String!\n    $visit: VisitInput!\n  ) {\n    updateVisitDetails(\n      visitId: $visitId\n      clinicId: $clinicId\n      visitDetails: $visit\n    ) {\n      patientId\n      patientIdType\n    }\n  }\n": types.UpdateVisitNotesDocument,
    "\n  mutation updateVisitProgress(\n    $clinicId: String!\n    $visitId: String!\n    $visitProgress: VisitProgress!\n  ) {\n    updateVisitProgress(\n      clinicId: $clinicId\n      visitId: $visitId\n      visitProgress: $visitProgress\n    ) {\n      patientId\n      patientIdType\n      visitId\n      urgency\n      waitingFor\n      assocPhysician\n    }\n  }\n": types.UpdateVisitProgressDocument,
    "\n  query userForSession {\n    userForSession {\n      email\n      givenName\n      familyName\n      role\n      externalId\n      clinics {\n        clinicId\n        orgId\n        name\n        country\n        province\n        city\n        active\n      }\n      healthOrg {\n        orgId\n        trainingOnly\n        demo\n      }\n    }\n  }\n": types.UserForSessionDocument,
    "\n  query usersForRole($role: String!) {\n    usersForRole(role: $role)\n  }\n": types.UsersForRoleDocument,
    "\n  query visitById($clinicId: String!, $visitId: String!) {\n    visit(visitId: $visitId, clinicId: $clinicId) {\n      clinicId\n      patientId\n      visitId\n      chiefComplaint\n      reasonForComing\n      noteToPhysician\n    }\n  }\n": types.VisitByIdDocument,
    "\n  query visits($criteria: VisitCriteria!) {\n    visits(criteria: $criteria) {\n      visitId\n      clinicId\n      transcription\n      originalNotes\n      patientId\n      patientIdType\n      allergies\n      drugs\n      hopi\n      meds\n      obgyn\n      screenings\n      socialHx\n      surgicalHx\n      vaccine\n      pastMedicalHx\n      a_p\n      familyHx\n      createdAt\n      assocPhysician\n      physician {\n        email\n        familyName\n        givenName\n      }\n    }\n  }\n": types.VisitsDocument,
    "\n  query vitals(\n    $patientId: String!\n    $patientIdType: String!\n    $eventType: String!\n    $eventId: String!\n  ) {\n    vitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventType: $eventType\n      eventId: $eventId\n    ) {\n      height\n      weight\n      temperature\n      systolicBloodPressure\n      diastolicBloodPressure\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      eventId\n      eventType\n      patientId\n      patientIdType\n      frontPainPoints\n      backPainPoints\n    }\n  }\n": types.VitalsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMedicineData($input: [MedicineInput!]!) {\n    getMedicineData(input: $input) {\n      meds {\n        Trade_Name\n        Salt\n        Number\n        Unit\n        Route\n        Frequency\n      }\n    }\n  }\n"): (typeof documents)["\n  query getMedicineData($input: [MedicineInput!]!) {\n    getMedicineData(input: $input) {\n      meds {\n        Trade_Name\n        Salt\n        Number\n        Unit\n        Route\n        Frequency\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query allHealthOrgs {\n    allHealthOrgs {\n      orgId\n      name\n    }\n  }\n"): (typeof documents)["\n  query allHealthOrgs {\n    allHealthOrgs {\n      orgId\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query audioResults($clinicId: String!, $visitId: String!) {\n    visit(clinicId: $clinicId, visitId: $visitId) {\n      transcription\n      questions\n      originalNotes\n      patientId\n      patientIdType\n      diagnoses\n      assessment\n      allergies\n      drugs\n      hopi\n      meds\n      obgyn\n      screenings\n      socialHx\n      surgicalHx\n      vaccine\n      pastMedicalHx\n      a_p\n      familyHx\n      symptoms\n    }\n  }\n"): (typeof documents)["\n  query audioResults($clinicId: String!, $visitId: String!) {\n    visit(clinicId: $clinicId, visitId: $visitId) {\n      transcription\n      questions\n      originalNotes\n      patientId\n      patientIdType\n      diagnoses\n      assessment\n      allergies\n      drugs\n      hopi\n      meds\n      obgyn\n      screenings\n      socialHx\n      surgicalHx\n      vaccine\n      pastMedicalHx\n      a_p\n      familyHx\n      symptoms\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation benchmarkSaving($benchmark: BenchmarkInput!) {\n        createBenchmark(benchmark: $benchmark) {\n        benchmarkId\n        socialHx\n          familyHx\n          meds\n          allergies\n          habits\n        }\n    }\n    "): (typeof documents)["\n    mutation benchmarkSaving($benchmark: BenchmarkInput!) {\n        createBenchmark(benchmark: $benchmark) {\n        benchmarkId\n        socialHx\n          familyHx\n          meds\n          allergies\n          habits\n        }\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query clinicById($clinicId: String!) {\n    clinic(clinicId: $clinicId) {\n      clinicId\n      name\n      country\n      province\n      city\n    }\n  }\n"): (typeof documents)["\n  query clinicById($clinicId: String!) {\n    clinic(clinicId: $clinicId) {\n      clinicId\n      name\n      country\n      province\n      city\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query clinicPatients($clinicId: String!) {\n    clinic(clinicId: $clinicId) {\n      patients {\n        patientId\n        patientIdType\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  query clinicPatients($clinicId: String!) {\n    clinic(clinicId: $clinicId) {\n      patients {\n        patientId\n        patientIdType\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query clinicsInHealthOrg($orgId: String!) {\n    clinicsInHealthOrg(orgId: $orgId) {\n      clinicId\n      name\n    }\n  }\n"): (typeof documents)["\n  query clinicsInHealthOrg($orgId: String!) {\n    clinicsInHealthOrg(orgId: $orgId) {\n      clinicId\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createPatient($patient: PatientCreation!) {\n    createPatient(patient: $patient) {\n      patientId\n      patientIdType\n    }\n  }\n"): (typeof documents)["\n  mutation createPatient($patient: PatientCreation!) {\n    createPatient(patient: $patient) {\n      patientId\n      patientIdType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createRecording($recording: RecordingInput!) {\n    createRecording(recording: $recording) {\n      uploadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation createRecording($recording: RecordingInput!) {\n    createRecording(recording: $recording) {\n      uploadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createRecordingArchive($archiveId: String!, $index: Int!) {\n    createRecordingArchive(archiveId: $archiveId, index: $index)\n  }\n"): (typeof documents)["\n  mutation createRecordingArchive($archiveId: String!, $index: Int!) {\n    createRecordingArchive(archiveId: $archiveId, index: $index)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createSiteNote($siteNote: SiteNoteInput!) {\n    createSiteNote(note: $siteNote) {\n      uploadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation createSiteNote($siteNote: SiteNoteInput!) {\n    createSiteNote(note: $siteNote) {\n      uploadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWebUser($webUser: WebUserCreation!) {\n    createWebUser(webUser: $webUser) {\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation createWebUser($webUser: WebUserCreation!) {\n    createWebUser(webUser: $webUser) {\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteRecording($recordingKeys: RecordingKeys!) {\n    deleteRecording(recording: $recordingKeys)\n  }\n"): (typeof documents)["\n  mutation deleteRecording($recordingKeys: RecordingKeys!) {\n    deleteRecording(recording: $recordingKeys)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation generateAssessment(\n    $clinicId: String!\n    $visitId: String\n    $sessionId: String!\n  ) {\n    generateAssessment(\n      clinicId: $clinicId\n      visitId: $visitId\n      sessionId: $sessionId\n    )\n  }\n"): (typeof documents)["\n  mutation generateAssessment(\n    $clinicId: String!\n    $visitId: String\n    $sessionId: String!\n  ) {\n    generateAssessment(\n      clinicId: $clinicId\n      visitId: $visitId\n      sessionId: $sessionId\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation initiateVisit($visitInfo: InitiateVisitInput!) {\n    initiateVisit(visitInfo: $visitInfo) {\n      patientId\n      patientIdType\n      visitId\n      urgency\n      waitingFor\n      assocPhysician\n    }\n  }\n"): (typeof documents)["\n  mutation initiateVisit($visitInfo: InitiateVisitInput!) {\n    initiateVisit(visitInfo: $visitInfo) {\n      patientId\n      patientIdType\n      visitId\n      urgency\n      waitingFor\n      assocPhysician\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patient($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      patientId\n      patientIdType\n      firstName\n      lastName\n      birthday\n      patientId\n      patientIdType\n    }\n  }\n"): (typeof documents)["\n  query patient($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      patientId\n      patientIdType\n      firstName\n      lastName\n      birthday\n      patientId\n      patientIdType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patientUploadPhoto($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      photoUploadUrl\n    }\n  }\n"): (typeof documents)["\n  query patientUploadPhoto($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      photoUploadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patientsById($ids: [PatientId!]!) {\n    patientsById(ids: $ids) {\n      firstName\n      lastName\n      patientId\n      patientIdType\n    }\n  }\n"): (typeof documents)["\n  query patientsById($ids: [PatientId!]!) {\n    patientsById(ids: $ids) {\n      firstName\n      lastName\n      patientId\n      patientIdType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patientMedication($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      medications {\n        medicationId\n        name\n        dosage\n        status\n        schedule\n      }\n    }\n  }\n"): (typeof documents)["\n  query patientMedication($id: String!, $patientIdType: String!) {\n    patient(id: $id, patientIdType: $patientIdType) {\n      medications {\n        medicationId\n        name\n        dosage\n        status\n        schedule\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation patientUpdate($patient: PatientUpdate!) {\n    updatePatient(patient: $patient) {\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  mutation patientUpdate($patient: PatientUpdate!) {\n    updatePatient(patient: $patient) {\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recordingsByCriteria($criteria: RecordingQueryInput!) {\n    recordings(criteria: $criteria) {\n      recordingId\n      recordedAt\n      user\n      patientId\n      status\n      assignedTo\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"): (typeof documents)["\n  query recordingsByCriteria($criteria: RecordingQueryInput!) {\n    recordings(criteria: $criteria) {\n      recordingId\n      recordedAt\n      user\n      patientId\n      status\n      assignedTo\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recording($id: String!) {\n    recording(id: $id) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query recording($id: String!) {\n    recording(id: $id) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recordingsById($ids: [String]!) {\n    recordingsById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query recordingsById($ids: [String]!) {\n    recordingsById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recordingsByPrefixId($id: String!) {\n    recordingsByPrefixId(id: $id) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query recordingsByPrefixId($id: String!) {\n    recordingsByPrefixId(id: $id) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query siteNotes($criteria: SiteNoteQueryInput!) {\n    siteNotes(criteria: $criteria) {\n      filename\n      noteId\n      user\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query siteNotes($criteria: SiteNoteQueryInput!) {\n    siteNotes(criteria: $criteria) {\n      filename\n      noteId\n      user\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query siteNotesById($ids: [String]!) {\n    siteNotesById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  query siteNotesById($ids: [String]!) {\n    siteNotesById(ids: $ids) {\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation startTranscriptionSession($visitId: String) {\n    startTranscriptionSession(visitId: $visitId)\n  }\n"): (typeof documents)["\n  mutation startTranscriptionSession($visitId: String) {\n    startTranscriptionSession(visitId: $visitId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation transcribeAudio(\n    $clinicId: String\n    $visitId: String\n    $sessionId: String!\n    $traceId: String!\n    $audio: String!\n  ) {\n    transcribeAudio(\n      clinicId: $clinicId\n      visitId: $visitId\n      sessionId: $sessionId\n      traceId: $traceId\n      audio: $audio\n    )\n  }\n"): (typeof documents)["\n  mutation transcribeAudio(\n    $clinicId: String\n    $visitId: String\n    $sessionId: String!\n    $traceId: String!\n    $audio: String!\n  ) {\n    transcribeAudio(\n      clinicId: $clinicId\n      visitId: $visitId\n      sessionId: $sessionId\n      traceId: $traceId\n      audio: $audio\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateRecordingStatus(\n    $keys: RecordingKeys!\n    $update: RecordingStatusUpdate!\n  ) {\n    updateRecordingStatus(recording: $keys, status: $update) {\n      status\n      assignedTo\n    }\n  }\n"): (typeof documents)["\n  mutation updateRecordingStatus(\n    $keys: RecordingKeys!\n    $update: RecordingStatusUpdate!\n  ) {\n    updateRecordingStatus(recording: $keys, status: $update) {\n      status\n      assignedTo\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateRecordingNotes(\n    $keys: RecordingKeys!\n    $update: RecordingNotesUpdate!\n  ) {\n    updateRecordingNotes(recording: $keys, updates: $update) {\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"): (typeof documents)["\n  mutation updateRecordingNotes(\n    $keys: RecordingKeys!\n    $update: RecordingNotesUpdate!\n  ) {\n    updateRecordingNotes(recording: $keys, updates: $update) {\n      originalTranscription\n      correctedTranscription\n      altTranscription\n      notes\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateVisitAssessment(\n    $visitId: String!\n    $clinicId: String!\n    $assessment: AssessmentInput!\n  ) {\n    updateVisitAssessment(\n      visitId: $visitId\n      clinicId: $clinicId\n      assessmentDetails: $assessment\n    ) {\n      patientId\n      patientIdType\n      assessment\n    }\n  }\n"): (typeof documents)["\n  mutation updateVisitAssessment(\n    $visitId: String!\n    $clinicId: String!\n    $assessment: AssessmentInput!\n  ) {\n    updateVisitAssessment(\n      visitId: $visitId\n      clinicId: $clinicId\n      assessmentDetails: $assessment\n    ) {\n      patientId\n      patientIdType\n      assessment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateVisitDetails(\n    $patientId: String!\n    $patientIdType: String!\n    $createdAt: Date!\n    $visitId: String!\n    $clinicId: String!\n    $visit: VisitInput!\n    $vitals: VitalsInput!\n  ) {\n    updateVisitDetails(\n      visitId: $visitId\n      clinicId: $clinicId\n      visitDetails: $visit\n    ) {\n      patientId\n      patientIdType\n      visitId\n      chiefComplaint\n      noteToPhysician\n      reasonForComing\n    }\n    createVitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventId: $visitId\n      eventType: \"V\"\n      createdAt: $createdAt\n      vitals: $vitals\n    ) {\n      patientId\n      patientIdType\n      eventId\n      eventType\n      height\n      weight\n      temperature\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      systolicBloodPressure\n      diastolicBloodPressure\n      frontPainPoints\n      backPainPoints\n    }\n  }\n"): (typeof documents)["\n  mutation updateVisitDetails(\n    $patientId: String!\n    $patientIdType: String!\n    $createdAt: Date!\n    $visitId: String!\n    $clinicId: String!\n    $visit: VisitInput!\n    $vitals: VitalsInput!\n  ) {\n    updateVisitDetails(\n      visitId: $visitId\n      clinicId: $clinicId\n      visitDetails: $visit\n    ) {\n      patientId\n      patientIdType\n      visitId\n      chiefComplaint\n      noteToPhysician\n      reasonForComing\n    }\n    createVitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventId: $visitId\n      eventType: \"V\"\n      createdAt: $createdAt\n      vitals: $vitals\n    ) {\n      patientId\n      patientIdType\n      eventId\n      eventType\n      height\n      weight\n      temperature\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      systolicBloodPressure\n      diastolicBloodPressure\n      frontPainPoints\n      backPainPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateVisitNotes(\n    $visitId: String!\n    $clinicId: String!\n    $visit: VisitInput!\n  ) {\n    updateVisitDetails(\n      visitId: $visitId\n      clinicId: $clinicId\n      visitDetails: $visit\n    ) {\n      patientId\n      patientIdType\n    }\n  }\n"): (typeof documents)["\n  mutation updateVisitNotes(\n    $visitId: String!\n    $clinicId: String!\n    $visit: VisitInput!\n  ) {\n    updateVisitDetails(\n      visitId: $visitId\n      clinicId: $clinicId\n      visitDetails: $visit\n    ) {\n      patientId\n      patientIdType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateVisitProgress(\n    $clinicId: String!\n    $visitId: String!\n    $visitProgress: VisitProgress!\n  ) {\n    updateVisitProgress(\n      clinicId: $clinicId\n      visitId: $visitId\n      visitProgress: $visitProgress\n    ) {\n      patientId\n      patientIdType\n      visitId\n      urgency\n      waitingFor\n      assocPhysician\n    }\n  }\n"): (typeof documents)["\n  mutation updateVisitProgress(\n    $clinicId: String!\n    $visitId: String!\n    $visitProgress: VisitProgress!\n  ) {\n    updateVisitProgress(\n      clinicId: $clinicId\n      visitId: $visitId\n      visitProgress: $visitProgress\n    ) {\n      patientId\n      patientIdType\n      visitId\n      urgency\n      waitingFor\n      assocPhysician\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userForSession {\n    userForSession {\n      email\n      givenName\n      familyName\n      role\n      externalId\n      clinics {\n        clinicId\n        orgId\n        name\n        country\n        province\n        city\n        active\n      }\n      healthOrg {\n        orgId\n        trainingOnly\n        demo\n      }\n    }\n  }\n"): (typeof documents)["\n  query userForSession {\n    userForSession {\n      email\n      givenName\n      familyName\n      role\n      externalId\n      clinics {\n        clinicId\n        orgId\n        name\n        country\n        province\n        city\n        active\n      }\n      healthOrg {\n        orgId\n        trainingOnly\n        demo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query usersForRole($role: String!) {\n    usersForRole(role: $role)\n  }\n"): (typeof documents)["\n  query usersForRole($role: String!) {\n    usersForRole(role: $role)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query visitById($clinicId: String!, $visitId: String!) {\n    visit(visitId: $visitId, clinicId: $clinicId) {\n      clinicId\n      patientId\n      visitId\n      chiefComplaint\n      reasonForComing\n      noteToPhysician\n    }\n  }\n"): (typeof documents)["\n  query visitById($clinicId: String!, $visitId: String!) {\n    visit(visitId: $visitId, clinicId: $clinicId) {\n      clinicId\n      patientId\n      visitId\n      chiefComplaint\n      reasonForComing\n      noteToPhysician\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query visits($criteria: VisitCriteria!) {\n    visits(criteria: $criteria) {\n      visitId\n      clinicId\n      transcription\n      originalNotes\n      patientId\n      patientIdType\n      allergies\n      drugs\n      hopi\n      meds\n      obgyn\n      screenings\n      socialHx\n      surgicalHx\n      vaccine\n      pastMedicalHx\n      a_p\n      familyHx\n      createdAt\n      assocPhysician\n      physician {\n        email\n        familyName\n        givenName\n      }\n    }\n  }\n"): (typeof documents)["\n  query visits($criteria: VisitCriteria!) {\n    visits(criteria: $criteria) {\n      visitId\n      clinicId\n      transcription\n      originalNotes\n      patientId\n      patientIdType\n      allergies\n      drugs\n      hopi\n      meds\n      obgyn\n      screenings\n      socialHx\n      surgicalHx\n      vaccine\n      pastMedicalHx\n      a_p\n      familyHx\n      createdAt\n      assocPhysician\n      physician {\n        email\n        familyName\n        givenName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query vitals(\n    $patientId: String!\n    $patientIdType: String!\n    $eventType: String!\n    $eventId: String!\n  ) {\n    vitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventType: $eventType\n      eventId: $eventId\n    ) {\n      height\n      weight\n      temperature\n      systolicBloodPressure\n      diastolicBloodPressure\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      eventId\n      eventType\n      patientId\n      patientIdType\n      frontPainPoints\n      backPainPoints\n    }\n  }\n"): (typeof documents)["\n  query vitals(\n    $patientId: String!\n    $patientIdType: String!\n    $eventType: String!\n    $eventId: String!\n  ) {\n    vitals(\n      patientId: $patientId\n      patientIdType: $patientIdType\n      eventType: $eventType\n      eventId: $eventId\n    ) {\n      height\n      weight\n      temperature\n      systolicBloodPressure\n      diastolicBloodPressure\n      heartRate\n      respiratoryRate\n      bloodOxygen\n      eventId\n      eventType\n      patientId\n      patientIdType\n      frontPainPoints\n      backPainPoints\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;