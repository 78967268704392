import React from "react";
import ImageUploading, { type ImageListType } from "react-images-uploading";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import { nullary } from "shared-utils";

export interface FileUploaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAction: (imageData: string) => any;
}

type ImageUploadingChildParams = Parameters<
  Parameters<typeof ImageUploading>[0]["children"]
>[0];

type DragParams = ImageUploadingChildParams["dragProps"];

interface ImageData {
  data_url: string;
}

interface SingleImageProps {
  image: ImageData;
  onImageUpdate: () => void;
  onImageRemove: () => void;
}

function SingleImage({
  image,
  onImageUpdate,
  onImageRemove,
}: SingleImageProps) {
  return (
    <Box position="relative" maxWidth="200px">
      <img src={image.data_url} alt="" width="200px" />
      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        <IconButton onClick={onImageUpdate}>
          <UploadIcon />
        </IconButton>
        <Button onClick={onImageRemove}>
          <DeleteIcon />
        </Button>
      </Box>
    </Box>
  );
}

interface EmptyImageProps {
  dragProps: DragParams;
  isDragging: boolean;
  onImageUpload: () => void;
}
function EmptyImage({ dragProps, isDragging, onImageUpload }: EmptyImageProps) {
  const borderColor = isDragging ? "red" : null;
  return (
    <Box
      maxWidth="200px"
      border="dashed 2px"
      borderColor={borderColor}
      {...dragProps}
    >
      <Button onClick={onImageUpload} sx={{ width: "100%" }}>
        <FormattedMessage id="general.addImage" />
      </Button>
    </Box>
  );
}

function UploadUi({
  imageList,
  onImageUpload,
  onImageUpdate,
  onImageRemove,
  isDragging,
  dragProps,
}: ImageUploadingChildParams) {
  const removeImage = nullary(onImageRemove, 0);
  const updateImage = nullary(onImageUpdate, 0);
  const imageComponent =
    imageList.length > 0 ? (
      <SingleImage
        image={imageList[0] as ImageData}
        onImageRemove={removeImage}
        onImageUpdate={updateImage}
      />
    ) : (
      <EmptyImage
        isDragging={isDragging}
        dragProps={dragProps}
        onImageUpload={onImageUpload}
      />
    );

  return imageComponent;
}

// UI allowing the user to edit an image. It could be extended to handle
// multiple images as well, when needed.
// Not sure of the point of filename, might delete.
function FileUploader({ createAction }: FileUploaderProps) {
  const [images, setImages] = React.useState<ImageListType>([]);
  const dispatch = useDispatch();

  const onChange = (imageList: ImageListType, addUpdateIndex: number[]) => {
    // write the new images to S3, unless it was a delete.
    // (we should handle that as well...)
    if (imageList.length >= images.length) {
      addUpdateIndex.forEach((index) => {
        console.log("dispatch an action!");
        dispatch(createAction((imageList[index] as ImageData).data_url));
      });
    }
    setImages(imageList);
  };

  return (
    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
      {(props: ImageUploadingChildParams) => <UploadUi {...props} />}
    </ImageUploading>
  );
}

export default FileUploader;
