import { useState } from "react";

function useMultiSelect<T = string>(initial: T[] = []) {
  const [selectionList, setSelectionList] = useState<T[]>(initial);

  const isSelected = (id: T) => selectionList.includes(id);

  const selectionWithChange = (selectionId: T, selected: boolean) => {
    if (selected) {
      if (isSelected(selectionId)) {
        return selectionList;
      } else {
        return [...selectionList, selectionId];
      }
    } else {
      return selectionList.filter((id) => id !== selectionId);
    }
  };

  const changeSelection = (selectionId: T, selected: boolean) =>
    setSelectionList(selectionWithChange(selectionId, selected));

  const toggleSelection = (selectionId: T) =>
    changeSelection(selectionId, !isSelected(selectionId));

  return {
    selectionList,
    isSelected,
    changeSelection,
    toggleSelection,
    setSelectionList,
  };
}

export default useMultiSelect;
