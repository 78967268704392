import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../state/store";
import { setAuthToken } from "../state/authSlice";
import { RootState } from "../state/reducers";

function useOAuthToken() {
  const dispatch = useDispatch<AppDispatch>();
  const [hasToken, setHasToken] = useState(false);
  const { loggingOut } = useSelector((state: RootState) => state.auth);

  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    const getUserToken = async () => {
      try {
        let accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "openid profile email read:current_user",
          },
        });
        if (accessToken.length > 0 && accessToken[0] === '"') {
          accessToken = JSON.parse(accessToken);
        }
        dispatch(setAuthToken(accessToken));
      } catch (e) {
        console.log(`token failed ${JSON.stringify(e)}`);
      }
    };
    if (!loggingOut) {
      if (token) {
        setHasToken(true);
      } else if (isAuthenticated) {
        void getUserToken();
      }
    }
  }, [
    isAuthenticated,
    dispatch,
    getAccessTokenSilently,
    user?.sub,
    hasToken,
    token,
    loggingOut,
  ]);

  return { isAuthenticated, hasToken };
}

export default useOAuthToken;
