import { useMemo } from "react";
import { useIntl } from "react-intl";
import PasswordStrengthBar, {
  PasswordFeedback,
} from "react-password-strength-bar";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { AutoTooltip } from "shared-ui";

import { textChangeHandler } from "../utils/eventUtils";
import { type DefinePasswordState } from "../hooks/useDefinePasswordState";

// this component creates a set of controls combining:
// a field to enter a password
// a confirmation field
// an indicator of the quality of the password
//
// it also shows tooltips indicating password quality feedback
// it should be used in conjunction with useDefinePasswordState.

interface DefinePasswordFieldSetProps {
  username?: string;
  definePasswordState: DefinePasswordState;
}

function DefinePasswordFieldSet({
  username,
  definePasswordState,
}: DefinePasswordFieldSetProps) {
  return (
    <DefinePasswordFieldSetUi
      username={username}
      password={definePasswordState.password}
      setPassword={definePasswordState.setPassword}
      passwordConfirmation={definePasswordState.passwordConfirmation}
      setPasswordConfirmation={definePasswordState.setPasswordConfirmation}
      passwordMessage={definePasswordState.passwordMessage}
      unmatchedMessage={definePasswordState.unmatchedMessage}
      onPasswordFeedback={definePasswordState.onPasswordFeedback}
    />
  );
}

type FeedbackCallback = (ok: boolean, msg?: string) => void;

interface DefinePasswordFieldSetUiProps {
  username?: string;
  password: string;
  setPassword: (password: string) => void;
  passwordConfirmation: string;
  setPasswordConfirmation: (passwordConfirmation: string) => void;
  passwordMessage: string | undefined;
  unmatchedMessage: string | undefined;
  onPasswordFeedback: FeedbackCallback;
}

function DefinePasswordFieldSetUi({
  username,
  password,
  setPassword,
  passwordConfirmation,
  setPasswordConfirmation,
  passwordMessage,
  unmatchedMessage,
  onPasswordFeedback,
}: DefinePasswordFieldSetUiProps) {
  const intl = useIntl();

  return (
    <>
      <AutoTooltip message={passwordMessage}>
        <TextField
          required
          inputProps={{ "data-testid": "password" }}
          label={intl.formatMessage({ id: "createUser.password" })}
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={textChangeHandler(setPassword)}
        />
      </AutoTooltip>

      <AutoTooltip message={unmatchedMessage}>
        <TextField
          required
          inputProps={{ "data-testid": "confirm-password" }}
          id="confirm-password"
          label={intl.formatMessage({ id: "createUser.confirmPassword" })}
          type="password"
          autoComplete="current-password"
          value={passwordConfirmation}
          onChange={textChangeHandler(setPasswordConfirmation)}
        />
      </AutoTooltip>
      <DhaniaPasswordStrengthBar
        username={username || ""}
        password={password}
        onFeedback={onPasswordFeedback}
      />
    </>
  );
}

interface DhaniaPasswordStrengthBarProps {
  username: string;
  password: string;
  onFeedback: FeedbackCallback;
}

function DhaniaPasswordStrengthBar({
  username,
  password,
  onFeedback,
}: DhaniaPasswordStrengthBarProps) {
  const intl = useIntl();
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;

  const scoreWords = useMemo(() => {
    return ["weak", "weak", "okay", "good", "strong"].map((key) =>
      intl.formatMessage({ id: `passwordStrength.${key}` })
    );
  }, [intl]);

  const onScoreChanged = (score: number, feedback: PasswordFeedback) => {
    const { warning } = feedback;
    const msg = warning && warning.length > 0 ? warning : undefined;
    // we allow passwords that are at least 'ok', though hopefully users do better.
    onFeedback(score >= 2, msg);
  };

  return (
    <PasswordStrengthBar
      password={password}
      barColors={["#ddd", "#ef4836", "#f6b44d", primary, secondary]}
      shortScoreWord={intl.formatMessage({ id: "passwordStrength.short" })}
      scoreWords={scoreWords}
      userInputs={[username, "sehat", "sanjha"]} // taboo words to be avoided.
      onChangeScore={onScoreChanged}
    />
  );
}
export default DefinePasswordFieldSet;
