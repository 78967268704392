import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type HealthOrg, type WebUser } from "../gql/graphql";
import { logoutAction } from "./authSlice";

export interface CurrentUserState {
  currentUser?: WebUser;
  currentHealthOrg?: HealthOrg;
}

const initialState: CurrentUserState = {};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<WebUser | undefined>) => {
      state.currentUser = action.payload;
    },
    setCurrentHealthOrg: (
      state,
      action: PayloadAction<HealthOrg | undefined>
    ) => {
      state.currentHealthOrg = action.payload;
    },
  },
  extraReducers: (builder) => {
    // logout is defined in authSlice
    builder.addCase(logoutAction, (state) => {
      state.currentUser = undefined;
      state.currentHealthOrg = undefined;
    });
  },
});

export default currentUserSlice.reducer;
export const { setCurrentUser, setCurrentHealthOrg } = currentUserSlice.actions;
