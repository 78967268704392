import TextField from "@mui/material/TextField";
import AmchurFileUploader from "../components/AmchurFileUploader";
import {
  usePatientQuery,
  usePatientUpdateMutation,
} from "../graphql/generated";
import { enterKeyHandler } from "../utils/eventUtils";

export default function PatientInfo() {
  const { data, isLoading } = usePatientQuery({
    id: "2023",
    patientIdType: "PKCNI",
  });
  const [updatePatient] = usePatientUpdateMutation();
  const patient = data?.patient;

  const handleEnter = enterKeyHandler((value) => {
    if (!!patient) {
      console.log("handleEnter", value);

      const updatedPatient = {
        ...patient,
        lastName: value,
      };
      // remove void if we need do use a .then()
      void updatePatient({ patient: updatedPatient });
    }
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!!patient) {
    return (
      <>
        <div>{patient.firstName || ""}</div>
        <div>{patient.lastName || ""}</div>

        <TextField defaultValue={patient.lastName} onKeyDown={handleEnter} />
        <AmchurFileUploader />
      </>
    );
  }
  return <div>Not found</div>;
}
