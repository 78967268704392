import { useIntl } from "react-intl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { textChangeHandler } from "../../utils/eventUtils";
import { type VitalsState } from "../../hooks/useVitalsState";
import VitalsIcon from "../../components/VitalsIcon";

const stdProps = { size: "small" } as const;

interface VitalsFormViewProps {
  vitalsState: VitalsState;
}

function VitalsFormView({ vitalsState }: VitalsFormViewProps) {
  const intl = useIntl();

  return (
    <Stack spacing="1em">
      <VitalTextField
        title={intl.formatMessage({ id: "demographics.height" })}
        unit={intl.formatMessage({ id: "demographics.height-unit" })}
        value={vitalsState.heightString}
        setValue={vitalsState.setHeight}
        testId="height"
      />
      <VitalTextField
        title={intl.formatMessage({ id: "demographics.weight" })}
        unit={intl.formatMessage({ id: "demographics.weight-unit" })}
        value={vitalsState.weightString}
        setValue={vitalsState.setWeight}
        testId="weight"
      />
      <VitalTextField
        title={intl.formatMessage({ id: "demographics.temperature" })}
        unit={intl.formatMessage({ id: "demographics.temperature-unit" })}
        value={vitalsState.tempString}
        setValue={vitalsState.setTemp}
        testId="temp"
      />

      <VitalTextField
        title={intl.formatMessage({ id: "demographics.heartRate" })}
        unit={intl.formatMessage({ id: "demographics.heartRate-unit" })}
        value={vitalsState.heartRateString}
        setValue={vitalsState.setHeartRate}
        testId="heartRate"
      />

      <VitalTextField
        title={intl.formatMessage({ id: "demographics.respiratoryRate" })}
        unit={intl.formatMessage({ id: "demographics.respiratoryRate-unit" })}
        value={vitalsState.respiratoryRateString}
        setValue={vitalsState.setRespiratoryRate}
        testId="respiratoryRate"
      />

      <VitalTextField
        title={intl.formatMessage({ id: "demographics.bloodOxygen" })}
        unit={intl.formatMessage({ id: "demographics.bloodOxygen-unit" })}
        value={vitalsState.bloodOxygenString}
        setValue={vitalsState.setBloodOxygen}
        testId="bloodOxygen"
      />
      <VitalField
        unit={intl.formatMessage({ id: "demographics.blood-pressure-unit" })}
      >
        <VitalTextField
          title={intl.formatMessage({ id: "demographics.systolic" })}
          unit={intl.formatMessage({ id: "demographics.blood-pressure-unit" })}
          testId="systolic"
          value={vitalsState.systolicString}
          setValue={vitalsState.setSystolic}
          showIcon={false}
        />
        <VitalTextField
          title={intl.formatMessage({ id: "demographics.diastolic" })}
          unit={intl.formatMessage({ id: "demographics.blood-pressure-unit" })}
          testId="diastolic"
          value={vitalsState.diastolicString}
          setValue={vitalsState.setDiastolic}
          showIcon={false}
        />
      </VitalField>
    </Stack>
  );
}

interface VitalFieldProps {
  unit: string;
  children: React.ReactNode;
  showIcon?: boolean;
}

function VitalField({ unit, children, showIcon = true }: VitalFieldProps) {
  return (
    <Stack direction="row" alignItems="flex-end">
      {showIcon && <VitalsIcon />}
      {showIcon && <Box width="6px" />}
      <Stack direction="row" spacing={2}>
        {children}
      </Stack>
    </Stack>
  );
}

interface VitalTextFieldProps {
  title: string;
  unit: string;
  value: string;
  testId: string;
  setValue: (value: string) => void;
  showIcon?: boolean;
}

function VitalTextField({
  title,
  unit,
  value,
  setValue,
  testId,
  showIcon = true,
}: VitalTextFieldProps) {
  return (
    <VitalField unit={unit} showIcon={showIcon}>
      <TextField
        sx={{ width: showIcon ? "20em" : "18em" }}
        label={title}
        inputProps={{ "data-testid": testId }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="caption">{unit}</Typography>
            </InputAdornment>
          ),
        }}
        value={value}
        onChange={textChangeHandler(setValue)}
        {...stdProps}
      />
    </VitalField>
  );
}

export default VitalsFormView;
