import * as R from "ramda";
import { useState } from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { nullaryBool } from "shared-utils";
import { Recording } from "../../graphql/generated";
import { toFormattedCnic } from "../../domain/cnicUtils";
import AmchurDialog from "../../components/AmchurDialog";
import { type RecordingKeys } from "../../graphql/generated";
import { checkboxChangeHandler } from "../../utils/eventUtils";
import { type RecordingModel } from "../../models/modelTypes";
import { useDeleteRecordingMutation } from "../../hooks/apiHooks/recordingMutationHooks";

interface ConfirmDeleteDialogProps {
  isOpen: boolean;
  onDelete: () => Promise<boolean>;
  onCloseRequested: () => void;
}

function ConfirmDeleteDialog({
  isOpen,
  onDelete,
  onCloseRequested,
}: ConfirmDeleteDialogProps) {
  return (
    <AmchurDialog
      isOpen={isOpen}
      onCloseRequested={onCloseRequested}
      onOk={onDelete}
      title="Delete Recording?"
      titleColor="error"
      contentText="Delete this recording? This can not be undone."
    />
  );
}

interface DeleteRecordingButtonProps {
  recording: Recording;
}

type RecordingToKeysProc = (r: Recording) => RecordingKeys;
const getRecordingKeys = R.pick(["recordingId", "user"]) as RecordingToKeysProc;

function DeleteRecordingButton({ recording }: DeleteRecordingButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, closeDialog] = nullaryBool(setIsOpen);
  const { deleteRecording } = useDeleteRecordingMutation();

  const keys = getRecordingKeys(recording);

  const deleteThisRecording = () => {
    return deleteRecording(keys)
      .then((ok) => true)
      .catch((e) => false);
  };

  return (
    <>
      <Button startIcon={<DeleteIcon />} onClick={openDialog} color="error" />
      <ConfirmDeleteDialog
        isOpen={isOpen}
        onCloseRequested={closeDialog}
        onDelete={deleteThisRecording}
      />
    </>
  );
}

interface OneRecordingViewProps {
  recording: RecordingModel;
  isAdmin: boolean;
  isSelected: boolean;
  onClick: (id: string) => void;
  onChangeSelectState: (f: boolean) => void;
}

function OneRecordingView({
  recording,
  isSelected,
  isAdmin,
  onClick,
  onChangeSelectState,
}: OneRecordingViewProps) {
  const recordedAt = recording.recordedAt;
  const onChange = checkboxChangeHandler(onChangeSelectState);

  return (
    <TableRow key={recording.recordingId}>
      <TableCell>
        <Checkbox checked={isSelected} onChange={onChange} />
      </TableCell>
      <TableCell>
        {recording.recordingId == null || (
          <Link to={`/recording/${recording.recordingId}`}>
            {/* <Button onClick={() => onClick(recording.recordingId || "")}> */}
            {recording.recordingId}
          </Link>
        )}
      </TableCell>
      <TableCell>{recordedAt.toLocaleString(DateTime.DATETIME_MED)}</TableCell>
      {isAdmin && (
        <TableCell>{toFormattedCnic(recording.patientId || "")}</TableCell>
      )}
      <TableCell>{recording.user}</TableCell>
      {isAdmin && (
        <TableCell>
          <DeleteRecordingButton recording={recording} />
        </TableCell>
      )}
    </TableRow>
  );
}

export default OneRecordingView;
