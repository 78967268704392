import { useCallback, useEffect } from "react";

// takes a scrollable element and make sure it always scrolls to the bottom
// when the size changes (e.g. when more output appears)
function useScrollWithOutput(scrollRef: React.RefObject<HTMLUListElement>) {
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current != null) {
      scrollRef.current.scroll({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [scrollRef]);

  useEffect(scrollToBottom, [
    scrollToBottom,
    scrollRef?.current?.scrollHeight || 0,
  ]);
}

export default useScrollWithOutput;
