import { createTheme } from "@mui/material/styles";
import typography from "./typography";

const theme = createTheme({
  typography,
  palette: {
    mode: "light",
    primary: {
      main: "#00658F",
      light: "#35c4ff",
    },
    secondary: {
      main: "#006D37",
    },
    error: {
      main: "#BB1800",
    },
    secondaryContainer: {
      main: "#75FDA1",
    },
    primaryContainer: {
      main: "#C7E7FF",
    },
    primaryFaded: {
      main: "#0099d8",
    },
    primaryVeryFaded: {
      main: "#35c4ff",
    },
    inverseText: { main: "#f3f3f3" },

    neutralBackground: {
      main: "#9c9c9c",
    },
    hoverBackground: {
      main: "#008AD8",
    },
    themeText: {
      main: "#ffffff",
      light: "#b3b1b1",
      dark: "#858585",
    },
    infoBox: {
      main: "#949494",
    },
  },
});

theme.components = {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: "#C7E7FF",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        backgroundColor: "#333333",
        color: "#ffffff",
        textAlign: "left",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        //backgroundColor: "#eeeeee",
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        //backgroundColor: "#eeeeee",
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        //backgroundColor: "#eeeeee",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        // don't force capitalization
        textTransform: "none",
        borderRadius: 8,
      },
    },
  },
};

export default theme;
