import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import SimpleHeader from "./SimpleHeader";

function ClinicHeader() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <SimpleHeader>
      <SimpleHeader.Menu>
        <IconButton onClick={openMenu}>
          <MenuIcon />
        </IconButton>

        <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
          <MenuItem onClick={() => navigate("/greeter-dashboard")}>
            <ListItemText>Greeter</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/medassist-dashboard")}>
            <ListItemText>Med Assistant</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/physician-dashboard")}>
            <ListItemText>Physician</ListItemText>
          </MenuItem>
        </Menu>
      </SimpleHeader.Menu>
    </SimpleHeader>
  );
}

export default ClinicHeader;
