import { useSelector } from "react-redux";
import { UniversalOrgId } from "shared-utils";
import CreateUserForm from "../../components/CreateUserForm";
import { RootState } from "../../state/reducers";

function CreateUserRoute() {
  // from this page, we always add the user to the same HO as the current user.
  const orgId = useSelector(
    (state: RootState) => state.currentUser.currentUser?.orgId
  );

  // we really don't want to accidently add a user to all HOs.
  if (!orgId || orgId === UniversalOrgId) {
    return <div>No current organization</div>;
  }

  return (
    <CreateUserForm
      roleOptions={["greet", "medast", "phys"]}
      roleDefault="greet"
      orgId={orgId}
    />
  );
}

export default CreateUserRoute;
