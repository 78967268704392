import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "../state/reducers";
import {
  ClinicWebUserInfo,
  useLazyClinicByIdQuery,
} from "../graphql/generated";
import { setClinic } from "../state/currentClinicSlice";
import { useServiceBag } from "../services/ServiceBag";

//to pullout reusable blocks of code into a custom hook

function useClinic() {
  const { envService } = useServiceBag();
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies();
  const [fetchClinic] = useLazyClinicByIdQuery();
  const [isFetchingClinic, setIsFetchingClinic] = useState(true);
  const token = useSelector((state: RootState) => state.auth.token);

  const clinic = useSelector((state: RootState) => state.currentClinic.clinic);
  const clinicIdFromCookie = `${JSON.stringify(cookies["sehat-clinic"])}`;

  useEffect(() => {
    const fetchClinicFromBackend = async (clinicId: string) => {
      try {
        console.log(`fetch clinic: ${clinicId} ${typeof clinicId}`);

        const result = await fetchClinic({ clinicId });
        console.log(`got result clinic ${JSON.stringify(result)}`);
        const clinic = result.data?.clinic;
        if (clinic != null) {
          dispatch(setClinic({ ...clinic, __typename: "ClinicWebUserInfo" }));
        }
        setIsFetchingClinic(false);
      } catch (e) {
        console.log("error fetching clinic", e);
        setIsFetchingClinic(false);
      }
    };

    if (!isAuthenticated || token == null) {
      // wait for authentication before we do anything.
      return;
    } else if (clinic || !clinicIdFromCookie) {
      // if we hae a clinic already, or we don't have a clinicId from the cookie, we're done.
      setIsFetchingClinic(false);
    } else {
      // no clinic, but an id: fetch from backend.
      setIsFetchingClinic(true);
      void fetchClinicFromBackend(clinicIdFromCookie);
    }
  }, [
    clinic,
    clinicIdFromCookie,
    dispatch,
    fetchClinic,
    isAuthenticated,
    token,
  ]);

  const selectClinic = (clinic: ClinicWebUserInfo) => {
    console.log(`select clinic: ${clinic.clinicId || "-"}`);
    dispatch(setClinic(clinic));
    const expires = envService.currentTime().plus({ days: 2 }).toJSDate();
    console.log(`expires to ${expires.toDateString()}`);
    setCookie("sehat-clinic", clinic.clinicId, { path: "/", expires });
  };

  const clearClinic = () => {
    console.log(`clear clinic`);
    dispatch(setClinic(undefined));
    setCookie("sehat-clinic", "", { path: "/" });
  };
  return { clinic, isFetchingClinic, selectClinic, clearClinic };
}

export default useClinic;
