import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SimpleHeader from "./SimpleHeader";

interface PrimaryAppBarProps {
  readonly toggleMenu: () => void;
}

const PrimaryAppBar = ({ toggleMenu }: PrimaryAppBarProps) => {
  return (
    <SimpleHeader>
      <SimpleHeader.Menu>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleMenu}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </SimpleHeader.Menu>
      <SimpleHeader.Subheader>
        <Typography ml="5px" fontWeight="bold">
          Internal
        </Typography>
      </SimpleHeader.Subheader>
    </SimpleHeader>
  );
};

export default PrimaryAppBar;
