import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClinicWebUserInfo } from "../graphql/generated";
import { logoutAction } from "./authSlice";

export interface CurrentClinicSatate {
  clinic?: ClinicWebUserInfo;
  availableClinics?: ClinicWebUserInfo[];
}

const initialState: CurrentClinicSatate = {
  availableClinics: [],
};

const currentClinicSlice = createSlice({
  name: "CurrentClinic",
  initialState,
  reducers: {
    setClinic: (
      state,
      action: PayloadAction<ClinicWebUserInfo | undefined>
    ) => {
      state.clinic = action.payload;
    },
    setAvailableClinics: (
      state,
      action: PayloadAction<ClinicWebUserInfo[]>
    ) => {
      state.availableClinics = action.payload;
    },
  },
  extraReducers: (builder) => {
    // logout is defined in authSlice
    builder.addCase(logoutAction, (state) => {
      state.clinic = undefined;
      state.availableClinics = [];
    });
  },
});

export default currentClinicSlice.reducer;
export const { setClinic, setAvailableClinics } = currentClinicSlice.actions;
