export const patientQuery = /* GraphQL */ `
  query patient($id: String!, $patientIdType: String!) {
    patient(id: $id, patientIdType: $patientIdType) {
      patientId
      patientIdType
      firstName
      lastName
      birthday
      patientId
      patientIdType
    }
  }
`;

export const patientUploadPhotoQuery = /* GraphQL */ `
  query patientUploadPhoto($id: String!, $patientIdType: String!) {
    patient(id: $id, patientIdType: $patientIdType) {
      photoUploadUrl
    }
  }
`;

export const patientsByIdQuery = /* GraphQL */ `
  query patientsById($ids: [PatientId!]!) {
    patientsById(ids: $ids) {
      firstName
      lastName
      patientId
      patientIdType
    }
  }
`;

export const patientMedicationQuery = /* GraphQL */ `
  query patientMedication($id: String!, $patientIdType: String!) {
    patient(id: $id, patientIdType: $patientIdType) {
      medications {
        medicationId
        name
        dosage
        status
        schedule
      }
    }
  }
`;
