export function isTemperatureAlertable(temperature: number | undefined | null) {
  return temperature != null && temperature > 100.4;
}

export function isHeartRateAlertable(heartRate: number | undefined | null) {
  return heartRate != null && heartRate > 100;
}

export function isRespiratoryRateAlertable(
  respiratoryRate: number | undefined | null
) {
  return respiratoryRate != null && respiratoryRate > 20;
}

export function isBloodOxygenAlertable(bloodOxygen: number | undefined | null) {
  return bloodOxygen != null && bloodOxygen < 95;
}

export function isBloodPressureAlertable(
  systolic: number | undefined | null,
  diastolic: number | undefined | null
) {
  return (
    (systolic != null && systolic > 140) ||
    (diastolic != null && diastolic > 90)
  );
}
