import { DateTime } from "luxon";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/material";
import { useServiceBag } from "../services/ServiceBag";

export function formattedDateTime(now: DateTime) {
  return {
    time: now.toFormat("hh:mm"),
    timePeriod: now.toFormat("a"),
    date: now.toFormat("dd"),
    month: now.toFormat("LLLL"),
    day: now.toFormat("cccc"),
  };
}

interface VisistDetailsHeaderProps {
  firstName: string | undefined | null;
  lastName: string | undefined | null;
}

function VisistDetailsHeader({
  firstName,
  lastName,
}: VisistDetailsHeaderProps) {
  const { envService } = useServiceBag();
  const now = envService.currentTime();
  const intl = useIntl();
  const theme = useTheme();
  const { time, timePeriod, date, month, day } = formattedDateTime(
    now.setLocale(intl.formatMessage({ id: "lang" }))
  );
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: theme.palette.primary.main,
        px: 4,
        py: 1.5,
        mb: 2,
        color: theme.palette.inverseText.main,
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography
        variant="navHeader"
        sx={{
          display: "flex",
          alignItems: "baseline",
          columnGap: "12px",
        }}
      >
        <FormattedMessage id="vitals.title" />
        <Typography variant="navItems1">
          {firstName} {lastName}
        </Typography>
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography variant="navItems3">
          {time}
          <Typography variant="caption"> {timePeriod}</Typography>
        </Typography>

        <Stack
          direction="row"
          sx={{ display: "flex", alignItems: "center", columnGap: "6px" }}
        >
          <Typography variant="navItems2">{month}</Typography>
          <Typography variant="navItems2">{date}</Typography>
        </Stack>

        <Typography variant="datum"> {day}</Typography>
      </Stack>
    </Stack>
  );
}

export default VisistDetailsHeader;
