import { useFloatStringState, useIntStringState } from "./useIntStringState";

function useVitalsState() {
  const [systolic, systolicString, setSystolic] = useIntStringState(0);
  const [diastolic, diastolicString, setDiastolic] = useIntStringState(0);
  const [heartRate, heartRateString, setHeartRate] = useIntStringState(0);
  const [respiratoryRate, respiratoryRateString, setRespiratoryRate] =
    useIntStringState(0);
  const [bloodOxygen, bloodOxygenString, setBloodOxygen] = useIntStringState(0);
  const [temp, tempString, setTemp] = useFloatStringState(0);
  const [weight, weightString, setWeight] = useFloatStringState(0);
  const [height, heightString, setHeight] = useFloatStringState(0);

  return {
    systolic,
    systolicString,
    setSystolic,
    diastolic,
    diastolicString,
    setDiastolic,
    heartRate,
    heartRateString,
    setHeartRate,
    respiratoryRate,
    respiratoryRateString,
    setRespiratoryRate,
    bloodOxygen,
    bloodOxygenString,
    setBloodOxygen,
    temp,
    tempString,
    setTemp,
    weight,
    weightString,
    setWeight,
    height,
    heightString,
    setHeight,
  };
}

export type VitalsState = ReturnType<typeof useVitalsState>;
export default useVitalsState;
