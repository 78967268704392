import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useLazyVisitByIdQuery } from "../graphql/generated";
import { setVisit } from "../state/visitSlice";
import { RootState } from "../state/reducers";

function useActiveVisit(clinicId: string) {
  const [queryParams] = useSearchParams();
  const queryVisitId = useMemo(() => queryParams.get("visitId"), [queryParams]);
  const [queryVisit] = useLazyVisitByIdQuery();
  const dispatch = useDispatch();

  const visit = useSelector((state: RootState) => {
    return state.visit;
  });
  const visitId = visit?.visit?.visitId;

  // this will be the case if we reload and visitId is a query param
  // we will show a spinner while we get the visit from the db.
  const needToLoadVisit = queryVisitId && queryVisitId !== visitId;

  useEffect(() => {
    async function loadVisit() {
      const result = await queryVisit({
        visitId: queryVisitId || "",
        clinicId,
      });
      const visit = result.data?.visit;
      if (visit) {
        dispatch(setVisit({ visit }));
      } else {
        console.log(`did not find visit ${queryVisitId || "-"}`);
      }
    }
    if (needToLoadVisit) {
      void loadVisit();
    }
  }, [clinicId, dispatch, needToLoadVisit, queryVisit, queryVisitId]);

  return {
    visit: needToLoadVisit ? null : visit?.visit,
    visitId: needToLoadVisit ? null : visit?.visit?.visitId,
    needToLoadVisit,
  };
}

export default useActiveVisit;
