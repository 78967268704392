import { configureStore } from "@reduxjs/toolkit";
import api from "../graphql/enhancedApi";
import serviceBag from "../services/ServiceBag";
import { setApiDispatch } from "../graphql/apiService";
import rootReducer from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // this allows us to put luxon date objects in the store.
      thunk: { extraArgument: serviceBag },
    }).concat(api.middleware),
});

setApiDispatch(store.dispatch as (u: unknown) => unknown);
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
