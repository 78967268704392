import { type FileUploaderProps, FileUploader } from "shared-ui";
import { uploadPatientPhotoAction } from "../middleware/patientMiddleware";

type AmchurFileUploaderProps = Omit<FileUploaderProps, "createAction">;

function AmchurFileUploader(props: AmchurFileUploaderProps) {
  const createActionFunc = (image: string) => {
    return uploadPatientPhotoAction({
      imageBase64: image,
      patient: {
        patientId: "2023",
        patientIdType: "PKCNI",
      },
    });
  };

  return <FileUploader createAction={createActionFunc} {...props} />;
}

export default AmchurFileUploader;
