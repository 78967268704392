import * as R from "ramda";
import { useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { type SxProps, type Theme } from "@mui/material/styles";

// wrapper around autocomplete to handle the simple case where
// we are autocompleting a list of strings.
// it also adds a label.

interface TextAutocompleteFieldProps {
  choices: string[];
  value: string | null;
  setValue: (assignee: string | null) => void;
  label: string;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
}

function TextAutocompleteField({
  choices,
  value,
  setValue,
  label,
  sx,
  size,
}: TextAutocompleteFieldProps) {
  const options = useMemo(
    () => choices.map((a) => ({ label: a, value: a })),
    [choices]
  );

  const selectedOption = value ? { label: value, value: value } : null;

  const extractValue = R.unless(R.isNil, R.prop("value"));
  const onChange = R.compose(setValue, extractValue, R.nthArg(1));

  return (
    <Autocomplete
      options={options}
      value={selectedOption}
      onChange={onChange}
      sx={{ width: 300, marginTop: "1em", ...(sx || {}) }}
      renderInput={(params) => (
        <TextField {...params} size={size || "medium"} label={label} />
      )}
    />
  );
}

export default TextAutocompleteField;
