import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// interface MedicineBin {
//     Trade_Name: string;
//     Salt: string;
//     Number: string;
//     Unit: string;
//     Route: string;
//     Frequency: string;
//     Start_date: string;
//     End_date: string;
//     Prescribed_by: string;
// }

// const initialState: MedicineBin[] = [];

interface MedicineBinState {
    data: any;  // Replace `any` with the correct type if possible
  }
  
  const initialState: MedicineBinState = {
    data: {},
  };

const MedicineBinSlice = createSlice({
  name: "MedicineBin",
  initialState,
  reducers: {
    UpdateMedBin(state, action: PayloadAction<any>) {
        // action would be the new medicine array, take input from action, if the action is not "" then update the state
        console.log('[IN SLICE] UpdateMedBin Action Payload:', action.payload); // Log the action payload
        state.data = action.payload;
        console.log('[IN SLICE] Medicine Bin State After Update:', state.data); // Log the updated state
    },
  },
});

export default MedicineBinSlice.reducer;
export const { UpdateMedBin } = MedicineBinSlice.actions;