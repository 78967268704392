import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Patient } from "../graphql/generated";

export interface CurrentPatientState {
  patient?: Patient;
  patientId?: string; // used when creating a new patient
}

const initialState: CurrentPatientState = {};

const currentPatientSlice = createSlice({
  name: "CurrentPatient",
  initialState,
  reducers: {
    setPatient: (state, action: PayloadAction<Patient>) => {
      state.patient = action.payload;
    },
    setPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload;
    },
  },
});

export default currentPatientSlice.reducer;
export const { setPatient, setPatientId } = currentPatientSlice.actions;
