import { SerializedError } from "@reduxjs/toolkit";
import { ErrorResponse } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { enqueueSnackbar } from "notistack";
import { IntlShape } from "react-intl";

interface OperationResult {
  data?: unknown;
  error?: ErrorResponse | SerializedError;
}

export function announceOperationResult(
  result: OperationResult,
  intl: IntlShape
) {
  if (result.error) {
    enqueueSnackbar(
      intl.formatMessage(
        { id: "createUser.userCreationFailed" },
        { msg: result.error?.message }
      ),
      {
        variant: "error",
      }
    );
    return false;
  } else if (result.data) {
    enqueueSnackbar(intl.formatMessage({ id: "createUser.userCreated" }), {
      variant: "success",
    });
    return true;
  } else {
    // no reason for this to happen.
    console.log("unknown result");
    return false;
  }
}
