import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ServiceBag } from "../services/ServiceBag";
import { RootState } from "../state/reducers";

type ThunkApiArg = Parameters<Parameters<typeof createAsyncThunk>[1]>[1];

// convenience wrapper around createAsyncThunk that unwraps the ApiService
export function createApiThunk<Payload, Result>(
  name: string,
  thunk: (
    arg: Payload,
    serviceBag: ServiceBag,
    getState: () => RootState,
    thunkApi: ThunkApiArg
  ) => Promise<Result>
) {
  return createAsyncThunk(name, async (arg: Payload, thunkApi) => {
    const serviceBag = thunkApi.extra as ServiceBag;
    const getState = thunkApi.getState as () => RootState;
    return thunk(arg, serviceBag, getState, thunkApi);
  });
}
