import { useState, useCallback } from "react";
import { useIntl } from "react-intl";

type FeedbackCallback = (ok: boolean, msg?: string) => void;

export interface DefinePasswordState {
  password: string;
  setPassword: (p: string) => void;
  passwordConfirmation: string;
  setPasswordConfirmation: (p: string) => void;
  passwordMessage: string | undefined;
  unmatchedMessage: string | undefined;
  onPasswordFeedback: FeedbackCallback;
}

function useDefinePasswordState() {
  const intl = useIntl();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordOk, setPasswordOk] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState<string | undefined>();

  const unmatchedMessage =
    passwordConfirmation.length === 0 || password === passwordConfirmation
      ? undefined
      : intl.formatMessage({ id: "createUser.passwordsNotMatch" });

  const onPasswordFeedback = useCallback(
    (ok: boolean, msg?: string) => {
      setPasswordOk(ok);
      setPasswordMessage(msg);
    },
    [setPasswordOk, setPasswordMessage]
  );

  const definePasswordState: DefinePasswordState = {
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    passwordMessage,
    unmatchedMessage,
    onPasswordFeedback,
  };

  const acceptablePassword =
    password.length > 0 && password === passwordConfirmation && passwordOk
      ? password
      : "";

  const clearPassword = () => {
    setPassword("");
    setPasswordConfirmation("");
    setPasswordMessage(undefined);
  };
  return { acceptablePassword, definePasswordState, clearPassword };
}

export default useDefinePasswordState;
