import { useMemo } from "react";
import { removeNils } from "shared-utils";
import {
  useRecordingsByCriteriaQuery as useRecordingsByCriteriaQueryInternal,
  useSiteNotesQuery as useSiteNotesQueryInternal,
  useUsersForRoleQuery as useUsersForRoleQueryInternal,
} from "../../graphql/generated";
import {
  type RecordingModel,
  type SiteNoteModel,
} from "../../models/modelTypes";

type DataObject = {
  [id: string]: unknown;
};

interface ResultObject {
  data: unknown;
}

function objectsFromResult(result: ResultObject, propName: string): unknown[] {
  const data = result.data as DataObject;
  if (data == null) {
    return [];
  }
  return (data[propName] || []) as unknown[];
}
function useDeniledResultData(result: ResultObject, propName: string) {
  const objects = objectsFromResult(result, propName);
  return useMemo(() => removeNils(objects), [objects]);
}

// I'm sort of assuming that all queryies have the same return type except for the data
// so we can just reuse this everywhere. Hopefully this is true.
type ResultSansData = Omit<
  ReturnType<typeof useSiteNotesQueryInternal>,
  "data"
>;

function useDenilQuery<Data>(propName: string, result: object) {
  const data = useDeniledResultData(result as ResultObject, propName) as Data[];

  return { ...result, data } as ResultSansData & { data: Data[] };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function wrapWithDenil<Data, T1, T2>(
  propName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  f: (a: any, b: any) => any
) {
  return (a: T1, b: T2 = {} as T2) => {
    const result = f(a, b);
    return useDenilQuery<Data>(propName, result);
  };
}

// the Typed versions of the queryies accomplish 2 things:
// 1. they cleanly remove any nils (there probably aren't any, but TS complains) -- and the result is memoized
// 2. they return the correct type for the data, including taking into
//  account the transforms in enhancedApi.
type UseSiteNotesQueryParams = Parameters<typeof useSiteNotesQueryInternal>;
export const useSiteNotesQuery = wrapWithDenil<
  SiteNoteModel,
  UseSiteNotesQueryParams[0],
  UseSiteNotesQueryParams[1]
>("siteNotes", useSiteNotesQueryInternal);

type UseUsersForRoleQuery = Parameters<typeof useUsersForRoleQueryInternal>;
export const useUsersForRoleQuery = wrapWithDenil<
  string,
  UseUsersForRoleQuery[0],
  UseUsersForRoleQuery[1]
>("usersForRole", useUsersForRoleQueryInternal);

type UseRecordingsByCriteriaQuery = Parameters<
  typeof useRecordingsByCriteriaQueryInternal
>;
export const useRecordingsByCriteriaQuery = wrapWithDenil<
  RecordingModel,
  UseRecordingsByCriteriaQuery[0],
  UseRecordingsByCriteriaQuery[1]
>("recordings", useRecordingsByCriteriaQueryInternal);
