import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
  api_host: `${process.env.REACT_APP_GRAPHQL_URL || ""}/mx-proxy`,
});
const prodMod = process.env.NODE_ENV === "production" ? true : false;
export interface eventTrackerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// to send properties passed to it, with all subsequent trackEvent calls
export const registerEvent = (props: eventTrackerProps) => {
  if (prodMod) {
    mixpanel.register(props);
  }
};
// to track events with an optional properties object
export const trackEvent = (name: string, props?: eventTrackerProps) => {
  try {
    if (prodMod) {
      mixpanel.track(name, props);
    }
  } catch (e) {
    console.log(`Failed to track: ${JSON.stringify(e)}`);
  }
};
