import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

type IconType = typeof SvgIcon;
export type IconProps = Parameters<IconType>[0];
type IconComponent = (props: IconProps) => React.ReactNode;

function IconOnCircle({ icon }: { icon: IconComponent }) {
  const theme = useTheme();
  return (
    <Box
      borderRadius="50%"
      sx={{ backgroundColor: theme.palette.secondaryContainer.main }}
      width="26px"
      height="26px"
      justifyContent="center"
      display="flex"
      alignItems="center"
      marginBottom="10px"
    >
      {icon({
        sx: { color: theme.palette.inverseText.main, fontSize: "14pt" },
      })}
    </Box>
  );
}

export default IconOnCircle;
