import { useSelector } from "react-redux";
import { limitToWebUserRoles } from "shared-utils";
import { RootState } from "../state/reducers";

export default function useCurrentRole() {
  const role = useSelector(
    (state: RootState) => state.currentUser.currentUser?.role
  );
  return role ? limitToWebUserRoles(role) : null;
}
