import React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CheckIcon from "@mui/icons-material/Check";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { nullaryBool } from "shared-utils";

interface ChildrenProps {
  children: React.ReactNode;
}

export function VitalsCardContents({ children }: ChildrenProps) {
  return <>{children}</>;
}
export function VitalsCardActions({ children }: ChildrenProps) {
  return <>{children}</>;
}

interface VitalsCardLayoutSlots {
  contents?: React.ReactNode;
  actions?: React.ReactNode;
}

interface VitalsCardControlsProps {
  setCollapsed?: (collapsed: boolean) => void;
  collapsed: boolean;
  setEditMode?: (editMode: boolean) => void;
  editMode: boolean;
}

function VitalsCardControls({
  setCollapsed,
  collapsed,
  setEditMode,
  editMode,
}: VitalsCardControlsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const nop = () => {};
  const [collapse, expand] = nullaryBool(setCollapsed || nop);

  const runAndClose = (f: () => void) => {
    return () => {
      f();
      closeMenu();
    };
  };

  return (
    <>
      <IconButton color="inherit" onClick={openMenu}>
        <MoreIcon />
      </IconButton>
      <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
        {setCollapsed && !collapsed && (
          <MenuItem onClick={runAndClose(collapse)}>
            <ListItemIcon>
              <ExpandLessIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Collapse</ListItemText>
          </MenuItem>
        )}
        {setCollapsed && collapsed && (
          <MenuItem onClick={runAndClose(expand)}>
            <ListItemIcon>
              <ExpandMoreIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Expand</ListItemText>
          </MenuItem>
        )}
        {setEditMode && (
          <MenuItem
            onClick={() => {
              setEditMode(!editMode);
              closeMenu();
            }}
          >
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>EditMode</ListItemText>
            {editMode && (
              <CheckIcon fontSize="small" sx={{ marginLeft: "1em" }} />
            )}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

interface VitalsCardLayoutProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  height: any;
  title: string;
  setCollapsed?: (collapsed: boolean) => void;
  collapsed: boolean;
  setEditMode?: (editMode: boolean) => void;
  editMode: boolean;
  slots: VitalsCardLayoutSlots;
}

function VitalsCardLayout({
  height,
  title,
  slots,
  setCollapsed,
  collapsed,
  setEditMode,
  editMode,
}: VitalsCardLayoutProps) {
  const { contents, actions } = slots;

  const actionSize = actions ? 50 : 0;

  const headerSx = {
    height: 65,
    backgroundColor: editMode ? "#7777AA" : undefined,
  };
  const contentSx = {
    height: `${parseInt(height) - 65 - actionSize}px`,
    backgroundColor: editMode ? "#aaaaee" : undefined,
  };
  const actionSx = {
    height: actionSize,
    backgroundColor: editMode ? "#aaaaee" : undefined,
  };

  return (
    <Card sx={{ height: height }}>
      <CardHeader
        title={title}
        sx={headerSx}
        action={
          <VitalsCardControls
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        }
      />
      {!collapsed && (
        <CardContent sx={contentSx}>
          <>{contents}</>
        </CardContent>
      )}
      {!collapsed && actions && (
        <CardActions sx={actionSx}>{actions}</CardActions>
      )}
    </Card>
  );
}

function extractSlots(children: React.ReactNode) {
  const slots: VitalsCardLayoutSlots = {};
  const remainingChildren: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }
    if (child.type === VitalsCardContents) {
      slots.contents = child;
    } else if (child.type === VitalsCardActions) {
      slots.actions = child;
    } else {
      remainingChildren.push(child);
    }
  });
  return { slots, remainingChildren };
}

interface VitalsCardProps {
  title: string;
  setCollapsed?: (collapsed: boolean) => void;
  collapsed?: boolean;
  setEditMode?: (collapsed: boolean) => void;
  editMode?: boolean;
}

const VitalsCard = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    VitalsCardProps
>(
  (
    {
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children,
      title,
      setCollapsed,
      collapsed,
      setEditMode,
      editMode,
      //props,
    },
    ref
  ) => {
    const { slots, remainingChildren } = extractSlots(children);

    return (
      <div
        style={style}
        className={className}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        <VitalsCardLayout
          height={style?.height}
          slots={slots}
          title={title}
          setCollapsed={setCollapsed}
          collapsed={collapsed || false}
          setEditMode={setEditMode}
          editMode={editMode || false}
        />
        {remainingChildren}
      </div>
    );
  }
);

export default VitalsCard;
