interface VisitType {
  waitingFor?: string | null | undefined;
  assocPhysician?: string | null | undefined;
}

export const AwaitMedAssist = "MA";
export const AwaitAnyPhysician = "PA";
export const AwaitPhysicianPrefix = "P-";

export function waitForMedAssist<T extends VisitType>(visit: T) {
  visit.waitingFor = AwaitMedAssist;
}

export function waitForAnyPhysician<T extends VisitType>(visit: T) {
  visit.waitingFor = AwaitAnyPhysician;
  visit.assocPhysician = null;
}

export function waitForSpecificPhysician<T extends VisitType>(
  visit: T,
  physicianId: string
) {
  visit.waitingFor = `${AwaitPhysicianPrefix}${physicianId}`;
  visit.assocPhysician = physicianId;
}

export function waitForAssocPhysician<T extends VisitType>(visit: T) {
  if (visit.assocPhysician == null) {
    visit.waitingFor = AwaitAnyPhysician;
  } else {
    visit.waitingFor = `${AwaitPhysicianPrefix}${visit.assocPhysician}`;
  }
}

export function visitConcluded<T extends VisitType>(visit: T) {
  visit.waitingFor = null;
}
