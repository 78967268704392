import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CenteredForm from "../../components/CenteredForm";
import PatientIdField from "../../components/PatientIdField";
import { OkButton } from "../../components/standardButtons";

// this is just a placeholder for now
function SelectPatientRoute() {
  const [patientId, setPatientId] = useState("");
  const navigate = useNavigate();

  const onSubmit = () => {
    //dispatch(setPatient(patientId));
    navigate("/visit-details");
  };

  return (
    <Stack>
      <CenteredForm>
        <PatientIdField
          patientId={patientId}
          setPatientId={setPatientId}
          required={true}
          disabled={false}
        />
        <OkButton onClick={onSubmit} />
      </CenteredForm>
    </Stack>
  );
}

export default SelectPatientRoute;
