import { useSelector, useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import AmchurCard from "../../components/AmchurCard";
import { type Patient, Visit } from "../../graphql/generated";
import { toFormattedCnic } from "../../domain/cnicUtils";
import usePatientsForVisitsQueries, {
  type VisitAndPatient,
} from "../../hooks/usePatientsForVisitsQueries";
import { RootState } from "../../state/reducers";
import { setVisit } from "../../state/visitSlice";
import { setPatient } from "../../state/currentPatientSlice";
import useCurrentClinicId from "../../hooks/useCurrentClinic";
import useCurrentHealthOrg from "../../hooks/useCurrentHealthOrg";

function PhysicianDashboardRoute() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const healthOrg = useCurrentHealthOrg();

  const clinicId = useCurrentClinicId();
  const currentUser = useSelector(
    (state: RootState) => state.currentUser.currentUser?.email
  );

  const { patientsAndVisits } = usePatientsForVisitsQueries({
    clinicId,
    // todo: if there is no current user we shouldn't even be on this page: we aren't logged in!
    physician: currentUser || "???",
  });

  const seePatient = (visit: Visit, patient: Partial<Patient>) => {
    dispatch(setVisit({ visit }));
    dispatch(setPatient(patient as Patient));
    const destination = healthOrg?.demo
      ? "/demo-visit-in-progress"
      : "/visit-in-progress";

    navigate({
      pathname: destination,
      search: createSearchParams({
        visitId: visit.visitId,
      }).toString(),
    });
  };

  return (
    <PhysicianDashboardUiView
      allPatients={patientsAndVisits}
      selectVisit={seePatient}
    />
  );
}
interface PhysicianDashboardUiViewProps {
  allPatients: VisitAndPatient[];
  selectVisit: (visitId: Visit, patient: Partial<Patient>) => void;
}

function PhysicianDashboardUiView({
  allPatients,
  selectVisit,
}: PhysicianDashboardUiViewProps) {
  const intl = useIntl();

  return (
    <Stack direction="row" spacing={2} mt="10px" mx="2em">
      <Stack width="80%" spacing="20px">
        <Typography variant="h3">
          <FormattedMessage id="physicianDashboard.listHeader" />
        </Typography>
        <AmchurCard
          title={intl.formatMessage({ id: "physicianDashboard.listSubheader" })}
          role="secondary"
        >
          <AllPatientsListView
            allPatients={allPatients}
            selectVisit={selectVisit}
          />
        </AmchurCard>
      </Stack>
      <Stack width="20%">Buttons go here.</Stack>
    </Stack>
  );
}

interface AllPatientsListViewProps {
  allPatients: VisitAndPatient[];
  selectVisit: (visit: Visit, patient: Partial<Patient>) => void;
}

function AllPatientsListView({
  allPatients,
  selectVisit,
}: AllPatientsListViewProps) {
  return (
    <Stack spacing={2}>
      {allPatients.map((patientAndVisit) => (
        <PatientView
          patient={patientAndVisit.patient}
          visit={patientAndVisit.visit as Visit}
          key={patientAndVisit.visit.visitId}
          selectVisit={selectVisit}
        />
      ))}
    </Stack>
  );
}

interface PatientViewProps {
  patient: Partial<Patient>;
  visit: Visit;
  selectVisit: (visitId: Visit, patient: Partial<Patient>) => void;
}

function PatientView({ patient, visit, selectVisit }: PatientViewProps) {
  const onClick = () => selectVisit(visit, patient);

  const cnic = toFormattedCnic(patient.patientId || "");
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Box width="50%" justifyContent="flex-start">
        <Typography textAlign="start">
          {patient.firstName} {patient.lastName}
        </Typography>
      </Box>
      <Typography>{cnic}</Typography>
      <Button onClick={onClick}>
        <FormattedMessage id="physicianDashboard.startAppt" />
      </Button>
    </Stack>
  );
}
export default PhysicianDashboardRoute;
