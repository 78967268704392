import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import useClinic from "../hooks/useClinic";
import { logoutAction } from "../state/authSlice";

function useLogout() {
  const { logout } = useAuth0();
  const { clearClinic } = useClinic();
  const dispatch = useDispatch();

  const performLogout = () => {
    dispatch(logoutAction());
    clearClinic();
    void logout({
      logoutParams: { returnTo: `${window.location.origin}` },
    });
  };

  return performLogout;
}

export default useLogout;
