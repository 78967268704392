export function toRawCnic(cnic: string) {
  return cnic.replace(/\D/g, "");
}

export function toFormattedCnic(cnic: string) {
  if (cnic.length >= 5) {
    cnic = cnic.slice(0, 5) + "-" + cnic.slice(5);
  }
  if (cnic.length >= 13) {
    cnic = cnic.slice(0, 13) + "-" + cnic.slice(13);
  }
  return cnic;
}
