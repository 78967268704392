import { forwardRef, useRef, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import { removeNils } from "shared-utils";
import AmchurCard from "../../components/AmchurCard";
import { type Vitals, type Medication } from "../../graphql/generated";
import PainPointPairView from "../../components/PainPointPairView";
import { TranscriptionSection } from "../../hooks/useTranscriptionState";
import useWindowLayoutEffect from "../../hooks/useWindowLayoutEffect";
import TranscriptionPanel from "./TranscriptionPanel";

export type MedicationInfo = Omit<Medication, "patientId" | "patientIdType">;

interface VisitInteractionPanelProps {
  medications: MedicationInfo[];
  vitals: Vitals | undefined | null;
  transcription: TranscriptionSection[];
  questions: string[] | null;
}

function VisitInteractionPanelInternal(
  { vitals, medications, transcription, questions }: VisitInteractionPanelProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const stackRef = useRef<HTMLElement>(null);
  const [transcriptionHeight, setTranscriptionHeight] = useState("100%");
  const [transcriptionListHeight, setTranscriptionListHeight] =
    useState("100%");
  const [questionHeight, setQuestionHeight] = useState("0px");

  useWindowLayoutEffect((windowHeight) => {
    const { top } = stackRef.current?.getBoundingClientRect() || {
      top: 0,
    };
    const height = windowHeight - top;
    let questionHeight = 80;
    if (height > 440) {
      questionHeight += (height - 440) / 3;
    }
    const transcriptionHeight = Math.max(height - questionHeight - 90, 150);
    const transcriptionListHeight = transcriptionHeight - 60;
    console.log(
      ` set them to ${transcriptionHeight} and ${questionHeight} based on ${height} top=${top}`
    );
    setTranscriptionHeight(`${transcriptionHeight}px`);
    setTranscriptionListHeight(`${transcriptionListHeight}px`);
    setQuestionHeight(`${questionHeight}px`);
  });

  return (
    <Box height="100%">
      {/* the box makes the margin on the stack work. */}
      <Stack
        spacing="1em"
        direction="row"
        marginX="2em"
        marginTop="18px"
        height="100%"
      >
        <Stack width="25%">
          <AmchurCard title="Physical" role="secondary">
            {vitals && <PhysicalsPanel vitals={vitals} />}
          </AmchurCard>
        </Stack>
        <Box width="50%" flexDirection="column" ref={stackRef}>
          <TranscriptionPanel
            transcription={transcription}
            transcriptionHeight={transcriptionHeight}
            transcriptionListHeight={transcriptionListHeight}
            transcriptionInProgress={false}
            ref={ref}
          />
          <Box height="10px" />
          <QuestionsPanel questions={questions} height={questionHeight} />
          <Box height="10px" />
        </Box>
        <Stack width="25%">
          <AmchurCard title="Medications" role="secondary">
            <MedicationsPanel medications={medications || []} />
          </AmchurCard>
        </Stack>
      </Stack>
    </Box>
  );
}
const VisitInteractionPanel = forwardRef(VisitInteractionPanelInternal);

interface MedicationsPanelProps {
  medications: MedicationInfo[];
}

function MedicationsPanel({ medications }: MedicationsPanelProps) {
  return (
    <Stack>
      {medications.map((medication) => (
        <MedicationRow medication={medication} key={medication.name} />
      ))}
    </Stack>
  );
}

interface MedicationRowProps {
  medication: MedicationInfo;
}

function MedicationRow({ medication }: MedicationRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography sx={{ fontWeight: "bold" }}>{medication.name}</Typography>
      <Typography>{medication.dosage}</Typography>
    </Stack>
  );
}

interface PhysicalsPanelProps {
  vitals: Vitals;
}

function PhysicalsPanel({ vitals }: PhysicalsPanelProps) {
  const frontPainPoints = removeNils(vitals.frontPainPoints);
  const backPainPoints = removeNils(vitals.backPainPoints);
  return (
    <Box mx="auto" display="flex" width="100%" justifyContent="center">
      <PainPointPairView
        scale={0.3}
        frontPainPoints={frontPainPoints}
        frontToggleSelection={() => {}}
        backPainPoints={backPainPoints}
        backToggleSelection={() => {}}
      />
    </Box>
  );
}

function QuestionsPanel({
  questions,
  height,
}: {
  questions: string[] | null;
  height: string;
}) {
  return (
    <AmchurCard title="Questions" role="primary">
      <Stack spacing="1em" sx={{ overflowY: "scroll", height }}>
        <QuestionsPanelContents questions={questions} />
      </Stack>
    </AmchurCard>
  );
}

function QuestionsPanelContents({ questions }: { questions: string[] | null }) {
  if (questions == null) {
    return <></>;
  }
  if (questions.length === 0) {
    return <Typography fontStyle="italic">No questions</Typography>;
  }
  return (
    <TransitionGroup>
      {questions.map((question, ix) => (
        <Collapse key={ix}>
          <Typography textAlign="left">{question}</Typography>
        </Collapse>
      ))}
    </TransitionGroup>
  );
}

export default VisitInteractionPanel;
