// this is the only essential check to see if the email is valid:
// it must have an @ not at the beginning or end of the string
// a '.' is not formally required!
export function isValidEmail(email: string) {
  const trimmedEmail = email.trim();
  return (
    trimmedEmail.indexOf("@") > 0 &&
    trimmedEmail[0] !== "@" &&
    trimmedEmail.slice(-1) !== "@"
  );
}

export const UniversalOrgId = "000";
